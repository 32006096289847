import { IAny, TrackItFC } from 'helpers/helperTypes';
import React, { useState } from 'react';
import createContextTuple from './createContextTuple';

type TApplicationData = { environment: 'PROD' | 'TEST' | 'DEV' | 'DEFAULT' } & IAny;

interface IApplicationDataProviderProps {
    children: React.ReactNode;
}

const [hook, Provider] = createContextTuple<{
    applicationData: TApplicationData | null;
    setApplicationData: (data: TApplicationData | null) => void;
}>();
export const useApplicationData = hook;

const ApplicationDataProvider: TrackItFC<IApplicationDataProviderProps> = ({ children }) => {
    const [applicationData, setApplicationData] = useState<TApplicationData | null>(null);

    return <Provider value={{ applicationData, setApplicationData }}>{children}</Provider>;
};

export default ApplicationDataProvider;
