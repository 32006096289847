import client from 'api/client';
import SearchData from 'models/SearchData';

const RESOURCE_PATH = `${process.env.REACT_APP_BACKEND}/search`;

const getSearchResults = async (searchData: SearchData) => client.post(`${RESOURCE_PATH}`, searchData);

export default {
    getSearchResults,
};
