import { BoxProps } from '@material-ui/core';
import RegionAccordionItem, { IRegionAccordionItemButton } from 'components/regions/common/RegionAccordionItem';
import { RegionComponent } from 'components/regions/types';
import { TrackItFC } from 'helpers/helperTypes';
import React, { useState } from 'react';

const containerStyle: (style: BoxProps['style']) => BoxProps['style'] = (style) => ({
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid #d1d1d1',
    backgroundColor: '#fff',
    borderRadius: '3px',
    margin: '5px',
    boxSizing: 'border-box',
    ...style,
});

const contentStyle = {
    padding: '0 10px 10px 10px',
};

interface IRegionRendererProps {
    element: RegionComponent;
    style?: BoxProps['style'];
}

const RegionRenderer: TrackItFC<IRegionRendererProps> = (props) => {
    const { element: Element, style } = props;

    const [headerTitle, setHeaderTitle] = useState('');
    const [subtitle, setSubtitle] = useState('');
    const [buttonRight, setButtonRight] = useState<IRegionAccordionItemButton>();

    return (
        <div style={containerStyle(style)}>
            <RegionAccordionItem header={headerTitle} buttonRight={buttonRight} subtitle={subtitle} startsOpen>
                <div style={contentStyle}>
                    <Element
                        setHeaderTitle={setHeaderTitle}
                        setSubtitle={setSubtitle}
                        setButtonRight={setButtonRight}
                    />
                </div>
            </RegionAccordionItem>
        </div>
    );
};

export default RegionRenderer;
