import { useLocation } from 'react-router-dom';

const useQueryParams = () => {
    const iterator = new URLSearchParams(useLocation().search).entries();

    const map: { [index: string]: string } = {};

    let entry;
    do {
        entry = iterator.next();

        if (entry.value) {
            const [key, value] = entry.value;
            map[key] = value;
        }
    } while (!entry.done);

    return map;
};

export default useQueryParams;
