import { Box, BoxProps, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import * as taskApi from 'api/task';
import DialogTypes from 'components/dialogs/types';
import ActionDots from 'components/generic/ActionDots';
import Input, { IInputProps } from 'components/generic/Input';
import { SaveButton, SaveProvider } from 'components/generic/save';
import EntityStatus from 'components/renderers/EntityStatus';
import { getKeysOf, IAny, TrackItFC } from 'helpers/helperTypes';
import { useData, useMetadata, useReload } from 'middleware/data';
import { useDialogDispatch } from 'middleware/dialogContext';
import { withChildren } from 'middleware/validation';
import withRegion from 'middleware/withRegion';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useUserData } from 'store/contexts/UserDataContext';
import RegionAccordionItem from '../common/RegionAccordionItem';
import { RegionComponent } from '../types';

interface ITaskItemProps {
    onChange: (object: any) => void;
    entity: IInputProps['entity'];
    task: IAny;
    readonly?: boolean;
    style?: Omit<NonNullable<BoxProps['style']>, 'display' | 'alignItems' | 'position'>;
    history: ReturnType<typeof useHistory>;
}

const TaskItem: TrackItFC<ITaskItemProps> = ({ entity, onChange, task, style, history, readonly }) => {
    const dispatchDialog = useDialogDispatch();
    const [tasks, updateTasks] = useData('tasks');
    const reloadTasks = useReload('tasks');

    return (
        <SaveProvider
            title={`TSK$$${task.code}`}
            saver={() => taskApi.saveTask(task)}
            afterSave={() => {
                const newTask = { ...task };
                newTask.updateCount += 1;
                updateTasks(newTask, [Object.keys(tasks).find((el) => tasks[el] === task) as string]);
                reloadTasks();
            }}
            error={() =>
                dispatchDialog({
                    type: DialogTypes.TEXT_CLOSE,
                    state: { title: 'Failure', text: 'Failed to save tasks' },
                })
            }
        >
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    padding: '10px 0',
                    justifyContent: 'space-between',
                    ...style,
                }}
            >
                <Box>
                    <EntityStatus
                        code={task.code}
                        status={task.status}
                        entity="task"
                        link={getRoute({ path: ROUTES.tasks.view, params: { code: task.code } })}
                        onChange={!readonly ? onChange : undefined}
                    />
                </Box>
                <Box
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'space-between',
                        padding: '0 1rem',
                    }}
                >
                    <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        <Box style={{ width: '100%', padding: '0.5rem' }}>
                            <Input
                                readonly={readonly}
                                value={task}
                                entity={entity}
                                valueKey="description"
                                infor="casedescription"
                                onChange={onChange}
                            />
                        </Box>
                        <Box style={{ width: '100%', padding: '0.5rem' }}>
                            <Input
                                value={task}
                                entity={entity}
                                onChange={onChange}
                                valueKey="responsibleCode"
                                infor="responsible"
                                autocomplete="responsible"
                                readonly={readonly}
                                label="Assignee"
                            />
                        </Box>
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            padding: '0 0.5rem',
                        }}
                    >
                        <Box style={{ display: 'flex' }}>
                            <Typography variant="subtitle2" color="textSecondary">
                                Links:
                            </Typography>
                            <Typography style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }} color="textSecondary">
                                {task.links}
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                            <Typography variant="subtitle2" color="textSecondary">
                                Comments:
                            </Typography>
                            <Typography style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }} color="textSecondary">
                                {task.comments}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {!readonly && (
                    <Box style={{ alignSelf: 'stretch', display: 'flex' }}>
                        <Box>
                            <SaveButton iconOnly color="action" />
                        </Box>
                        <ActionDots
                            actions={[
                                {
                                    name: 'Edit',
                                    onClick: () =>
                                        history.push({
                                            pathname: getRoute({
                                                path: ROUTES.tasks.view,
                                                params: { code: task.code },
                                            }),
                                        }),
                                },
                                {
                                    name: 'Delete',
                                    onClick: () =>
                                        dispatchDialog({
                                            type: DialogTypes.TEXT_YES_NO,
                                            state: {
                                                title: 'Confirm deletion',
                                                text: `Are you sure you want to delete Task ${task.code}?`,
                                                callback: async () => {
                                                    dispatchDialog({
                                                        type: DialogTypes.LOADING,
                                                        state: {
                                                            promise: async () =>
                                                                await taskApi.deleteTask({
                                                                    code: task.code,
                                                                }),
                                                            callback: () => {
                                                                reloadTasks();
                                                                dispatchDialog(null);
                                                            },
                                                            error: () =>
                                                                dispatchDialog({
                                                                    type: DialogTypes.TEXT_CLOSE,
                                                                    state: {
                                                                        title: 'Error',
                                                                        text: 'The task could not be deleted.',
                                                                    },
                                                                }),
                                                        },
                                                    });
                                                },
                                            },
                                        }),
                                },
                            ]}
                            isStatic
                        />
                    </Box>
                )}
            </Box>
        </SaveProvider>
    );
};

const TasksRegion: RegionComponent = (props) => {
    const { setHeaderTitle, setSubtitle, setButtonRight } = props;
    const [general] = useData('general');
    const [tasks, updateTasks] = useData('tasks');
    const [{ entity }] = useMetadata();
    const reloadTasks = useReload('tasks');
    const dispatchDialog = useDialogDispatch();
    const { readonly } = useUserData();

    const history = useHistory();

    useEffect(() => {
        if (!readonly)
            setButtonRight({
                icon: ({ size, color }) => <Add style={{ fontSize: size, color }} />,
                callback: () =>
                    dispatchDialog({
                        type: DialogTypes.ADD_TASK,
                        state: {
                            parent: general.code,
                            callback: () => {
                                reloadTasks();
                                dispatchDialog(null);
                            },
                        },
                    }),
            });
        setHeaderTitle('Tasks');
        setSubtitle(
            `(${Object.values(tasks).filter((t: any) => t.status === 'TSK_STAT_40').length}/${tasks.length} Completed)`
        );
    }, [general.code, reloadTasks]);

    const taskGroups = {
        preparation: Object.values(tasks).filter((t: any) => t.phase === 'PREPARATION'),
        implementation: Object.values(tasks).filter((t: any) => t.phase === 'IMPLEMENTATION'),
        followup: Object.values(tasks).filter((t: any) => t.phase === 'FOLLOWUP'),
        others: Object.values(tasks).filter(
            (t: any) => !['FOLLOWUP', 'PREPARATION', 'IMPLEMENTATION'].includes(t.phase)
        ),
    };

    const headers: { [key in keyof typeof taskGroups]?: { title: string; subtitle: string } } = {
        preparation: { title: 'Preparation', subtitle: `Tasks: ${taskGroups.preparation.length}` },
        implementation: { title: 'Implementation', subtitle: `Tasks: ${taskGroups.implementation.length}` },
        followup: { title: 'Follow-Up', subtitle: `Tasks: ${taskGroups.followup.length}` },
    };

    if (
        taskGroups.preparation.length + taskGroups.implementation.length + taskGroups.followup.length <
        Object.values(tasks).length
    ) {
        headers.others = { title: 'Others', subtitle: `Tasks: ${taskGroups.others.length}` };
    }

    return (
        <Box>
            {getKeysOf(tasks).length > 0 ? (
                <Box>
                    {getKeysOf(taskGroups).map((key) =>
                        taskGroups[key].length < 1 && key === 'others' ? null : (
                            <RegionAccordionItem
                                key={headers[key]?.title}
                                header={headers[key]?.title || ''}
                                subtitle={headers[key]?.subtitle}
                                startsOpen
                                style={{ margin: '4px -10px', border: '1px solid #d1d1d1' }}
                            >
                                {taskGroups[key].length > 0 ? (
                                    taskGroups[key].map((ft: any, i) => (
                                        <TaskItem
                                            key={ft.code}
                                            task={ft}
                                            entity={entity}
                                            onChange={(object) =>
                                                updateTasks(object, [
                                                    Object.values(tasks).findIndex((t: any) => t.code === ft.code),
                                                ])
                                            }
                                            readonly={readonly}
                                            style={{
                                                borderBottom:
                                                    i !== getKeysOf(taskGroups[key]).length - 1
                                                        ? '1px solid lightgrey'
                                                        : undefined,
                                            }}
                                            history={history}
                                        />
                                    ))
                                ) : (
                                    <Typography color="textSecondary">No tasks.</Typography>
                                )}
                            </RegionAccordionItem>
                        )
                    )}
                </Box>
            ) : (
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Typography color="textSecondary">No tasks.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default withChildren(withRegion(TasksRegion, ['tasks']));
