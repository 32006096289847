const name = 'trackItApplicationSettings';

export const get = async () => {
    const applicationSettings = window.localStorage.getItem(name);
    if (applicationSettings === null) {
        return { body: { data: {} } };
    }

    return { body: { data: JSON.parse(applicationSettings) } };
};

export const post = async (applicationSettings) => {
    try {
        window.localStorage.setItem(name, JSON.stringify(applicationSettings));
    } catch (e) {
        // failed, but it's ok
    }
};

export const deleter = async () => {
    window.localStorage.removeItem(name);
};

const all = {
    get,
    post,
    delete: deleter,
};

export default all;
