/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { makeStyles, Typography } from '@material-ui/core';
import DiscreteSelect from 'components/generic/DiscreteSelect';
import Input from 'components/generic/Input';
import EntityStatus from 'components/renderers/EntityStatus';
import TrackHierarchy from 'components/renderers/TrackHierarchy';
import { useData, useMetadata } from 'middleware/data';
import withRegion from 'middleware/withRegion';
import React, { useMemo } from 'react';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';
import { useUserData } from 'store/contexts/UserDataContext';
import { RegionComponent } from '../types';

const useStyles = makeStyles({
    parent: {
        display: 'flex',
        flexDirection: 'row',
        boxSizing: 'content-box',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    data: {
        display: 'flex',
        alignItems: 'center',
        border: '1px solid #dfdfdf',
        background: '#eceff1',
        borderRadius: 2,
        margin: '0.25rem 0',
    },
    trackDetails: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '800px',
    },
    status: {
        flex: '0.2',
    },
    description: {
        flex: '0.6',
    },
    details: {
        flex: '0.4',
    },
    actions: {
        display: 'flex',
        flexDirection: 'row',
    },
    field: {
        textAlign: 'end',
    },
});

const EntityDetailsRegion: RegionComponent = () => {
    const [{ entity }] = useMetadata();
    const [general, updateGeneral] = useData('general');
    const [metadata, updateMetadata] = useMetadata();
    const [hierarchy] = useData('hierarchy');
    const { dropdownValues } = useDropdownValues();
    const { readonly } = useUserData();
    const { parents, children } = hierarchy[general.code] ?? {};
    const classes = useStyles();

    const turnOnEditMode = (e: any) => {
        e.stopPropagation();
        updateMetadata({ editMode: true });
    };

    const editMode = useMemo(() => metadata.editMode || metadata.newEntity, [metadata.editMode, metadata.newEntity]);

    return (
        <div className={classes.parent} onClick={!readonly ? turnOnEditMode : undefined}>
            <div className={classes.trackDetails}>
                <TrackHierarchy hierarchy={{ parents }} />
                <div className={classes.data}>
                    <div className={classes.status}>
                        <EntityStatus status={general.status} entity={entity} onChange={editMode && updateGeneral} />
                    </div>
                    <div className={classes.description}>
                        <Typography style={{ maxWidth: 50, flexBasis: '50px' }} variant="button">
                            {general.code}
                        </Typography>
                        <Typography>
                            {editMode ? (
                                <div onClick={(e: any) => e.stopPropagation()}>
                                    <Input
                                        valueKey="description"
                                        infor="casedescription"
                                        value={general}
                                        entity={entity}
                                        onChange={updateGeneral}
                                        label=""
                                    />
                                </div>
                            ) : (
                                general.description
                            )}
                        </Typography>
                    </div>
                    <div className={classes.details}>
                        <table style={{ width: '100%' }}>
                            {entity === 'track' &&
                                [
                                    {
                                        title: 'Scope',
                                        key: 'scope',
                                        valuesKey: 'scopes',
                                    },
                                    {
                                        title: 'Period',
                                        key: 'period',
                                        valuesKey: 'periods',
                                    },
                                    {
                                        title: 'Facility',
                                        key: 'facility',
                                        valuesKey: 'facilities',
                                    },
                                ].map(({ title, key, valuesKey }) => (
                                    <tr key={key}>
                                        <td className={classes.field}>
                                            <Typography style={{ marginRight: 5 }} variant="button" display="inline">
                                                {title}
                                                {editMode && <span style={{ color: 'red' }}>*</span>}
                                            </Typography>
                                        </td>
                                        <td>
                                            {!editMode && (
                                                <Typography style={{ marginRight: 5 }} variant="body1" display="inline">
                                                    {
                                                        (
                                                            (dropdownValues as any)?.[valuesKey].find(
                                                                (val: any) => val.code === general[key]
                                                            ) ?? {}
                                                        ).desc
                                                    }
                                                </Typography>
                                            )}
                                            {editMode && (
                                                <div onClick={(e: any) => e.stopPropagation()}>
                                                    <DiscreteSelect
                                                        valueKey={key}
                                                        options={(dropdownValues as any)?.[valuesKey] || []}
                                                        value={(dropdownValues as any)?.[valuesKey].find(
                                                            (val: any) => val.code === general[key]
                                                        )}
                                                        onChange={(value: any) => updateGeneral({ [key]: value })}
                                                        width="150px"
                                                        textAlign="left"
                                                    />
                                                </div>
                                            )}
                                        </td>
                                    </tr>
                                ))}
                        </table>
                    </div>
                </div>
                <TrackHierarchy hierarchy={{ children }} />
            </div>
        </div>
    );
};

export default withRegion(EntityDetailsRegion, ['general', 'hierarchy']);
