import { SvgIcon } from '@material-ui/core';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import { ISquareIconProps } from './interfaces';

const TracksIcon: TrackItFC<ISquareIconProps> = ({ size, color }) => (
    <SvgIcon style={{ width: size, height: size }}>
        <path
            transform="matrix(2.9248 0 0 2.9788 -292.44 -165.25)"
            d="m106.08 62.94-3.9688-1e-6 -1.9844-3.437 1.9844-3.437h3.9688l1.9844 3.437z"
            fill={color}
            stroke={color}
            strokeLinecap="round"
            strokeWidth=".24009"
            style={{
                paintOrder: 'stroke markers fill',
            }}
        />
    </SvgIcon>
);

export default TracksIcon;
