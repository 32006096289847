import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import integrationStudyApi from 'api/integrationStudy';
import { Checkbox, CloseButton, ValidateButton } from 'components/generic/dialog';
import { IAny } from 'helpers/helperTypes';
import { useData, useReload } from 'middleware/data';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TDetachParentTrackDialog } from './dialogStates';
import DialogTypes from './types';

const DetachParentIntegrationStudyDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TDetachParentTrackDialog>();
    const [{ code }] = useData('general');
    const [hierarchy] = useData('hierarchy');

    const { parents } = hierarchy[code] || {};

    const reloadHierarchy = useReload('hierarchy');

    const updateParent = (parent: IAny) => updateState({ parents: { ...(state.parents || []), ...parent } });

    const checkboxState = {
        ...Object.fromEntries(Object.values(parents).map((parent: any) => [parent.code, false] as const)),
        ...state.parents,
    };

    const checkboxes = Object.values(parents).map((parent: any) => (
        <Checkbox
            label={`${parent.code} - ${parent.description}`}
            state={checkboxState}
            valueKey={parent.code}
            update={updateParent}
            key={parent.code}
            optional
        />
    ));

    const apiData = {
        childIntegrationStudyCode: code,
        parentsToDetach: Object.entries(checkboxState)
            .filter(([, value]) => value)
            .map(([key]) => key),
    };

    // the 2 styles below compensate for the fact that the Checkbox has the style "width: 50%"
    const contentStyle: any = { overflowX: 'hidden' };
    const contentDivStyle: any = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '200%',
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title">Detach Parent Track</DialogTitle>
            <DialogContent style={contentStyle}>
                <div style={contentDivStyle}>{checkboxes}</div>
            </DialogContent>
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => integrationStudyApi.detachParent(apiData),
                                callback: () => {
                                    reloadHierarchy();
                                    dispatchDialog(null);
                                },
                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: {
                                            title: 'Detach Parent Track',
                                            text: 'There was an error detaching the parent Track(s).',
                                        },
                                    }),
                            },
                        })
                    }
                    label="Confirm"
                />
            </DialogActions>
        </>
    );
};

export default DetachParentIntegrationStudyDialog;
