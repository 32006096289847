import IconButton from '@material-ui/core/IconButton';
import { logout } from 'AuthWrapper';
import GlobalSearch from 'components/ui/GlobalSearch';
import { Account, Logout } from 'mdi-material-ui';
import React from 'react';
import { useUserData } from 'store/contexts/UserDataContext';

const accountIconStyle = {
    fontSize: 20,
    margin: 5,
};

const logoutIconStyle = {
    paddingRight: 9,
    fontSize: 18,
    lineHeight: '22px',
    color: 'rgba(255, 255, 255, 0.8)',
};

const separatorStyle = {
    borderLeft: '1px solid rgba(255, 255, 255, 0.8)',
    width: 1,
    height: 22,
    marginLeft: 14,
};

const HeaderDetails = () => {
    const { userData } = useUserData();
    const { userCode, userGroup } = userData?.eamAccount;

    return (
        <>
            <GlobalSearch />
            <Account style={accountIconStyle} />
            {userCode} ({userGroup})
            <span style={separatorStyle} />
            <IconButton onClick={logout} style={logoutIconStyle}>
                <Logout style={{ fontSize: 20 }} />
            </IconButton>
        </>
    );
};

export default HeaderDetails;
