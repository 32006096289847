// React app polyfills should be at the top
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import AuthWrapper from 'AuthWrapper';
import { enGB } from 'date-fns/locale';
import 'index.css';
import React from 'react';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import * as serviceWorker from 'serviceWorker';
import AggregateDataProvider from 'store/contexts/DataProvider';
import store from 'store/store';
import { ToastContainer } from 'react-toastify';
import TrackIt from './layout/TrackIt';
import TrackItLoader from './layout/TrackItLoader';
import { DialogContextProvider } from './middleware/dialogContext';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
    <AuthWrapper>
        <Router basename={process.env.PUBLIC_URL}>
            <AggregateDataProvider>
                <Provider store={store}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                        <DialogContextProvider>
                            <TrackItLoader>
                                <ToastContainer />
                                <TrackIt />
                            </TrackItLoader>
                        </DialogContextProvider>
                    </MuiPickersUtilsProvider>
                </Provider>
            </AggregateDataProvider>
        </Router>
    </AuthWrapper>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
