const theme = {
    PROD: 'BLUE',
    TEST: 'GREEN',
    DEV: 'RED',
    DEFAULT: 'BLUE',
};

const config = {
    theme,
};

export default config;
