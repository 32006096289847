import { Box, Typography } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import * as commentApi from 'api/comment';
import * as trackApi from 'api/track';
import Comments from 'eam-components/dist/ui/components/comments/Comments';
import { IAny } from 'helpers/helperTypes';
import { useData, useEntity } from 'middleware/data';
import withRegion from 'middleware/withRegion';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useUserData } from 'store/contexts/UserDataContext';
import { RegionComponent } from '../types';

const CommentsRegion: RegionComponent = ({ setHeaderTitle }) => {
    const [general] = useData('general');
    const { userData } = useUserData();
    const [entity, updateEntity] = useEntity();

    useEffect(() => {
        setHeaderTitle('Comments');
    }, []);

    const incrementUpdateCount = () => {
        updateEntity(['data', 'general'], {
            updateCount: entity.data.general.updateCount + 1,
        });
    };

    return (
        <Box>
            <Comments
                userDesc="comments"
                entityKeyCode={general.code}
                entityCode="CASE"
                userCode={userData?.eamAccount.userCode}
                handleError={(e: any) => toast.error(e)}
                readComments={() => trackApi.getCommentsFromTrack({ code: general.code })}
                createComment={(data: IAny) => commentApi.createComment(data)}
                updateComment={(data: IAny) => commentApi.updateComment(data)}
                onCommentAdded={incrementUpdateCount}
                onCommentUpdated={incrementUpdateCount}
                allowHtml
                displayPrivateCheck
                commentFooterMapper={({ comment }: IAny) =>
                    comment.typeCode === 'P' && (
                        <Typography style={{ fontSize: 11, display: 'flex', alignItems: 'normal' }}>
                            <Lock style={{ fontSize: 12, marginRight: 2 }} />
                            Private
                        </Typography>
                    )
                }
            />
        </Box>
    );
};

export default withRegion(CommentsRegion, ['comments']);
