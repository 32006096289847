import { IAny, TrackItFC } from 'helpers/helperTypes';
import React, { useState } from 'react';
import createContextTuple from './createContextTuple';

interface IUserDataStaticProviderProps {
    children: React.ReactNode;
}

const [hook, Provider] = createContextTuple<{
    userData: IAny | null;
    readonly: boolean;
    setUserData: (data: IAny | null) => void;
}>();
export const useUserData = hook;

const UserDataProvider: TrackItFC<IUserDataStaticProviderProps> = ({ children }) => {
    const [userData, setUserData] = useState<IAny | null>(null);

    const readonly = userData?.eamAccount?.departmentalSecurity?.CTRK?.readOnly === true;

    return <Provider value={{ userData, readonly, setUserData }}>{children}</Provider>;
};

export default UserDataProvider;
