import { Box, Link, Typography } from '@material-ui/core';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';

interface IISLinkStatusProps {
    color: string;
    primaryText?: string;
    statusText: string;
    url?: string;
    tooltip?: string;
}

const ISLinkStatus: TrackItFC<IISLinkStatusProps> = ({ url, tooltip, color, primaryText, statusText }) => (
    <Box>
        <Box
            style={{
                display: 'flex',
                flexDirection: 'column',
                ...(statusText ? { borderLeft: `4px solid ${color}`, padding: '0 4px' } : {}),
            }}
        >
            {primaryText ? (
                url ? (
                    <Link href={url} style={{ fontSize: 13 }} title={tooltip}>
                        {primaryText}
                    </Link>
                ) : (
                    <Typography color="textPrimary" variant="body1">
                        {primaryText}
                    </Typography>
                )
            ) : null}
            <Typography color="textSecondary" variant={primaryText ? 'caption' : 'body1'} style={{ lineHeight: 1 }}>
                {statusText}
            </Typography>
        </Box>
    </Box>
);
export default ISLinkStatus;
