import queryString from 'query-string';

const ROUTES = {
    homepage: '/',
    globalSearch: '/search',
    logs: '/logs',
    integrationStudies: {
        view: '/integrationstudy/:code',
        create: '/integrationstudy/create',
    },
    tasks: {
        view: '/task/:code',
        search: '/tasks/search',
        create: '/task/create',
    },
    mytasks: {
        search: '/mytasks/search',
    },
    tracks: {
        view: '/track/:code',
        search: '/tracks/search',
        create: '/track/create',
    },
    documents: {
        search: '/documents/search',
    },
    redirect: '/redirect/:page+',
};

const withParams = ({ path, params }: { path: string; params: { [key: string]: string } }) =>
    Object.keys(params).reduce((acc, key) => {
        const paramRegex = new RegExp(`:${key}`, 'g');
        return acc.replace(paramRegex, params[key]);
    }, path);

const withQueryParams = ({ path, queryParams }: { path: string; queryParams: { [key: string]: string } }) =>
    queryString.stringifyUrl({ url: path, query: queryParams });

export const getRoute = ({
    path,
    params = {},
    queryParams = {},
}: {
    path: string;
    params?: { [key: string]: string };
    queryParams?: { [key: string]: string };
}) => withQueryParams({ path: withParams({ path, params }), queryParams });

export default ROUTES;
