import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CloseButton, OkButton } from 'components/generic/dialog';
import { useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TTextYesNoDialog } from './dialogStates';

const TextOkDialog = () => {
    const [
        {
            state: { text, title, callback, yesLabel = 'Yes', noLabel = 'No' },
        },
    ] = useDialogState<TTextYesNoDialog>();
    return (
        <>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <CloseButton label={noLabel} />
                <OkButton callback={callback} label={yesLabel} />
            </DialogActions>
        </>
    );
};

export default TextOkDialog;
