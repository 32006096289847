import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as needApi from 'api/need';
import { CloseButton, ValidateButton } from 'components/generic/dialog';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TAddNeedDialog } from '../dialogStates';
import DialogTypes from '../types';
import NeedDialog from './NeedDialog';

const AddNeedDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TAddNeedDialog>();
    const { code, isRequiredBool, title, type, description } = state;
    const reloadNeeds = state.callback;

    if (state.isRequiredBool === null || state.isRequiredBool === undefined) {
        updateState({ isRequiredBool: false });
    }

    const apiData = { isRequiredBool, title, type, trackID: code, description };

    return (
        <>
            <DialogTitle id="alert-dialog-title">Add Need</DialogTitle>
            <NeedDialog state={state} updateState={updateState} />
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => needApi.createNeed(apiData),
                                callback: () => {
                                    reloadNeeds?.();
                                    dispatchDialog(null);
                                },
                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: { title: 'Add Need', text: 'There was an error adding the need.' },
                                    }),
                            },
                        })
                    }
                    label="Create"
                />
            </DialogActions>
        </>
    );
};

export default AddNeedDialog;
