import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import trackApi from 'api/track';
import { CloseButton, Input, ValidateButton } from 'components/generic/dialog';
import { useData, useReload } from 'middleware/data';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TAttachSubTrackDialog } from './dialogStates';
import DialogTypes from './types';

const AttachSubTrackDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TAttachSubTrackDialog>();
    const [{ code }] = useData('general');
    const reloadHierarchy = useReload('hierarchy');

    const { childTrackCode } = state;

    const apiData = {
        childTrackCode,
        parentTrackCode: code,
    };
    return (
        <>
            <DialogTitle id="alert-dialog-title">Attach sub-track</DialogTitle>
            <DialogContent>
                <Input label="Track ID" state={state} valueKey="childTrackCode" update={updateState} />
            </DialogContent>
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => trackApi.attachSubTrack(apiData),
                                callback: () => {
                                    dispatchDialog(null);
                                    reloadHierarchy();
                                },
                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_OK,
                                        state: {
                                            title: 'Attach subtrack',
                                            text: 'There was an error attaching the subtrack.',
                                        },
                                    }),
                            },
                        })
                    }
                    label="Confirm"
                />
            </DialogActions>
        </>
    );
};

export default AttachSubTrackDialog;
