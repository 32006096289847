import SearchField from 'components/ui/SearchField';
import { TrackItFC } from 'helpers/helperTypes';
import qs from 'query-string';
import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface IGlobalSearchProps {}

const DEFAULT_SEARCH_QUERY = '';

const GlobalSearch: TrackItFC<IGlobalSearchProps> = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const [searchValue, setSearchValue] = useState(DEFAULT_SEARCH_QUERY);
    const history = useHistory();
    const handleSearchValueChange = ({ newValue }: { newValue: string }) => setSearchValue(newValue);
    const handleSearch = ({ value }: { value: string }) => {
        history.push({
            pathname: '/search',
            search: qs.stringify({ query: value }),
        });
        // setSearchValue(DEFAULT_SEARCH_QUERY);
    };
    React.useEffect(() => {
        if (location.pathname !== '/search') {
            setSearchValue(DEFAULT_SEARCH_QUERY);
        }
    }, [location]);

    React.useEffect(() => {
        setSearchValue(queryParams.get('query') || DEFAULT_SEARCH_QUERY);
    }, [queryParams.get('query')]);

    return <SearchField onChange={handleSearchValueChange} value={searchValue} onSearch={handleSearch} />;
};

export default GlobalSearch;
