import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import linkApi from 'api/link';
import { CloseButton, ValidateButton } from 'components/generic/dialog';
import { IAny } from 'helpers/helperTypes';
import { useReload } from 'middleware/data';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TEditLinkDialog } from '../dialogStates';
import DialogTypes from '../types';
import LinkDialog from './LinkDialog';

const EditLinkDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TEditLinkDialog>();
    const reloadLinks = useReload('links');

    const { system, description, url, targetId, version, pres1, pres2, comment, trackLinkPK } = state;

    const apiData = {
        system,
        description,
        url,
        comment,
        trackLinkPK,
        sysRecordID: targetId,
        recVersion: version,
        udf01: pres1,
        udf02: pres2,
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title">Edit Link</DialogTitle>
            <LinkDialog state={state} updateState={updateState} />
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => linkApi.editLink(apiData),
                                callback: () => {
                                    reloadLinks();
                                    dispatchDialog(null);
                                },
                                error: (error: IAny) =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: {
                                            title: 'Edit Link',
                                            text: (
                                                <>
                                                    <p>There was an error editing the link.</p>
                                                    {error?.response?.body?.errors?.map((e: any) => (
                                                        <p>{e.detail}</p>
                                                    ))}
                                                </>
                                            ),
                                        },
                                    }),
                            },
                        })
                    }
                    label="Modify"
                />
            </DialogActions>
        </>
    );
};

export default EditLinkDialog;
