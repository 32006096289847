import TrackHierarchy from 'components/renderers/TrackHierarchy';
import { IAny } from 'helpers/helperTypes';
import { useData } from 'middleware/data';
import withRegion from 'middleware/withRegion';
import React from 'react';
import { RegionComponent } from '../types';

const buildHierarchyRegion = (type: 'children' | 'parents' | 'links') => {
    const HierarchyRegion: RegionComponent = () => {
        const [general] = useData('general');
        const [hierarchy] = useData('hierarchy');
        const { parents, children } = hierarchy[general.code] ?? {};

        const [linksData] = useData('trackLinks');
        const links = (linksData?.[general.code] || []).map((e: IAny) => ({ ...e, entityType: 'TRACK' }));

        const data = { children, parents, links };

        return (
            <>
                <TrackHierarchy hierarchy={{ [type]: data[type] }} />
            </>
        );
    };

    const dependencies = {
        children: 'hierarchy',
        parents: 'hierarchy',
        links: 'trackLinks',
    };

    return withRegion(HierarchyRegion, ['general', dependencies[type]]);
};

export const ChildrenHierarchyRegion = buildHierarchyRegion('children');
export const ParentsHierarchyRegion = buildHierarchyRegion('parents');
export const LinksHierarchyRegion = buildHierarchyRegion('links');
