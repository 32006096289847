import client from 'api/client';

const RESOURCE_PATH = `${process.env.REACT_APP_BACKEND}/tracks`;

export const getTrack = async ({ code }) => await client.get(`${RESOURCE_PATH}?code=${code}`);

export const getTrackDefault = async ({ parent }) => {
    const optionalPart = parent ? `?parent=${parent}` : '';
    return await client.get(`${RESOURCE_PATH}/default${optionalPart}`);
};

export const saveTrack = async (data) => await client.put(`${RESOURCE_PATH}`, data);
export const createTrack = async (data) => await client.post(`${RESOURCE_PATH}`, data);
export const getTasksFromTrack = async ({ code }) => await client.get(`${RESOURCE_PATH}/${code}/tasks`);
export const getStudiesFromTrack = async ({ code }) => await client.get(`${RESOURCE_PATH}/${code}/studies`);
export const getNeedsFromTrack = async ({ code }) => await client.get(`${RESOURCE_PATH}/${code}/needs`);
export const getHierarchyFromTracks = async (data) => await client.post(`${RESOURCE_PATH}/hierarchy`, data);
export const getStudiesFromManyTracks = async (data) => await client.post(`${RESOURCE_PATH}/studies`, data);
export const getLinksFromTrack = async ({ code }) => await client.get(`${RESOURCE_PATH}/${code}/links`);
export const getCommentsFromTrack = async ({ code }) => await client.get(`${RESOURCE_PATH}/${code}/comments`);
export const cloneTrack = async (data) => await client.post(`${RESOURCE_PATH}/clone`, data);
export const attachSubTrack = async (data) => await client.post(`${RESOURCE_PATH}/attachsubtrack`, data);
export const attachIntegrationStudy = async (data) =>
    await client.post(`${RESOURCE_PATH}/attachintegrationstudy`, data);
export const detachIntegrationStudy = async (data) =>
    await client.post(`${RESOURCE_PATH}/detachintegrationstudy`, data);
export const detachSubtrack = async (data) => await client.post(`${RESOURCE_PATH}/detachsubtrack`, data);
export const detachParentTrack = async (data) => await client.post(`${RESOURCE_PATH}/detachparenttrack`, data);
export const getTrackLinksForCases = async ({ cases }) => await client.post(`${RESOURCE_PATH}/hierarchy/links`, cases);
export const createNeed = async (data) => await client.post(`${RESOURCE_PATH}/need`, data);
export const saveNeed = async (data) => await client.put(`${RESOURCE_PATH}/need`, data);
export const getCaseSummary = async (caseCode) => await client.get(`${RESOURCE_PATH}/summary/${caseCode}`);
export const getDocumentsInfoForTracks = async (data) => await client.post(`${RESOURCE_PATH}/documents-info`, data);

const all = {
    getTrack,
    getTrackDefault,
    saveTrack,
    createTrack,
    getTasksFromTrack,
    getStudiesFromTrack,
    getNeedsFromTrack,
    getHierarchyFromTracks,
    getStudiesFromManyTracks,
    getLinksFromTrack,
    getCommentsFromTrack,
    cloneTrack,
    attachSubTrack,
    attachIntegrationStudy,
    detachIntegrationStudy,
    detachSubtrack,
    detachParentTrack,
    getTrackLinksForCases,
    createNeed,
    saveNeed,
    getCaseSummary,
    getDocumentsInfoForTracks,
};

export default all;
