import { BoxProps } from '@material-ui/core';
import { get as getApplicationData } from 'api/applicationData';
import { get as getApplicationSettings, post as postApplicationSettings } from 'api/applicationSettings';
import { get as getDropdownValues } from 'api/dropdownValues';
import { get as getUserData } from 'api/userData';
import TracksIcon from 'components/icons/TracksIcon';
import ColoredIcon from 'components/renderers/ColoredIcon';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import { CheckboxBlank, CheckboxBlankOutline } from 'mdi-material-ui';
import React, { useEffect, useState } from 'react';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';
import { useSettingsData } from 'store/contexts/SettingsDataContext';
import { useUserData } from 'store/contexts/UserDataContext';
import './TrackItLoader.css';

const centeredStyle: BoxProps['style'] = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
};

const horizontalStyle: BoxProps['style'] = {
    display: 'flex',
};

const headerStyle: BoxProps['style'] = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
};

const titleStyle: BoxProps['style'] = {
    fontFamily: 'Roboto,sans-serif',
    fontSize: '60px',
    lineHeight: '1.35em',
    color: '#000',
    fontWeight: 900,
};

const versionStyle: BoxProps['style'] = {
    paddingLeft: '15px',
    marginTop: '-15px',
    fontFamily: 'Roboto,sans-serif',
    fontSize: '15px',
    lineHeight: '1.35em',
    color: '#666',
    fontWeight: 900,
};

const load = async (getter: () => Promise<IAny>, action: (value: any) => void) => {
    try {
        const response = await getter();
        action((response && response.body && response.body.data) ?? null);
    } catch (e) {
        action({ error: e || true });
    }
};

const TrackItLoader: TrackItFC<{ children: React.ReactNode }> = ({ children }) => {
    // only display the loading screen after a short timeout, to prevent it from flashing
    const [displayLoading, setDisplayLoading] = useState(false);
    useEffect(() => {
        setTimeout(() => setDisplayLoading(true), 333);
    }, []);

    const { applicationData, setApplicationData } = useApplicationData();
    const { userData, setUserData } = useUserData();
    const { settingsData, setSettingsData } = useSettingsData();
    const { dropdownValues, setDropdownValues } = useDropdownValues();

    const version = process.env.REACT_APP_VERSION;

    useEffect(() => {
        load(getUserData, setUserData);
        load(getApplicationData, setApplicationData);
        load(getApplicationSettings, setSettingsData);
        load(getDropdownValues, setDropdownValues);
    }, []);

    useEffect(() => {
        if (settingsData !== null && settingsData.error === undefined) {
            postApplicationSettings(settingsData);
        }
    }, [settingsData]);

    const toLoad = [userData, applicationData, settingsData, dropdownValues];

    if (toLoad.every((data) => data !== null && data.error === undefined)) {
        return <>{children}</>;
    }

    if (!displayLoading) {
        return <></>;
    }

    const waiting = toLoad.filter((elem) => elem === null);
    const loaded = toLoad.filter((elem) => elem !== null && !elem.error);
    const failed = toLoad.filter((elem) => elem !== null && elem.error);

    const failedLoading = failed.length > 0;

    return (
        <>
            <div style={centeredStyle}>
                <div style={horizontalStyle}>
                    <div className="rotating">
                        <ColoredIcon size={150} icon={TracksIcon} color={failedLoading ? 'red' : undefined} />
                    </div>
                    <div style={headerStyle}>
                        <span style={titleStyle}>Track It</span>
                        <span style={versionStyle}>v{version}</span>
                        <div>
                            {loaded.map((_, i) => (
                                <ColoredIcon
                                    icon={({ size, color }) => <CheckboxBlank style={{ fontSize: size, color }} />}
                                    color="green"
                                    size={20}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`l${i}`}
                                />
                            ))}
                            {waiting.map((_, i) => (
                                <ColoredIcon
                                    icon={({ size, color }) => (
                                        <CheckboxBlankOutline style={{ fontSize: size, color }} />
                                    )}
                                    size={20}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`w${i}`}
                                />
                            ))}
                            {failed.map((_, i) => (
                                <ColoredIcon
                                    icon={({ size, color }) => (
                                        <CheckboxBlankOutline style={{ fontSize: size, color }} />
                                    )}
                                    color="red"
                                    size={20}
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={`f${i}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                {failedLoading ? <span>Try again or contact cmmsx-support@cern.ch</span> : null}
            </div>
        </>
    );
};

export default TrackItLoader;
