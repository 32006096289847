import client from 'api/client';

const RESOURCE_PATH = `${process.env.REACT_APP_BACKEND}/userdata`;

export const get = async () => await client.get(`${RESOURCE_PATH}`);

const all = {
    get,
};

export default all;
