import { useSettingsData } from 'store/contexts/SettingsDataContext';
import { useEntityBasic } from './entityContext';

export const useApplicationSetting = (key: string, initialValue: unknown) => {
    const { settingsData, setSettingsData } = useSettingsData();
    const value = settingsData?.[key] ?? initialValue;
    const setValue = (newValue: any) => setSettingsData({ [key]: newValue });
    return [value, setValue] as const;
};

export const useEntity = (optionalPath?: string) => {
    const [entity, updateEntity] = useEntityBasic();
    const entityObject = typeof optionalPath === 'string' ? entity[optionalPath] : entity;
    const extraPath = optionalPath === undefined ? [] : [optionalPath];
    return [
        entityObject,
        (path: (string | number)[], data: any) => updateEntity([...extraPath, ...path], data),
    ] as const;
};

export const useUnsafeData = (path: string) => {
    const [data, updateData] = useEntity('data');
    if (data === undefined) {
        return [null, null];
    }
    return [data[path], (newData: any) => updateData([path], newData)] as const;
};

export const useData = (path: string) => {
    const [data, updateData] = useEntity('data');
    return [
        data[path],
        (newData: any, subPath?: (string | number)[]) => updateData([path, ...(subPath || [])], newData),
    ] as const;
};

export const useMetadata = () => {
    const [metadata, updateMetadata] = useEntity('metadata');
    return [metadata, (data: any) => updateMetadata([], data)] as const;
};

export const useReload = (name: string) => {
    const [reload] = useEntity('reload');
    return (reload as { [key: string]: any })[name];
};
