import { Typography } from '@material-ui/core';
import { AccountTree } from '@material-ui/icons';
import searchAPI from 'api/search';
import statusMapping from 'components/generic/status';
import CustomEAMFilter from 'components/grids/CustomEAMFilter';
import ISLinkStatus from 'components/renderers/ISLinkStatus';
import EAMGridMain from 'eam-components/dist/ui/components/grids/eam/EAMGridMain';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import queryString from 'query-string';
import React, { useEffect, useMemo, useState } from 'react';
import BlockUi from 'react-block-ui';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useFilters, useFlexLayout, useSortBy, useTable } from 'react-table';
import ROUTES, { getRoute } from 'routes/Routes';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';

const calculatePath = ({
    entity,
    code,
    row,
}: {
    entity: string;
    code: string;
    row: IAny;
}): { path?: string; isExternal?: boolean } => {
    switch (entity) {
        case 'TRACK':
            return { path: getRoute({ path: ROUTES.tracks.view, params: { code } }) };
        case 'TASK':
        case 'TRACKTSK':
            return { path: getRoute({ path: ROUTES.tasks.view, params: { code } }) };
        case 'INTSTUDY':
        case 'TRACKIS':
            return {
                path: getRoute({
                    path: ROUTES.integrationStudies.view,
                    params: { code },
                }),
            };
        case 'NEED':
        case 'COMMENT':
            return calculatePath({
                entity: row.original.usedinEntity,
                code: row.original.usedinCode,
                row,
            });
        case 'LINK':
            return {
                path: code,
                isExternal: true,
            };
        default:
            return {};
    }
};

interface ISearchPageProps {}

const SearchPage: TrackItFC<ISearchPageProps> = () => {
    const location = useLocation();
    const queryParams = queryString.parse(location.search);
    const { query } = queryParams;
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const { dropdownValues } = useDropdownValues();

    useEffect(() => {
        document.title = `Search for '${query ?? ''}'`;
    }, [query]);
    const { applicationData } = useApplicationData();

    const data = useMemo(() => searchResults, [searchResults]);
    const columns = useMemo(
        () => [
            {
                Header: 'Entity',
                accessor: 'entity',
                id: 'entity',
                width: 200,
                minWidth: 200,
                maxWidth: 99999,
                Filter: CustomEAMFilter,
                dataType: '__SELECT',
                Cell: ({ row, value }: { row: any; value: string }) => {
                    if (!value) return '';
                    const rowData = row.original;
                    return (
                        <div>
                            <div style={{ display: 'flex', whiteSpace: 'pre-wrap' }}>
                                <Typography component="span" variant="subtitle2">
                                    {dropdownValues?.entities.find((e) => e.code === value)?.desc}
                                </Typography>
                                <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                                    {['TRACK', 'INTSTUDY', 'TASK'].includes(value) ? (
                                        <Typography component="span">{` - ${rowData.code}`}</Typography>
                                    ) : null}
                                    {['TRACK', 'INTSTUDY'].includes(value) ? (
                                        <a
                                            href={`${applicationData?.trackItVisualToolUrl}${applicationData?.trackItVisualToolUrlTracks}${rowData.code}`}
                                            target="_blank"
                                            onClick={(e) => e.stopPropagation()}
                                            rel="noreferrer"
                                        >
                                            <AccountTree color="disabled" fontSize="small" />
                                        </a>
                                    ) : null}
                                </div>
                            </div>
                            <Typography component="p">{rowData.desc}</Typography>
                        </div>
                    );
                },
                selectOptions: dropdownValues?.entities,
                getOptionLabel: (option: any) => option.desc,
                getOptionValue: (option: any) => option.code,
            },
            {
                Header: 'Status',
                accessor: 'status',
                id: 'status',
                width: 150,
                minWidth: 150,
                maxWidth: 99999,
                Filter: CustomEAMFilter,
                dataType: '__SELECT',
                Cell: ({ value }: { value: string }) => {
                    if (!value) return '';
                    return (
                        <ISLinkStatus
                            color={statusMapping[value]?.color || ''}
                            statusText={statusMapping[value]?.text || ''}
                        />
                    );
                },
                selectOptions: dropdownValues?.trackStatuses,
                getOptionLabel: (option: any) => option.desc,
                getOptionValue: (option: any) => option.code,
            },
            {
                Header: 'In',
                accessor: 'usedinEntity',
                id: 'usedinEntity',
                width: 200,
                minWidth: 200,
                maxWidth: 99999,
                Filter: CustomEAMFilter,
                dataType: '__SELECT',
                Cell: ({ row, value }: { row: any; value: string }) => {
                    if (!value) return '';
                    const rowData = row.original;
                    const { path = '' } = calculatePath({
                        entity: rowData.usedinEntity,
                        code: rowData.usedinCode,
                        row: rowData,
                    });
                    return (
                        <Link
                            to={path}
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                            style={{ textDecoration: 'none', color: 'unset' }}
                        >
                            <div style={{ display: 'flex', whiteSpace: 'pre-wrap' }}>
                                <Typography component="span" variant="subtitle2">
                                    {dropdownValues?.inEntities.find((e) => e.code === value)?.desc}
                                </Typography>
                                {['TRACK', 'TRACKIS'].includes(value) ? (
                                    <div style={{ display: 'flex', flex: 1, justifyContent: 'space-between' }}>
                                        <Typography component="span">{` - ${rowData.usedinCode}`}</Typography>
                                        <a
                                            href={`${applicationData?.trackItVisualToolUrl}${applicationData?.trackItVisualToolUrlTracks}${rowData.usedinCode}`}
                                            target="_blank"
                                            onClick={(e) => e.stopPropagation()}
                                            rel="noreferrer"
                                        >
                                            <AccountTree color="disabled" fontSize="small" />
                                        </a>
                                    </div>
                                ) : null}
                            </div>
                            <Typography component="p">{rowData.usedinDesc}</Typography>
                        </Link>
                    );
                },
                selectOptions: dropdownValues?.inEntities,
                getOptionLabel: (option: any) => option.desc,
                getOptionValue: (option: any) => option.code,
            },
            {
                Header: 'Scope',
                accessor: 'scope',
                id: 'scope',
                width: 250,
                minWidth: 250,
                maxWidth: 99999,
                Filter: CustomEAMFilter,
                dataType: '__SELECT',
                Cell: ({ value }: { value: string }) =>
                    value ? dropdownValues?.scopes.find((e) => e.code === value)?.desc : '',
                selectOptions: dropdownValues?.scopes,
                getOptionLabel: (option: any) => option.desc,
                getOptionValue: (option: any) => option.code,
            },
            {
                Header: 'Facility',
                accessor: 'facility',
                id: 'facility',
                width: 150,
                minWidth: 150,
                maxWidth: 99999,
                Filter: CustomEAMFilter,
                dataType: '__SELECT',
                Cell: ({ value }: { value: string }) =>
                    value ? dropdownValues?.facilities.find((e) => e.code === value)?.desc : '',
                selectOptions: dropdownValues?.facilities,
                getOptionLabel: (option: any) => option.desc,
                getOptionValue: (option: any) => option.code,
            },
            {
                Header: 'Period',
                accessor: 'period',
                id: 'period',
                width: 150,
                minWidth: 150,
                maxWidth: 99999,
                Filter: CustomEAMFilter,
                dataType: '__SELECT',
                Cell: ({ value }: { value: string }) =>
                    value ? dropdownValues?.periods.find((e) => e.code === value)?.desc : '',
                selectOptions: dropdownValues?.periods,
                getOptionLabel: (option: any) => option.desc,
                getOptionValue: (option: any) => option.code,
            },
        ],
        []
    );

    const initialFilters = useMemo(
        () =>
            Object.entries(queryParams)
                .filter(([key]) => columns.some((e) => e.id === key))
                .map(([key, value]) => ({
                    id: key,
                    value: {
                        fieldName: key,
                        fieldValue: value,
                        operator: '=',
                    },
                })),
        [queryParams, columns]
    );

    const { sortColumn, sortOrder } = queryParams;
    const initialSortBy = useMemo(
        () =>
            sortColumn
                ? [
                      {
                          id: sortColumn,
                          desc: sortOrder === 'desc',
                      },
                  ]
                : [],
        []
    );

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                // @ts-ignore
                filters: initialFilters,
                sortBy: initialSortBy,
            },
            // @ts-ignore
            manualFilters: true,
            autoResetFilters: false,
            manualSortBy: true,
            autoResetSortBy: false,
        },
        useFilters,
        useSortBy,
        useFlexLayout
    );
    const { state } = tableInstance;
    // @ts-ignore
    const { filters, sortBy } = state;

    const fetchData = async () => {
        setLoading(true);
        const result = await searchAPI.getSearchResults({
            query,
            sortColumn: sortBy[0]?.id,
            ...(sortBy[0] && { sortOrder: sortBy[0]?.desc ? 'desc' : 'asc' }),
            filter: filters
                .map((e: any) => e?.value || e)
                .reduce((acc: any, e: any) => ({ ...acc, [e.fieldName]: e.fieldValue }), {}),
        });
        setSearchResults(result.body.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchData();
    }, [query, JSON.stringify(filters), JSON.stringify(sortBy)]);

    const history = useHistory();

    return (
        <BlockUi
            blocking={loading}
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'white',
            }}
        >
            <EAMGridMain
                loading={loading}
                tableInstance={tableInstance}
                getCellProps={() => ({
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    },
                })}
                getRowProps={(r: IAny) => ({
                    style: {
                        borderBottom: '1px solid #d2d2d2',
                        width: '100%',
                        cursor: 'pointer',
                    },
                    hover: true,
                    onClick: (e: IAny) => {
                        const { path, isExternal } = calculatePath({
                            entity: r.original.entity,
                            code: r.original.code,
                            row: r,
                        });
                        if (!path) return;
                        if (isExternal || !!e.ctrlKey) {
                            window.open(path.startsWith('/') ? `${process.env.PUBLIC_URL}${path} ` : path, '_blank');
                        } else {
                            history.push(path);
                        }
                    },
                })}
            />
        </BlockUi>
    );
};

export default SearchPage;
