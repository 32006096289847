import CircularProgress from '@material-ui/core/CircularProgress';
import DialogContent from '@material-ui/core/DialogContent';
import { TLoadingDialog } from 'components/dialogs/dialogStates';
import { useDialogState } from 'middleware/dialogContext';
import React, { useEffect } from 'react';

const LoadingDialog = () => {
    const [
        {
            state: { promise, callback, error },
        },
    ] = useDialogState<TLoadingDialog>();

    useEffect(() => {
        promise?.().then(callback).catch(error);
    }, [promise]);
    return (
        <DialogContent style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center' }}>
            <CircularProgress />
        </DialogContent>
    );
};

export default LoadingDialog;
