import { Button, Typography } from '@material-ui/core';
import statusMapping from 'components/generic/status';
import CustomEAMFilter from 'components/grids/CustomEAMFilter';
import ISLinkStatus from 'components/renderers/ISLinkStatus';
import EAMGrid from 'eam-components/dist/ui/components/grids/eam/EAMGrid';
import { EAMGridContext, EAMGridContextProvider } from 'eam-components/dist/ui/components/grids/eam/EAMGridContext';
import { EAMCellField } from 'eam-components/dist/ui/components/grids/eam/utils';
import { arrange2DArrayByColumns } from 'helpers/helperFunctions';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import useGridSyncedParams from 'hooks/useGridSyncedParams';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';

interface IDocumentsPageProps {}
interface IExcelExportButtonProps {}

export const COLUMNS = {
    NAME: 'ted_displayed_doc_name',
    TYPE: 'ted_edms_doc_type',
    SYSTEM: 'ted_system',
    STATUS: 'ted_edms_rel_sta_eng',
    TITLE: 'ted_edms_doc_name_eng',
    VERSION: 'ted_edms_doc_version',
    AUTHORS: 'ted_edms_authors',
    DEADLINE_DATE: 'ted_edms_deadline_date',
    RELEASE_DATE: 'ted_edms_release_date',
    TRACK: 'ted_track',
    SCOPE: 'csm_udfchar12',
    PERIOD: 'csm_udfchar06',
    FACILITY: 'csm_udfchar01',
    FIRST_PRES: 'ted_pres1_desc',
    SECOND_PRES: 'ted_pres2_desc',
    COMMENT: 'ted_comment',
};

const columnsOrder = [
    COLUMNS.NAME,
    COLUMNS.SYSTEM,
    COLUMNS.TYPE,
    COLUMNS.STATUS,
    COLUMNS.TITLE,
    COLUMNS.VERSION,
    COLUMNS.AUTHORS,
    COLUMNS.DEADLINE_DATE,
    COLUMNS.RELEASE_DATE,
    COLUMNS.TRACK,
    COLUMNS.SCOPE,
    COLUMNS.PERIOD,
    COLUMNS.FACILITY,
    COLUMNS.FIRST_PRES,
    COLUMNS.SECOND_PRES,
    COLUMNS.COMMENT,
];

const DocumentsPage: TrackItFC<IDocumentsPageProps> = () => {
    const { applicationData } = useApplicationData();
    const gridName = applicationData?.gridDocListGridName;
    const { dropdownValues } = useDropdownValues();

    useEffect(() => {
        document.title = 'Document List';
    }, []);

    const columnOverrides: any = {
        [COLUMNS.NAME]: {
            Header: 'DOC',
            Cell: ({ row, value }: { row: any; value: string }) => (
                <a
                    href={`${applicationData?.edmslinkUrl}${row.original.ted_edms_document_id}`}
                    onClick={(e: any) => {
                        e.stopPropagation(); // prevent the row's onClick from opening the track
                    }}
                    title="Open document"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {`${value} ${row.original.ted_specified_doc_version ? 'v. ' : ''}
    ${row.original.ted_specified_doc_version}`}
                </a>
            ),
        },
        [COLUMNS.SYSTEM]: {
            Header: 'Track-it Link System',
            Cell: ({ value }: { value: string }) =>
                dropdownValues?.systems.find((e) => e.code === value)?.description || '',
            dataType: '__SELECT',
            selectOptions: dropdownValues?.systems,
            getOptionLabel: (option: any) => option.description,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.TYPE]: {
            Header: 'EDMS Type',
        },
        [COLUMNS.STATUS]: {
            Header: 'Status',
            Cell: ({ value }: { value: string }) => (
                <ISLinkStatus color={statusMapping[value]?.color || ''} statusText={statusMapping[value]?.text || ''} />
            ),
        },
        [COLUMNS.TITLE]: {
            Header: 'Title',
        },
        [COLUMNS.VERSION]: {
            Header: 'Current Version',
        },
        [COLUMNS.AUTHORS]: {
            Header: 'Author',
        },
        [COLUMNS.DEADLINE_DATE]: {
            Header: 'Deadline Date',
        },
        [COLUMNS.RELEASE_DATE]: {
            Header: 'Release Date',
        },
        [COLUMNS.FIRST_PRES]: {
            Header: '1st Pres',
        },
        [COLUMNS.SECOND_PRES]: {
            Header: '2nd Pres',
        },
        [COLUMNS.COMMENT]: {
            Header: 'Comment',
        },
        [COLUMNS.TRACK]: {
            Header: 'In',
            Cell: ({ row, value }: { row: any; value: string }) => {
                const rowData = row.original;
                return (
                    <>
                        <Typography component="p">{value}</Typography>
                        <Typography>{rowData.csm_desc}</Typography>
                    </>
                );
            },
        },
        [COLUMNS.SCOPE]: {
            Header: 'Scope',
            Cell: ({ value }: { value: string }) => dropdownValues?.scopes.find((e) => e.code === value)?.desc || '',
            dataType: '__SELECT',
            selectOptions: dropdownValues?.scopes,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.PERIOD]: {
            Header: 'Period',
            Cell: ({ value }: { value: string }) => dropdownValues?.periods.find((e) => e.code === value)?.desc || '',
            dataType: '__SELECT',
            selectOptions: dropdownValues?.periods,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.FACILITY]: {
            Header: 'Facility',
            Cell: ({ value }: { value: string }) =>
                dropdownValues?.facilities.find((e) => e.code === value)?.desc || '',
            dataType: '__SELECT',
            selectOptions: dropdownValues?.facilities,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
    };

    const ExcelExportButton: TrackItFC<IExcelExportButtonProps> = () => {
        const { data } = useContext<any>(EAMGridContext);

        const handleExport: React.MouseEventHandler = () => {
            const filteredData = data.map((d: { [key: string]: string }) =>
                Object.fromEntries(
                    Object.entries(d)
                        .filter(([key]) => Object.keys(columnOverrides).includes(key))
                        .map(([key, val]) => {
                            switch (key) {
                                case 'ted_edms_rel_sta_eng':
                                    return [key, statusMapping[val]?.text || val];
                                case 'csm_udfchar12':
                                    return [key, dropdownValues?.scopes.find((e) => e.code === val)?.desc || val];
                                case 'csm_udfchar01':
                                    return [key, dropdownValues?.facilities.find((e) => e.code === val)?.desc || val];
                                case 'csm_udfchar06':
                                    return [key, dropdownValues?.periods.find((e) => e.code === val)?.desc || val];
                                case COLUMNS.SYSTEM:
                                    return [
                                        key,
                                        dropdownValues?.systems.find((e) => e.code === val)?.description || '',
                                    ];
                                default:
                                    return [key, val];
                            }
                        })
                        .map(([key, val]) => (val?.includes(',') ? [key, `"${val}"`] : [key, val]))
                )
            );

            const aoa: any[][] = [
                Object.keys(filteredData[0]).map((k) => columnOverrides[k]?.Header ?? k),
                ...filteredData.map(Object.values),
            ];

            const sortedColumns = arrange2DArrayByColumns(aoa, [
                'DOC',
                'Track-it Link System',
                'EDMS Type',
                'Status',
                'Title',
                'Current Version',
                'Author',
                'Deadline Date',
                'Release Date',
                'In',
                'Scope',
                'Period',
                'Facility',
                '1st Pres',
                '2nd Pres',
                'Comment',
            ]);

            const csvContent = `data:text/csv;charset=utf-8,${sortedColumns.map((e) => e.join(',')).join('\n')}`;
            const downloadLink = document.createElement('a');
            downloadLink.href = encodeURI(csvContent).replaceAll('#', '%23');
            downloadLink.download = 'documents.csv';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        return (
            <Button onClick={handleExport} variant="outlined" size="small">
                Export to Excel
            </Button>
        );
    };

    const defaultCreateColumns = ({ gridField, cellRenderer }: { gridField: []; cellRenderer: () => JSX.Element }) =>
        columnsOrder.reduce<any[]>((acc, columnID) => {
            if (!gridField) return acc;
            const field: any = gridField?.find((e: { name: string }) => e.name === columnID);
            if (!field && !columnID.startsWith('__')) return acc;
            return [
                ...acc,
                {
                    id: columnID,
                    Header: field?.label || '',
                    accessor: columnID,
                    width: Number(field?.width || 0),
                    minWidth: 0,
                    maxWidth: 99999,
                    dataType: field?.dataType,
                    Filter: CustomEAMFilter,
                    Cell: cellRenderer || EAMCellField,
                    ...columnOverrides[columnID],
                },
            ];
        }, []);

    const history = useHistory();
    const gridSyncedParamsProps = useGridSyncedParams();

    return (
        <EAMGridContextProvider
            gridName={gridName}
            {...gridSyncedParamsProps}
            // eslint-disable-next-line no-console
            handleError={(e: Error) => console.log(e)} // TODO
            searchOnMount={false}
            createColumns={defaultCreateColumns}
            processData={({ data }: any) =>
                // REVIEW, we are not showing all associated tracks (a document can be associated with multiple tracks), old trackit does the same (intended?)
                data.filter(
                    (e: any, i: number) =>
                        data.findIndex((a: any) => a.ted_displayed_doc_name === e.ted_displayed_doc_name) === i
                )
            }
            filterProcessor={(e: IAny) => ({
                ...e,
                operator: columnOverrides[e.fieldName]?.dataType === '__SELECT' ? '=' : e.operator,
            })}
        >
            <EAMGrid
                customFooterOptions={() => <ExcelExportButton />}
                getCellProps={() => ({
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    },
                })}
                getRowProps={(row: IAny) => ({
                    title:
                        row.original.csm_casetype === 'TRACKIS'
                            ? 'Open integration study'
                            : row.original.csm_casetype === 'TRACKTSK'
                            ? 'Open task'
                            : row.original.csm_casetype === 'TRACK'
                            ? 'Open track'
                            : null,
                    hover: true,
                    style: {
                        width: 'unset',
                        textDecoration: 'none',
                        cursor: 'pointer',
                    },
                    onClick: (e: IAny) => {
                        const caseType = row.original.csm_casetype;
                        const subPath =
                            caseType === 'TRACK'
                                ? ROUTES.tracks.view
                                : caseType === 'TRACKTSK'
                                ? ROUTES.tasks.view
                                : ROUTES.integrationStudies.view;
                        const path = getRoute({ path: subPath, params: { code: row.values.ted_track } });
                        if (e.ctrlKey) {
                            window.open(path.startsWith('/') ? `${process.env.PUBLIC_URL}${path} ` : path, '_blank');
                        } else {
                            history.push(path);
                        }
                    },
                })}
            />
        </EAMGridContextProvider>
    );
};

export default DocumentsPage;
