import { makeStyles, MenuItem, Select } from '@material-ui/core';
import { SaveContext } from 'components/generic/save';
import { TrackItFC } from 'helpers/helperTypes';
import React, { useContext } from 'react';

type TOption = { code: any; desc: string };

const useStyles = (textAlign: any) =>
    makeStyles({
        icon: {
            display: 'none',
        },
        root: {
            textOverflow: 'clip',
            textAlign,
        },
        select: {
            marginRight: '-24px',
        },
    });

interface IDiscreteSelectProps {
    valueKey: string;
    options: TOption[];
    value?: TOption;
    onChange?: (value: any) => void;
    onClose?: (value: any) => void;
    width?: string;
    textAlign?: any;
}

const DiscreteSelect: TrackItFC<IDiscreteSelectProps> = ({
    valueKey,
    options,
    onChange,
    onClose,
    value,
    width,
    textAlign = 'center',
}) => {
    const classes = useStyles(textAlign)();
    const { addDirty } = useContext(SaveContext);
    const handleChange = (
        ev: React.ChangeEvent<{
            name?: string | undefined;
            value: unknown;
        }>
    ) => {
        addDirty(valueKey);
        onChange?.(ev.target.value);
    };

    return (
        <Select
            style={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                width,
            }}
            value={value?.code ?? ''}
            onChange={handleChange}
            onClose={onClose}
            inputProps={{
                classes: {
                    icon: classes.icon,
                    root: classes.root,
                    select: classes.select,
                },
            }}
        >
            {options.map((opt) => (
                <MenuItem key={opt.code} value={opt.code}>
                    {opt.desc}
                </MenuItem>
            ))}
        </Select>
    );
};

export default DiscreteSelect;
