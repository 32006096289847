import { Theme, ThemeProvider } from '@material-ui/core/styles';
import IntegrationStudy from 'components/entities/IntegrationStudy';
import Task from 'components/entities/Task';
import Track from 'components/entities/Track';
import Themes from 'eam-components/dist/ui/components/theme';
import ApplicationLayout from 'layout/ApplicationLayout';
import DocumentsPage from 'pages/DocumentsPage';
import HomePage from 'pages/HomePage';
import RedirectPage from 'pages/RedirectPage';
import SearchPage from 'pages/SearchPage';
import TasksPage from 'pages/TasksPage';
import TracksPage from 'pages/TracksPage';
import ViewLogPage from 'pages/ViewLogPage';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ROUTES from 'routes/Routes';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import config from './config';

const TrackIt = () => {
    const { applicationData } = useApplicationData();

    const selectedTheme: Theme = applicationData?.environment
        ? Themes[config.theme[applicationData.environment]]
        : Themes.DANGER;

    return (
        <ThemeProvider theme={selectedTheme}>
            <Switch>
                <Route path={ROUTES.logs} component={ViewLogPage} />
                <Route>
                    <ApplicationLayout>
                        <Switch>
                            <Route path={ROUTES.globalSearch} component={SearchPage} />
                            <Route path={ROUTES.tracks.search} component={TracksPage} />
                            <Route path={ROUTES.tracks.create} component={Track} />
                            <Route path={ROUTES.tracks.view} component={Track} />
                            <Route path={ROUTES.integrationStudies.create} component={IntegrationStudy} />
                            <Route path={ROUTES.integrationStudies.view} component={IntegrationStudy} />
                            <Route path={ROUTES.tasks.search} component={TasksPage} key="tasks" />
                            <Route path={ROUTES.mytasks.search} component={TasksPage} key="mytasks" />
                            <Route path={ROUTES.tasks.create} component={Task} />
                            <Route path={ROUTES.tasks.view} component={Task} />
                            <Route path={ROUTES.documents.search} component={DocumentsPage} />
                            <Route path={ROUTES.redirect} component={RedirectPage} />
                            <Route exact path={ROUTES.homepage} component={HomePage} />
                        </Switch>
                    </ApplicationLayout>
                </Route>
            </Switch>
        </ThemeProvider>
    );
};

export default TrackIt;
