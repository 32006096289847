import { Box } from '@material-ui/core';
import { TrackItFC } from 'helpers/helperTypes';
import DialogLayout from 'layout/DialogLayout';
import React from 'react';
import { useDialogState } from './dialogContext';
import { EntityContextProvider } from './entityContext';

const withEntity: (Component: any) => TrackItFC<any> = (Component) => (props) => {
    const [dialogData] = useDialogState();
    const { type } = dialogData ?? {};
    return (
        <Box style={{ width: '100%', height: '100%' }}>
            <EntityContextProvider>
                <Component {...props} />
                <DialogLayout key={type} />
            </EntityContextProvider>
        </Box>
    );
};

export default withEntity;
