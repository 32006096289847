import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { OkButton } from 'components/generic/dialog';
import { useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TTextOkDialog } from './dialogStates';

const TextOkDialog = () => {
    const [
        {
            state: { text, title, callback, buttonLabel },
        },
    ] = useDialogState<TTextOkDialog>();
    return (
        <>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <OkButton callback={callback} label={buttonLabel} />
            </DialogActions>
        </>
    );
};

export default TextOkDialog;
