import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import trackApi from 'api/track';
import { Checkbox, CloseButton, ValidateButton } from 'components/generic/dialog';
import { IAny } from 'helpers/helperTypes';
import { useData, useReload } from 'middleware/data';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TDetachSubTrackDialog } from './dialogStates';
import DialogTypes from './types';

const DetachSubTrackDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TDetachSubTrackDialog>();
    const [{ code }] = useData('general');
    const [hierarchy] = useData('hierarchy');

    const { children } = hierarchy[code] || {};

    const reloadHierarchy = useReload('hierarchy');

    const updateSubtrack = (subtrack: IAny) => updateState({ subtracks: { ...(state.subtracks || []), ...subtrack } });

    const checkboxState = {
        ...Object.fromEntries(Object.values(children).map((child: any) => [child.code, false] as const)),
        ...state.subtracks,
    };

    const checkboxes = Object.values(children).map((subtrack: any) => (
        <Checkbox
            label={`${subtrack.code} - ${subtrack.description}`}
            state={checkboxState}
            valueKey={subtrack.code}
            update={updateSubtrack}
            key={subtrack.code}
            optional
        />
    ));

    const apiData = {
        parentTrackCode: code,
        subtracksToDetach: Object.entries(checkboxState)
            .filter(([, value]) => value)
            .map(([key]) => key),
    };

    // the 2 styles below compensate for the fact that the Checkbox has the style "width: 50%"
    const contentStyle: any = { overflowX: 'hidden' };
    const contentDivStyle: any = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '200%',
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title">Detach Sub-Track</DialogTitle>
            <DialogContent style={contentStyle}>
                <div style={contentDivStyle}>{checkboxes}</div>
            </DialogContent>
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => trackApi.detachSubtrack(apiData),
                                callback: () => {
                                    reloadHierarchy();
                                    dispatchDialog(null);
                                },
                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: {
                                            title: 'Detach Sub-Track',
                                            text: 'There was an error detaching the Sub-Track.',
                                        },
                                    }),
                            },
                        })
                    }
                    label="Confirm"
                />
            </DialogActions>
        </>
    );
};

export default DetachSubTrackDialog;
