import { TrackItFC } from 'helpers/helperTypes';
import React, { useState } from 'react';
import createContextTuple from './createContextTuple';

export type TDropdownData = {
    facilities: { code: string; desc: string }[];
    installation: { code: string; desc: string }[];
    isStatuses: { code: string; desc: string }[];
    periods: { code: string; desc: string }[];
    scopes: { code: string; desc: string }[];
    taskPhases: { code: string; desc: string }[];
    taskStatuses: { code: string; desc: string }[];
    trackMaturityLevel: { code: string; desc: string }[];
    trackStatuses: { code: string; desc: string }[];
    systems: { code: string; description: string; onlyIS: boolean }[];
    entities: { code: string; desc: string }[];
    inEntities: { code: string; desc: string }[];
    needTypesIntegrationStudy: { code: string; desc: string }[];
    needTypesTrack: { code: string; desc: string }[];
    logTypes: { code: string; desc: string }[];
    group: { code: string; desc: string }[];
};

interface IDropdownDataProviderProps {
    children: React.ReactNode;
}

const [hook, Provider] = createContextTuple<{
    dropdownValues: TDropdownData | null;
    setDropdownValues: (value: TDropdownData | null) => void;
}>();
export const useDropdownValues = hook;

const DropdownDataStaticProvider: TrackItFC<IDropdownDataProviderProps> = ({ children }) => {
    const [dropdownValues, setDropdownValues] = useState<TDropdownData | null>(null);

    return <Provider value={{ dropdownValues, setDropdownValues }}>{children}</Provider>;
};

export default DropdownDataStaticProvider;
