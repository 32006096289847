import client from 'api/client';
import EntitySearchData from 'models/EntitySearchData';

const RESOURCE_PATH = `${process.env.REACT_APP_BACKEND}/logs`;

const getLogs = async (entitySearchData: EntitySearchData) => client.post(`${RESOURCE_PATH}`, entitySearchData);

export default {
    getLogs,
};
