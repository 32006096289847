import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import trackApi from 'api/track';
import { Checkbox, CloseButton, OkButton } from 'components/generic/dialog';
import InputProvider from 'components/generic/InputProvider';
import { useData } from 'middleware/data';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { TCloneTrackDialog } from './dialogStates';
import DialogTypes from './types';

const CloneTrackDialog = () => {
    const history = useHistory();
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TCloneTrackDialog>();
    const [{ code }] = useData('general');

    const { integrationStudies = false, tasks = false, needs = false, links = false, sameParent = false } = state;

    const apiData = {
        trackCode: code,
        integrationStudies,
        tasks,
        needs,
        links,
        sameParent,
    };
    return (
        <>
            <DialogTitle id="alert-dialog-title">Clone Track</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please check the elements you would like to clone in the new track.
                </DialogContentText>
                <InputProvider state={state} update={updateState} optional>
                    <Checkbox optional label="Links to integration studies" valueKey="integrationStudies" />
                    <Checkbox optional label="Tasks" valueKey="tasks" />
                    <Checkbox optional label="Needs" valueKey="needs" />
                    <Checkbox optional label="Links" valueKey="links" />
                    <Checkbox optional label="Attach the cloned track to the same parent" valueKey="sameParent" />
                </InputProvider>
            </DialogContent>
            <DialogActions>
                <CloseButton label="Cancel" />
                <OkButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => trackApi.cloneTrack(apiData),
                                callback: (response) => {
                                    const data = response && response.body && response.body.data;
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_YES_NO,
                                        state: {
                                            title: `Track cloned successfully: ${data}`,
                                            text: `Do you want to navigate to the cloned track?`,
                                            callback: () => {
                                                history.push({
                                                    pathname: getRoute({
                                                        path: ROUTES.tracks.view,
                                                        params: { code: data },
                                                    }),
                                                });
                                                dispatchDialog(null);
                                            },
                                        },
                                    });
                                },

                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: {
                                            title: 'Clone Track',
                                            text: 'There was an error cloning the Track',
                                        },
                                    }),
                            },
                        })
                    }
                    label="Confirm"
                />
            </DialogActions>
        </>
    );
};

export default CloneTrackDialog;
