import { IAny, TrackItFC } from 'helpers/helperTypes';
import React, { useState } from 'react';
import createContextTuple from './createContextTuple';

interface ISettingsDataProviderProps {
    children: React.ReactNode;
}

const [hook, Provider] = createContextTuple<{
    settingsData: IAny | null;
    setSettingsData: (value: IAny | null) => void;
}>();
export const useSettingsData = hook;

const SettingsDataStaticProvider: TrackItFC<ISettingsDataProviderProps> = ({ children }) => {
    const [settingsData, setSettingsData] = useState<IAny | null>(null);

    return <Provider value={{ settingsData, setSettingsData }}>{children}</Provider>;
};

export default SettingsDataStaticProvider;
