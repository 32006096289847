import DialogContent from '@material-ui/core/DialogContent';
import { Checkbox, Input, Select } from 'components/generic/dialog';
import InputProvider from 'components/generic/InputProvider';
import React from 'react';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';

const overflowStyle: any = {
    overflowY: 'visible',
};

const NeedDialog = (props: any) => {
    const { state, updateState } = props;

    const { dropdownValues } = useDropdownValues();

    const typesKey = state.entity === 'track' ? 'needTypesTrack' : 'needTypesIntegrationStudy';

    const titleRequired = ['00_TRK_CUSTOM', 'AA_CUSTOM'].includes(state.type);

    return (
        <DialogContent style={overflowStyle}>
            <InputProvider state={state} update={updateState}>
                <Select
                    label="Type"
                    required
                    values={dropdownValues?.[typesKey]?.sort((a, b) => (a.code > b.code ? 1 : -1))}
                    valueKey="type"
                />
                {titleRequired && (
                    <Input
                        label="Title"
                        required={titleRequired}
                        optional={!titleRequired}
                        readonly={titleRequired}
                        valueKey="title"
                        maxLength={100}
                    />
                )}
                <Input label="Details" optional valueKey="description" maxLength={100} />
                <Checkbox label="Required" optional valueKey="isRequiredBool" />
            </InputProvider>
        </DialogContent>
    );
};

export default NeedDialog;
