import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import trackApi from 'api/track';
import { CloseButton, Input, ValidateButton } from 'components/generic/dialog';
import InputProvider from 'components/generic/InputProvider';
import { useData, useReload } from 'middleware/data';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TAttachTrackDialog } from './dialogStates';
import DialogTypes from './types';

const AttachTrackDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TAttachTrackDialog>();
    const [{ code }] = useData('general');

    const { parentTrackCode } = state;

    const reloadHierarchy = useReload('hierarchy');

    const apiData = {
        parentTrackCode,
        childIntegrationStudyCode: code,
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title">Attach to Track</DialogTitle>
            <DialogContent>
                <InputProvider>
                    <Input label="Track ID" optional state={state} valueKey="parentTrackCode" update={updateState} />
                </InputProvider>
            </DialogContent>
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => trackApi.attachIntegrationStudy(apiData),
                                callback: () => {
                                    reloadHierarchy();
                                    dispatchDialog(null);
                                },
                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: {
                                            title: 'Attach to Track',
                                            text: 'There was an error attaching the Track.',
                                        },
                                    }),
                            },
                        })
                    }
                    label="Confirm"
                />
            </DialogActions>
        </>
    );
};

export default AttachTrackDialog;
