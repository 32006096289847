import GridTools from 'eam-components/dist/ui/components/grids/GridTools';
import { IAny } from 'helpers/helperTypes';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useGridSyncedParams = () => {
    const history = useHistory();
    const initialDataspyID = GridTools.getURLParameterByName('gridDataspyID');
    const initialSortColumn = GridTools.getURLParameterByName('sortColumn');
    const initialSortOrder = GridTools.getURLParameterByName('sortOrder');

    const location = useLocation();

    const filters = useMemo(() => {
        const gridFiltersParam = GridTools.getURLParameterByName('gridFilters');
        let gridFilters = GridTools.parseGridFilters(gridFiltersParam);
        if (!gridFilters.length) {
            const search = window.localStorage.getItem(`gridState##${location.pathname}`) || '';
            const params = queryString.parse(search);
            gridFilters = GridTools.parseGridFilters(params.gridFilters);
        }
        const gridStateSearch = GridTools.replaceUrlParam('gridFilters', GridTools.stringifyGridFilters(gridFilters));
        history.push({ pathname: location.pathname, search: gridStateSearch });
        window.localStorage.setItem(`gridState##${location.pathname}`, gridStateSearch);
        return gridFilters;
    }, []);

    return {
        initialFilters: filters,
        onChangeFilters: (newFilters: IAny) => {
            const search = GridTools.replaceUrlParam('gridFilters', GridTools.stringifyGridFilters(newFilters));
            history.push({ pathname: location.pathname, search });
            window.localStorage.setItem(`gridState##${location.pathname}`, search);
        },
        initialDataspyID,
        onChangeDataspy: (newDataspy: IAny) => {
            const params = GridTools.replaceUrlParam('gridDataspyID', newDataspy.code);
            history.push(params);
        },
        initialSortBy: initialSortColumn
            ? [
                  {
                      id: initialSortColumn,
                      desc: initialSortOrder === 'desc',
                  },
              ]
            : [],
        onChangeSortBy: (newSortBy: IAny) => {
            const sortBy = newSortBy && newSortBy[0];
            const params = queryString.parse(location.search);
            const newParams = params;
            if (sortBy) {
                newParams.sortColumn = sortBy.id;
                newParams.sortOrder = sortBy.desc ? 'desc' : 'asc';
            } else {
                delete newParams.sortColumn;
                delete newParams.sortOrder;
            }
            const newSearch = queryString.stringify(newParams);
            history.push({
                pathname: location.pathname,
                search: newSearch,
            });
        },
    };
};

export default useGridSyncedParams;
