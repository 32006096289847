enum DialogTypes {
    TEXT_CLOSE,
    TEXT_OK,
    TEXT_YES_NO,
    LOADING,
    CLONE_TRACK,
    ATTACH_SUBTRACK,
    ATTACH_IS,
    DETACH_IS,
    ATTACH_TRACK,
    DETACH_SUBTRACK,
    DETACH_PARENTTRACK,
    DETACH_PARENTIS,
    ADD_LINK,
    EDIT_LINK,
    ADD_NEED,
    EDIT_NEED,
    ADD_TASK,
    EDIT_TASK,
    ADD_INTEGRATION_STUDY,
}

export default DialogTypes;
