import trackApi from 'api/track';
import DialogTypes from 'components/dialogs/types';
import RenderEntity from 'components/generic/RenderEntity';
import { SaveProvider } from 'components/generic/save';
import EntityDetailsRegion from 'components/regions/header/EntityDetailsRegion';
import { LinksHierarchyRegion } from 'components/regions/header/HierarchyRegions';
import NewEntityParentRegion from 'components/regions/header/NewEntityParentRegion';
import CommentsRegion from 'components/regions/side/CommentsRegion';
import CreateFromDocumentRegion from 'components/regions/side/CreateFromDocumentRegion';
import GeneralInformationRegion from 'components/regions/side/GeneralInformationRegion';
import IntegrationStudyRegion from 'components/regions/side/IntegrationStudiesRegion';
import LinksRegion from 'components/regions/side/LinksRegion';
import NeedsRegion from 'components/regions/side/NeedsRegion';
import StatusOverviewRegion from 'components/regions/side/StatusOverviewRegion';
import TasksRegion from 'components/regions/side/TasksRegion';
import EDMSDoclightRegion from 'components/regions/side/EDMSDoclightRegion';
import { TrackItFC } from 'helpers/helperTypes';
import { useMetadata, useUnsafeData } from 'middleware/data';
import { useDialogDispatch } from 'middleware/dialogContext';
import useQueryParams from 'middleware/useQueryParams';
import withEntity from 'middleware/withEntity';
import React, { useCallback, useEffect, useMemo } from 'react';
import { RouteComponentProps } from 'react-router';
import { useParams, withRouter } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useUserData } from 'store/contexts/UserDataContext';
import { TEntityAction } from './entityActions';

interface ITrackProps extends RouteComponentProps {}
const Track: TrackItFC<ITrackProps> = (props: any) => {
    const { code, history } = props;
    const { parent } = useQueryParams();
    const dispatchDialog = useDialogDispatch();
    const { readonly } = useUserData();

    const apiArgs = useMemo(
        () => ({
            code,
            parent,
        }),
        [code, parent]
    );

    useEffect(() => {
        const prefix = code === undefined ? 'New ' : '';
        const suffix = code === undefined ? '' : ` ${code}`;
        document.title = `${prefix}Track${suffix}`;
    }, []);

    const newEntity = code === undefined;

    const emptyRequest = async () => ({ response: { data: {} } });

    const mandatoryLoaders = useMemo(
        () => ({
            general: newEntity ? trackApi.getTrackDefault : trackApi.getTrack,
            hierarchy: newEntity ? () => [] : async () => trackApi.getHierarchyFromTracks({ trackCodes: [code] }),
        }),
        [newEntity, code]
    );

    const loaders = useMemo(
        () => ({
            tasks: newEntity ? emptyRequest : trackApi.getTasksFromTrack,
            studies: newEntity ? emptyRequest : trackApi.getStudiesFromTrack,
            needs: newEntity ? emptyRequest : trackApi.getNeedsFromTrack,
            links: newEntity ? emptyRequest : trackApi.getLinksFromTrack,
            comments: newEntity ? emptyRequest : trackApi.getCommentsFromTrack,
            trackLinks: newEntity ? emptyRequest : async () => trackApi.getTrackLinksForCases({ cases: [code] }),
        }),
        [newEntity, code]
    );

    const [, updateMetadata] = useMetadata();

    const saver = useCallback(
        async ({ data }: any) => {
            const { general } = data;

            const { scope, period, facility } = general;

            if (!scope || !period || !facility) {
                throw new Error('Scope, period and facility are required');
            }

            if (newEntity) {
                return await trackApi.createTrack(general);
            }

            return await trackApi.saveTrack(general);
        },
        [newEntity]
    );

    const afterSave = newEntity
        ? ({ response }: any) =>
              history.push({
                  pathname: getRoute({ path: ROUTES.tracks.view, params: { code: response.body.data } }),
                  state: { disablePrompt: true },
              })
        : ({ entity, updateEntity }: any) => {
              updateEntity(['data', 'general'], { updateCount: entity.data.general.updateCount + 1 });
              dispatchDialog({
                  type: DialogTypes.TEXT_CLOSE,
                  state: {
                      title: 'Track saved',
                      text: 'The track has been saved successfully.',
                  },
              });
          };

    const entity = 'track';
    useEffect(() => {
        updateMetadata({
            entity,
            newEntity,
            dirty: [],
        });
    }, [code]);

    const [general] = useUnsafeData('general');
    const [hierarchy] = useUnsafeData('hierarchy');

    const actions: TEntityAction[] = useMemo(
        () =>
            newEntity
                ? []
                : [
                      {
                          name: 'Clone Track',
                          onClick: () => dispatchDialog({ type: DialogTypes.CLONE_TRACK }),
                      },
                      {
                          name: 'Attach sub-track',
                          onClick: () =>
                              dispatchDialog({
                                  type: DialogTypes.ATTACH_SUBTRACK,
                              }),
                          disabled: !general || general.status === 'TRK_STAT_40',
                      },
                      {
                          name: 'Attach IS',
                          onClick: () =>
                              dispatchDialog({
                                  type: DialogTypes.ATTACH_IS,
                              }),
                      },
                      {
                          name: 'Detach IS',
                          onClick: () =>
                              dispatchDialog({
                                  type: DialogTypes.DETACH_IS,
                              }),
                      },
                      {
                          name: 'Create sub-track',
                          onClick: () =>
                              history.push(getRoute({ path: ROUTES.tracks.create, queryParams: { parent: code } })),
                      },
                      {
                          name: 'Detach sub-track',
                          onClick: () =>
                              dispatchDialog({
                                  type: DialogTypes.DETACH_SUBTRACK,
                              }),
                          disabled: !hierarchy || !hierarchy?.[code]?.children,
                      },
                      {
                          name: 'Detach from parent',
                          onClick: () =>
                              dispatchDialog({
                                  type: DialogTypes.DETACH_PARENTTRACK,
                              }),
                          disabled: !hierarchy || !hierarchy?.[code]?.parents,
                      },
                  ],
        [newEntity, hierarchy]
    );

    const headerRegions = useMemo(
        () =>
            newEntity
                ? [EntityDetailsRegion, ...(parent ? [NewEntityParentRegion] : [])]
                : [EntityDetailsRegion, LinksHierarchyRegion],
        [newEntity, parent]
    );
    const leftRegions = useMemo(
        () =>
            newEntity
                ? [GeneralInformationRegion, ...(!readonly ? [CreateFromDocumentRegion] : [])]
                : [GeneralInformationRegion, TasksRegion, IntegrationStudyRegion, EDMSDoclightRegion],
        [newEntity]
    );
    const rightRegions = useMemo(
        () => (newEntity ? [] : [StatusOverviewRegion, CommentsRegion, NeedsRegion, LinksRegion]),
        [newEntity]
    );

    return (
        <SaveProvider
            title={entity}
            saver={saver}
            afterSave={afterSave}
            error={(err: any) =>
                dispatchDialog({
                    type: DialogTypes.TEXT_CLOSE,
                    state: {
                        title: 'Track operation failed',
                        text: err?.response?.body?.errors?.[0]?.detail || err?.message,
                    },
                })
            }
        >
            <RenderEntity
                mandatoryLoaders={mandatoryLoaders}
                loaders={loaders}
                apiArgs={apiArgs}
                header={headerRegions}
                left={leftRegions}
                right={rightRegions}
                blockOnMetadata
                key={code}
                actions={actions}
                readonly={readonly}
            />
        </SaveProvider>
    );
};

const KeyedTrack = (props: any) => {
    const { code } = useParams<{ code: string; parent: string }>();

    const Component = withEntity(withRouter(Track));
    return <Component key={code} code={code} {...props} />;
};

export default KeyedTrack;
