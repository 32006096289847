import { Box, ButtonBase, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { TEntityAction } from 'components/entities/entityActions';
import { TrackItFC } from 'helpers/helperTypes';
import React, { useState } from 'react';

interface IActionDotsProps {
    actions: TEntityAction[];
    isStatic?: boolean;
}

const ActionDots: TrackItFC<IActionDotsProps> = ({ actions, isStatic }) => {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const handleClose = () => setAnchor(null);

    return (
        <Box style={{ position: isStatic ? 'initial' : 'absolute', top: 0, right: 0 }}>
            <ButtonBase
                style={{
                    justifyContent: 'space-between',
                }}
                onClick={(ev) => setAnchor(ev.currentTarget)}
            >
                <MoreVert color="action" />
            </ButtonBase>
            <Popper style={{ zIndex: 999 }} open={Boolean(anchor)} anchorEl={anchor} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            marginTop: 5,
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={Boolean(anchor)}>
                                    {actions.map((action) => (
                                        <MenuItem
                                            key={action.name}
                                            onClick={() => {
                                                action.onClick();
                                                handleClose();
                                            }}
                                        >
                                            {action.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default ActionDots;
