import { ISquareIconProps } from 'components/icons/interfaces';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';

const iconStyle = (size?: number, color?: string) => ({
    size: size ? `${size}px` : '40px',
    color: color || '#f7ce03',
});

export interface IColouredIconProps {
    icon: TrackItFC<ISquareIconProps>;
    size?: number;
    color?: string;
}

const ColoredIcon: TrackItFC<IColouredIconProps> = ({ size, color, icon: Icon }) => (
    <Icon {...iconStyle(size, color)} />
);

export default ColoredIcon;
