import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAny } from 'helpers/helperTypes';
import createPartialDynamicSlice from './reducers/partialDynamic';

export const [entityActionsReducer, entityActionsActions] = createPartialDynamicSlice<{
    name: string;
    onClick: () => void;
    location: string[];
}>('ENTITY_ACTIONS');

const settingsDataSlice = createSlice({
    name: 'SETTINGS_DATA',
    initialState: { value: null as IAny | null },
    reducers: {
        init: (_, action: PayloadAction<IAny>) => ({
            value: action.payload,
        }),
        update: (state, action: PayloadAction<IAny>) => ({
            value: { ...state, ...action.payload },
        }),
    },
});

export const settingsDataActions = settingsDataSlice.actions;
const settingsDataReducer = settingsDataSlice.reducer;

export const sliceReducersObject = {
    settingsDataReducer,
    entityActionsReducer,
};
