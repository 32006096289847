import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';

const containerStyle = {};

interface IHeaderRegionRendererProps {
    element?: TrackItFC;
}

const HeaderRegionRenderer: TrackItFC<IHeaderRegionRendererProps> = ({ element: Element }) => (
    <div style={containerStyle}>{Element && <Element />}</div>
);
export default HeaderRegionRenderer;
