import client from 'api/client';

const RESOURCE_PATH = `${process.env.REACT_APP_BACKEND}/tasks`;

export const getTask = async ({ code }) => await client.get(`${RESOURCE_PATH}?code=${code}`);
export const saveTask = async (data) => await client.put(`${RESOURCE_PATH}`, data);
export const getTaskDefault = async () => await client.get(`${RESOURCE_PATH}/default`);
export const createTask = async (data, parent) => await client.post(`${RESOURCE_PATH}/${parent}`, data);
export const getParent = async ({ code }) => await client.get(`${RESOURCE_PATH}/parent/${code}`);
export const deleteTask = async ({ code }) => await client.delete(`${RESOURCE_PATH}?code=${code}`);

const all = {
    getTask,
    saveTask,
    getTaskDefault,
    createTask,
    getParent,
};

export default all;
