import { Box, BoxProps, ButtonBase, Collapse } from '@material-ui/core';
import { ArrowForwardIos } from '@material-ui/icons';
import { ISquareIconProps } from 'components/icons/interfaces';
import { TrackItFC } from 'helpers/helperTypes';
import React, { useState } from 'react';

export interface IRegionAccordionItemButton {
    icon: TrackItFC<ISquareIconProps>;
    callback: (e: React.MouseEvent) => void;
}
interface IRegionAccordionItemProps {
    header: string;
    subtitle?: string;
    style?: BoxProps['style'];
    children: React.ReactNode;
    startsOpen?: boolean;
    buttonRight?: IRegionAccordionItemButton;
}

const subtitleStyle: BoxProps['style'] = {
    paddingLeft: 10,
    fontSize: '14px',
    color: '#525252',
    fontFamily: 'Roboto,sans-serif',
    margin: '-2px',
    fontWeight: 400,
    lineHeight: '1.35em',
    borderRadius: '3px',
};

const RegionAccordionItem: TrackItFC<IRegionAccordionItemProps> = ({
    header,
    subtitle,
    children,
    startsOpen,
    buttonRight,
    style,
}) => {
    const [open, setOpen] = useState(startsOpen);

    const headerStyle: BoxProps['style'] = {
        fontSize: '16px',
        padding: '6px 0',
        color: '#525252',
        fontFamily: 'Roboto,sans-serif',
        fontWeight: 450,
        justifyContent: 'space-between',
        display: 'flex',
        cursor: 'pointer',
        borderBottom: open ? '1px solid #d1d1d1' : undefined,
        backgroundColor: '#eceff1',
        userSelect: 'none',
        borderRadius: open ? 0 : 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
    };

    return (
        <Box style={style}>
            <Box style={headerStyle}>
                <Box style={{ display: 'flex', alignItems: 'center', flexBasis: '95%' }} onClick={() => setOpen(!open)}>
                    <Box style={{ padding: '0 10px', display: 'flex', justifyContent: 'space-around' }}>
                        <ArrowForwardIos
                            fontSize="small"
                            color="action"
                            style={{ transition: '0.1s linear', transform: open ? 'rotate(90deg)' : 'none' }}
                        />
                    </Box>
                    <Box style={{ textTransform: 'uppercase' }}>{header}</Box>
                    <Box style={subtitleStyle}>{subtitle}</Box>
                </Box>

                <Box style={{ padding: '0 10px', display: 'flex', justifyContent: 'space-around' }}>
                    {buttonRight && (
                        <ButtonBase onClick={buttonRight.callback}>
                            <buttonRight.icon size={20} color="#757575" />
                        </ButtonBase>
                    )}
                </Box>
            </Box>

            <Collapse in={open} timeout={100}>
                <Box style={{ padding: 10 }}>{children}</Box>
            </Collapse>
        </Box>
    );
};

export default RegionAccordionItem;
