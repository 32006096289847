import {
    Box,
    ButtonBase,
    ClickAwayListener,
    Grow,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';
import { TEntityAction } from 'components/entities/entityActions';
import { TrackItFC } from 'helpers/helperTypes';
import React, { useState } from 'react';

interface IEntityActionsDropdownProps {
    actions: TEntityAction[];
}

const EntityActionsDropdown: TrackItFC<IEntityActionsDropdownProps> = ({ actions }) => {
    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const handleClose = () => setAnchor(null);

    if (actions.length === 0) {
        return <></>;
    }

    return (
        <Box>
            <ButtonBase
                style={{
                    backgroundColor: '#eceff1',
                    borderRadius: 2,
                    padding: '10px 10px 10px 5px',
                    justifyContent: 'space-between',
                }}
                onClickCapture={(ev) => {
                    ev.stopPropagation();
                    setAnchor(ev.currentTarget);
                }}
            >
                <ExpandMore />
                <Typography>ACTIONS</Typography>
            </ButtonBase>
            <Popper style={{ zIndex: 999 }} open={Boolean(anchor)} anchorEl={anchor} role={undefined} transition>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            marginTop: 5,
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose}>
                                <MenuList autoFocusItem={Boolean(anchor)}>
                                    {actions.map((action) => (
                                        <MenuItem
                                            key={action.name}
                                            onClick={() => {
                                                action.onClick();
                                                handleClose();
                                            }}
                                            disabled={action.disabled}
                                        >
                                            {action.name}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </Box>
    );
};

export default EntityActionsDropdown;
