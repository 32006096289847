import { SvgIcon } from '@material-ui/core';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import { ISquareIconProps } from './interfaces';

const DocumentsIcon: TrackItFC<ISquareIconProps> = ({ size, color }) => (
    <SvgIcon style={{ width: size, height: size }}>
        <g transform="matrix(.94172 0 0 .94172 -271.9 -203.29)" fill={color}>
            <path
                d="m295.76 221.61v14.008h11.407v-9.6902l-4.3721-4.3177zm2.495 5.972h6.2982v0.98785h-6.2982zm0 2.0994h6.2982v0.98784h-6.2982zm0 2.0981h6.2982v0.98785h-6.2982z"
                style={{
                    paintOrder: 'stroke markers fill',
                }}
            />
            <path
                d="m295.1 217.41-6.3555 11.211 6.3555 11.211h12.721l0.10742-0.19141 6.248-11.02-6.3555-11.211zm0.43555 0.75h11.848l5.9316 10.461-5.9316 10.461h-11.848l-5.9297-10.461z"
                colorRendering="auto"
                dominantBaseline="auto"
                imageRendering="auto"
                shapeRendering="auto"
                style={{
                    fontFeatureSettings: 'normal',
                    fontVariantAlternates: 'normal',
                    fontVariantCaps: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantNumeric: 'normal',
                    fontVariantPosition: 'normal',
                    isolation: 'auto',
                    mixBlendMode: 'normal',
                    paintOrder: 'stroke markers fill',
                    textDecorationLine: 'none',
                    textDecorationStyle: 'solid',
                    textIndent: '0',
                    textOrientation: 'mixed',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                }}
            />
        </g>
    </SvgIcon>
);

export default DocumentsIcon;
