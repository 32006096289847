export type StrictlyDefined<T> = T extends undefined ? never : T;

export type TrackItFC<TProps extends object = {}> = (props: TProps) => JSX.Element;

export type NestedKeyOf<T, K extends PropertyKey> = T extends object
    ? {
          [P in keyof T]-?: (P extends K ? keyof T[P] : never) | NestedKeyOf<T[P], K>;
      }[keyof T]
    : never;
export interface IAny {
    [key: string]: any;
}

export const getKeysOf = Object.keys as <T extends object>(obj: T) => Array<keyof T>;
