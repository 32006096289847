/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
/* eslint-disable @typescript-eslint/no-unused-vars */ // TODO, remove
import { Box, BoxProps, ButtonBase, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { AccountTree, History } from '@material-ui/icons';
import { TEntityAction } from 'components/entities/entityActions';
import EntityActionsDropdown from 'components/renderers/EntityActionsDropdown';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import LoadingPane from 'layout/LoadingPane';
import { AlertOctagon } from 'mdi-material-ui';
import { useMetadata } from 'middleware/data';
import { useEntityBasic } from 'middleware/entityContext';
import { withChildren } from 'middleware/validation';
import React, { useEffect, useState } from 'react';
import { Prompt } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import { useUserData } from 'store/contexts/UserDataContext';
import HeaderRegionRenderer from './HeaderRegionRenderer';
import RegionRenderer from './RegionRenderer';
import { SaveButton } from './save';

interface IRenderEntityProps {
    apiArgs: any;
    loaders?: { [key: string]: (args: any) => Promise<any> };
    mandatoryLoaders: { [key: string]: (args: any) => Promise<any> };
    full?: TrackItFC;
    header?: TrackItFC[];
    left?: TrackItFC[];
    right?: TrackItFC[];
    middle?: TrackItFC[];
    blockOnMetadata: boolean;
    loadedWrapper: TrackItFC<{ children: React.ReactNode }>;
    actions?: TEntityAction[];
}

const RenderEntity: TrackItFC<IRenderEntityProps> = ({
    apiArgs,
    loaders = {},
    mandatoryLoaders,
    full: Full,
    header,
    left = [],
    right = [],
    blockOnMetadata,
    loadedWrapper: LoadedWrapper,
    actions,
}) => {
    const [entity, updateEntity] = useEntityBasic();
    const { applicationData } = useApplicationData();
    const { readonly } = useUserData();

    const [metadata, updateMetadata] = useMetadata();

    useEffect(() => {
        window.onbeforeunload = () => (metadata?.dirty.length !== 0 ? true : undefined);
    }, [metadata?.dirty]);

    const [error, setError] = useState<IAny[] | null>(null);

    const load = async (name: string, loader: (args: any) => Promise<any>, isMandatory: boolean) => {
        try {
            const response = await loader(apiArgs);
            const data = response && response.body && response.body.data;

            updateEntity(['data', name], data);
            updateEntity(['data', `${name}:first`], data);
            updateEntity(['loaders', name], true);
            updateEntity(['reload', name], async () => {
                try {
                    updateEntity(['loaders', name], false);
                    const reloadResponse = await loader(apiArgs);
                    const reloadData = reloadResponse && reloadResponse.body && reloadResponse.body.data;

                    updateEntity(['data', name], reloadData);
                    updateEntity(['loaders', name], true);
                } catch (e: any) {
                    console.error(e);
                    setError(e?.response?.body?.errors);
                }
            });
        } catch (e: any) {
            // do nothing for now
            console.error(e);
            setError(e.response.body.errors);
        }
    };

    useEffect(() => {
        Object.entries(mandatoryLoaders || []).forEach(([name, loader]) => {
            load(name, loader, true);
        });
        Object.entries(loaders).forEach(([name, loader]) => {
            load(name, loader, false);
        });
    }, []);

    if (error) {
        return (
            <Box
                style={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                }}
            >
                <Box style={{ display: 'flex', alignItems: 'center' }}>
                    <AlertOctagon color="error" style={{ marginRight: 10 }} />
                    <Typography>
                        {`${error
                            .map((e) => e.detail)
                            .join('\n')} If you believe this is an error, please contact CMMS Support.`}
                    </Typography>
                </Box>
            </Box>
        );
    }

    if (
        ((loaders || mandatoryLoaders) &&
            (entity.loaders === undefined || Object.keys(mandatoryLoaders).some((key) => !entity.loaders[key]))) ||
        (entity.metadata === undefined && blockOnMetadata === true)
    ) {
        return <LoadingPane />;
    }

    let wrapped = null;

    if (Full !== undefined) {
        wrapped = (
            <>
                <Full />
            </>
        );
    } else {
        const headerLeftStyle: BoxProps['style'] = {
            display: 'flex',
            flexDirection: 'column',
        };

        const headerRightStyle: BoxProps['style'] = {
            display: 'flex',
            flexDirection: 'column',
            padding: '0.25rem 0',
            boxSizing: 'border-box',
        };

        const headerStyle: BoxProps['style'] = {
            display: 'flex',
            flexFlow: 'row wrap',
            justifyContent: 'space-between',
            borderBottom: '1px solid $d1d1d1',
            boxShadow:
                '0 1px 0 rgb(0 0 0 / 6%), 0 2px 0 rgb(0 0 0 / 8%), 0 3px 0 rgb(0 0 0 / 5%), 0 4px 0 rgb(0 0 0 / 2%)',
            background: '#fafafa',
            zIndex: 99,
            padding: '10px',
        };

        const FixedHeaderComponent = () => (
            <Box style={{ display: 'flex' }}>
                {readonly === false && (
                    <Box style={{ margin: 1 }}>
                        <Prompt
                            message={(location: any) => {
                                const transitionAllowed =
                                    metadata?.dirty.length === 0 || location?.state?.disablePrompt === true;
                                if (transitionAllowed) {
                                    return true;
                                }
                                return 'Changes you made may not be saved.';
                            }}
                        />
                        <SaveButton label={metadata?.newEntity ? 'CREATE' : 'SAVE'} />
                    </Box>
                )}

                {['track', 'integrationStudy'].includes(entity.metadata.entity) && !entity.metadata.newEntity && (
                    <Box style={{ margin: 1 }}>
                        <ButtonBase
                            style={{
                                backgroundColor: '#eceff1',
                                borderRadius: 2,
                                padding: '10px 10px 10px 5px',
                                justifyContent: 'space-between',
                            }}
                            onClick={() =>
                                window.open(
                                    `${applicationData?.trackItVisualToolUrl}/tracks/${entity.data.general.code}`
                                )
                            }
                        >
                            <AccountTree />
                            <Typography style={{ marginLeft: 5 }}>TREE</Typography>
                        </ButtonBase>
                    </Box>
                )}
                {!entity.metadata.newEntity && (
                    <Box style={{ margin: 1 }}>
                        <ButtonBase
                            style={{
                                backgroundColor: '#eceff1',
                                borderRadius: 2,
                                padding: '10px 10px 10px 5px',
                                justifyContent: 'space-between',
                            }}
                            onClick={() =>
                                window.open(
                                    `${process.env.PUBLIC_URL}${getRoute({
                                        path: ROUTES.logs,
                                        queryParams: { code: entity.data.general.code },
                                    })}`
                                )
                            }
                        >
                            <History />
                            <Typography style={{ marginLeft: 5 }}>LOG</Typography>
                        </ButtonBase>
                    </Box>
                )}
                {readonly === false && (
                    <Box style={{ margin: 1 }}>
                        <EntityActionsDropdown actions={actions ?? []} />
                    </Box>
                )}
            </Box>
        );

        const headerComponent = (
            <div style={headerStyle}>
                <div style={headerLeftStyle}>
                    {header?.map((Element, i) => (
                        // error below is ok, as the indices are normally fixed
                        // eslint-disable-next-line react/no-array-index-key
                        <HeaderRegionRenderer element={Element} key={i} />
                    ))}
                </div>
                <div style={headerRightStyle}>
                    <HeaderRegionRenderer element={FixedHeaderComponent} />
                </div>
            </div>
        );

        const containerStyle: BoxProps['style'] = {
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
            height: '100%',
            boxSizing: 'border-box',
        };

        const gridStyle: BoxProps['style'] = {
            padding: '5px',
        };

        const zip = (a: any, b: any) =>
            Array(Math.max(b.length, a.length))
                .fill(undefined)
                .map((_, i) => [a[i], b[i]]);

        const renderRegions = (regions: any) =>
            regions.map((Component: any, i: any) => (
                <Grid item xs={12} key={i}>
                    <RegionRenderer element={Component} />
                </Grid>
            ));

        wrapped = (
            <>
                <div
                    style={containerStyle}
                    onClick={(e) => (metadata?.editMode ? updateMetadata({ editMode: false }) : null)}
                    role="main"
                >
                    {headerComponent}

                    <Grid container style={gridStyle}>
                        <Grid item xs={12} lg={8}>
                            {renderRegions(left)}
                        </Grid>
                        <Grid item xs={12} lg={4}>
                            {renderRegions(right)}
                        </Grid>
                    </Grid>
                </div>
            </>
        );
    }

    if (LoadedWrapper !== undefined) {
        return <LoadedWrapper>{wrapped}</LoadedWrapper>;
    }

    return wrapped;
};

export default withChildren(RenderEntity);
