import IconButton from '@material-ui/core/IconButton';
import { TrackItFC } from 'helpers/helperTypes';
import { FormatHorizontalAlignLeft, FormatHorizontalAlignRight } from 'mdi-material-ui';
import React from 'react';

const menuIconStyle = {
    color: 'white',
    fontSize: 18,
};

interface ICompactButtonProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const CompactButton: TrackItFC<ICompactButtonProps> = ({ open, setOpen }) => (
    <div
        style={{
            backgroundColor: 'rgba(40, 40, 40, 0.06)',
        }}
    >
        <IconButton onClick={() => setOpen(!open)}>
            {open ? (
                <FormatHorizontalAlignLeft style={menuIconStyle} />
            ) : (
                <FormatHorizontalAlignRight style={menuIconStyle} />
            )}
        </IconButton>
    </div>
);

export default CompactButton;
