import { Box, Grid, Typography } from '@material-ui/core';
import colors from 'components/color';
import statusMapping from 'components/generic/status';
import { format as formatDate } from 'date-fns';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import ISLinkStatus from './ISLinkStatus';

const categories = [
    { label: 'Integration 3D Models', type: '3DSCENE' },
    { label: 'Differential Drawings', type: 'DIFFERENTIALDRAWINGS' },
    { label: '3D Models', type: '3DMODEL' },
    { label: 'Conceptual Layouts', type: 'CONCEPTUALLAYOUT' },
    { label: 'Consolidated Layouts', type: 'CONSOLIDATIONLAYOUT' },
    { label: 'Approval Documents', type: 'APPROVAL' },
];

const generateTooltip = (link: IAny) => {
    const tooltipItems = [
        {
            label: 'Created By',
            value: link?.createdByUser?.userDesc || link?.createdBy,
        },
        {
            label: 'Creation Date',
            value: link?.dateCreated ? formatDate(link?.dateCreated, 'yyyy-MM-dd') : null,
        },
        {
            label: 'Comment',
            value: link?.comment,
        },
    ];
    return tooltipItems
        .filter((e) => e.value)
        .map((e) => `${e.label}: ${e.value}`)
        .join('\n');
};

const ISLinkSynopsis: TrackItFC<{ links: { [key: string]: IAny[] } }> = ({ links }) => (
    <Grid
        style={{
            display: 'flex',
            flexWrap: 'wrap',
            border: '1px solid #eceff1',
            padding: '0.5rem',
        }}
        container
        spacing={1}
    >
        {categories.map((el) => (
            <Grid item key={el.type} xs={6} md={4} style={{ display: 'flex', flexDirection: 'column' }}>
                <Typography style={{ fontSize: 12 }} variant="button">
                    {el.label}
                </Typography>
                <Box style={{ marginBottom: 3, display: 'flex', flexDirection: 'column' }}>
                    {links?.[el.type] ? (
                        links?.[el.type]?.map((link: IAny) =>
                            link.sysRecordID === 'pas besoin' ? (
                                <Box key={link.sysRecordID} style={{ display: 'flex', alignItems: 'center' }}>
                                    <Typography style={{ fontSize: 13, marginRight: 2 }}>pas besoin</Typography>
                                </Box>
                            ) : (
                                <Box
                                    key={link.sysRecordID}
                                    style={{
                                        display: 'flex',
                                        alignItems:
                                            link.statusDisplayValue && link.statusDisplayValue !== ''
                                                ? 'baseline'
                                                : 'center',
                                        paddingBottom: 4,
                                    }}
                                >
                                    <ISLinkStatus
                                        tooltip={generateTooltip(link)}
                                        url={link.url}
                                        primaryText={`${link.sysRecordID}${
                                            link.recVersion ? ` v.${link.recVersion}` : ''
                                        }`}
                                        statusText={link.statusDisplayValue}
                                        color={statusMapping[link.statusDisplayValue]?.color || colors.offwhite}
                                    />
                                </Box>
                            )
                        )
                    ) : (
                        <Typography color="textSecondary">None</Typography>
                    )}
                </Box>
            </Grid>
        ))}
    </Grid>
);

export default ISLinkSynopsis;
