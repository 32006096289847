import logAPI from 'api/logs';
import EAMGridMain from 'eam-components/dist/ui/components/grids/eam/EAMGridMain';
import { EAMFilterField } from 'eam-components/dist/ui/components/grids/eam/utils';
import { TrackItFC } from 'helpers/helperTypes';
import React, { useEffect, useState } from 'react';
import BlockUi from 'react-block-ui';
import { useFilters, useFlexLayout, useSortBy, useTable } from 'react-table';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';

interface ILogGridProps {
    code: string | null;
}

const sortIDMappings: { [key: string]: string } = {
    timestamp: 'date',
    type: 'type',
    user: 'user',
};

const LogGrid: TrackItFC<ILogGridProps> = ({ code }) => {
    const [logResults, setLogResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const { dropdownValues } = useDropdownValues();

    const data = React.useMemo(() => logResults, [logResults]);
    const userFilterOptions = React.useMemo(
        () => [...new Set(data.map((d: any) => d.user))].map((v) => ({ code: v, desc: v })),
        [data]
    );
    const operationsTypesUsed = React.useMemo(() => [...new Set(data.map((d: any) => d.type))], [data]);
    const columns = React.useMemo(
        () => [
            {
                Header: 'Operation',
                accessor: 'type',
                id: 'type',
                width: 100,
                minWidth: 100,
                maxWidth: 99999,
                Filter: EAMFilterField,
                dataType: '__SELECT',
                selectOptions: dropdownValues?.logTypes.filter((e) => operationsTypesUsed.includes(e.code)),
                getOptionLabel: (option: any) => option.desc,
                getOptionValue: (option: any) => option.code,
                Cell: ({ value }: { value: string }) =>
                    dropdownValues?.logTypes.find((e) => e.code === value)?.desc || '',
            },
            {
                Header: 'User',
                accessor: 'user',
                id: 'user',
                width: 100,
                minWidth: 100,
                maxWidth: 99999,
                Filter: EAMFilterField,
                dataType: '__SELECT',
                selectOptions: userFilterOptions,
                getOptionLabel: (option: any) => option.desc,
                getOptionValue: (option: any) => option.code,
            },
            {
                Header: 'Date',
                accessor: 'timestamp',
                id: 'timestamp',
                width: 200,
                minWidth: 200,
                maxWidth: 99999,
                Cell: ({ value }: { value: string }) => {
                    if (!value) return '';
                    const dv = new Date(value);
                    const date = dv.toISOString().split('T')[0];
                    const time = dv.toTimeString().split(' ')[0];
                    return `${date} ${time}`;
                },
                Filter: () => null,
            },
            {
                Header: 'Details',
                accessor: 'operation',
                id: 'operation',
                width: 300,
                minWidth: 200,
                maxWidth: 99999,
                Filter: () => null,
                disableSortBy: true,
            },
        ],
        [userFilterOptions, operationsTypesUsed]
    );

    const tableInstance = useTable(
        {
            columns,
            data,
            initialState: {
                // @ts-ignore
                sortBy: [{ id: 'timestamp', desc: false }],
            },
            // @ts-ignore
            manualFilters: true,
            autoResetFilters: false,
            manualSortBy: true,
            autoResetSortBy: false,
        },
        useFilters,
        useSortBy,
        useFlexLayout
    );

    const { state } = tableInstance;
    // @ts-ignore
    const { filters, sortBy } = state;

    const fetchData = async () => {
        setLoading(true);
        try {
            const result = await logAPI.getLogs({
                code,
                sortColumn: sortIDMappings[sortBy[0]?.id],
                ...(sortBy[0] && { sortOrder: sortBy[0]?.desc ? 'asc' : 'desc' }),
                filter: filters
                    .map((e: any) => e.value)
                    .reduce((acc: any, e: any) => ({ ...acc, [e.fieldName]: e.fieldValue }), {}),
            });
            setLogResults(result.body.data);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [JSON.stringify(filters), JSON.stringify(sortBy)]);

    return (
        <BlockUi
            blocking={loading}
            style={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'white',
            }}
        >
            <EAMGridMain
                loading={loading}
                tableInstance={tableInstance}
                getCellProps={() => ({
                    style: {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    },
                })}
                getRowProps={() => ({
                    style: {
                        borderBottom: '1px solid #d2d2d2',
                        width: '100%',
                    },
                    hover: true,
                })}
            />
        </BlockUi>
    );
};

export default LogGrid;
