import trackAPI from 'api/track';
import { useState } from 'react';

const useTrackHierarchy = () => {
    const [loading, setLoading] = useState(false);
    const [studies, setStudies] = useState<{ [key: string]: any[] }>({});

    const loadStudiesForTracks = async ({ trackCodes }: { trackCodes: string[] }) => {
        if (!trackCodes.length) return;
        setLoading(true);
        setStudies({});
        const result = await trackAPI.getStudiesFromManyTracks({ trackCodes });
        const trackHierarchyData = result.body.data;
        setStudies(trackHierarchyData);
        setLoading(false);
    };

    return {
        loadStudiesForTracks,
        isLoadingStudiesForTracks: loading,
        studiesForTracks: studies,
    };
};

export default useTrackHierarchy;
