import client from 'api/client';

const RESOURCE_PATH = `${process.env.REACT_APP_BACKEND}/links`;

export const createLink = async (data, caseCode) => await client.post(`${RESOURCE_PATH}/${caseCode}`, data);
export const editLink = async (data) => await client.put(`${RESOURCE_PATH}`, data);
export const deleteLink = async (code) => await client.delete(`${RESOURCE_PATH}/${code}`);

const all = {
    createLink,
    editLink,
    deleteLink,
};

export default all;
