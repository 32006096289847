import DialogContent from '@material-ui/core/DialogContent';
import { Input, Select } from 'components/generic/dialog';
import React from 'react';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';

const overflowStyle: any = {
    overflowY: 'visible',
};

const LinkDialog = (props: any) => {
    const { state, updateState } = props;
    const { dropdownValues } = useDropdownValues();

    const defaultAttributes: any = {
        recordId: 'Record ID',
        recordIdAttribute: 'R',
        url: 'URL',
        urlAttribute: 'H',
        recordVersion: 'Version',
        recordVersionAttribute: 'H',
        description: 'Description',
        descriptionAttribute: 'O',
        comment: 'Comment',
        commentAttribute: 'O',
        pres1: '1st Presentation',
        pres1Attribute: 'H',
        pres2: '2nd Presentation',
        pres2Attribute: 'H',
    };

    const attributes: any = {
        default: {
            recordIdAttribute: 'O',
            urlAttribute: 'R',
        },
        EDMS: {
            recordId: 'Document ID',
            recordVersionAttribute: 'O',
        },
        EDMSECR: {
            pres1Attribute: 'O',
            pres2Attribute: 'O',
            recordId: 'Document ID',
            recordVersionAttribute: 'O',
        },
        EDMSSRR: {
            pres1Attribute: 'O',
            pres2Attribute: 'O',
            recordId: 'Document ID',
            recordVersionAttribute: 'O',
        },
        APPRODOC: {
            recordId: 'Document ID',
            recordVersionAttribute: 'O',
        },
        CONCEPTUALLAYOUT: {
            recordId: 'Document ID',
            recordVersionAttribute: 'R',
            descriptionAttribute: 'H',
        },
        CONSOLIDATIONLAYOUT: {
            recordId: 'Document ID',
            recordVersionAttribute: 'R',
            descriptionAttribute: 'H',
        },
        DIFFERENTIALDRAWINGS: {
            recordId: 'Document ID',
            recordVersionAttribute: 'R',
            descriptionAttribute: 'H',
        },
        '3DMODEL': {
            recordId: '3D Document Number',
            recordVersionAttribute: 'R',
            recordVersion: 'Revision',
            descriptionAttribute: 'H',
        },
        '3DSCENE': {
            recordId: '3D Document Number',
            recordVersionAttribute: 'R',
            recordVersion: 'Revision',
            descriptionAttribute: 'H',
        },
        INDICO: {
            recordId: 'Event ID',
        },
        PLAN: {
            recordId: 'Activity ID',
            descriptionAttribute: 'H',
        },
        WEBPAGE: {
            recordIdAttribute: 'H',
            urlAttribute: 'R',
        },
        TRACK: {
            descriptionAttribute: 'H',
        },
        PANORAMA: {
            recordIdAttribute: 'H',
            urlAttribute: 'R',
        },
    };

    const {
        recordId,
        recordIdAttribute,
        recordVersion,
        recordVersionAttribute,
        url,
        urlAttribute,
        description,
        descriptionAttribute,
        comment,
        commentAttribute,
        pres1,
        pres1Attribute,
        pres2,
        pres2Attribute,
    }: any = {
        ...defaultAttributes,
        ...(attributes[state.system] ?? attributes.default),
    };

    const systems = (
        state.entity === 'integrationStudy'
            ? dropdownValues?.systems
            : dropdownValues?.systems.filter((el) => !el.onlyIS)
    )?.map(({ description: d, ...r }) => ({ ...r, desc: d }));

    return (
        <DialogContent style={overflowStyle}>
            <Select label="System" required values={systems} state={state} valueKey="system" update={updateState} />

            <Input
                label={recordId}
                required={recordIdAttribute === 'R'}
                optional={recordIdAttribute === 'O'}
                hidden={recordIdAttribute === 'H'}
                state={state}
                valueKey="targetId"
                update={updateState}
            />

            <Input
                label={recordVersion}
                required={recordVersionAttribute === 'R'}
                optional={recordVersionAttribute === 'O'}
                hidden={recordVersionAttribute === 'H'}
                state={state}
                valueKey="version"
                update={updateState}
            />

            <Input
                label={url}
                required={urlAttribute === 'R'}
                optional={urlAttribute === 'O'}
                hidden={urlAttribute === 'H'}
                state={state}
                valueKey="url"
                update={updateState}
            />

            <Input
                label={description}
                required={descriptionAttribute === 'R'}
                optional={descriptionAttribute === 'O'}
                hidden={descriptionAttribute === 'H'}
                state={state}
                valueKey="description"
                update={updateState}
                maxLength={256}
            />

            <Input
                label={comment}
                required={commentAttribute === 'R'}
                optional={commentAttribute === 'O'}
                hidden={commentAttribute === 'H'}
                state={state}
                valueKey="comment"
                update={updateState}
            />

            <Input
                label={pres1}
                required={pres1Attribute === 'R'}
                optional={pres1Attribute === 'O'}
                hidden={pres1Attribute === 'H'}
                state={state}
                valueKey="pres1"
                update={updateState}
            />

            <Input
                label={pres2}
                required={pres2Attribute === 'R'}
                optional={pres2Attribute === 'O'}
                hidden={pres2Attribute === 'H'}
                state={state}
                valueKey="pres2"
                update={updateState}
            />
        </DialogContent>
    );
};

export default LinkDialog;
