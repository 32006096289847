import { IAny, TrackItFC } from 'helpers/helperTypes';
import React, { useContext } from 'react';

const ValidationContext = React.createContext<any>(undefined);

interface IValidationWrapperProps {}

class ValidationWrapper extends React.Component<IValidationWrapperProps> {
    children: {};

    constructor(props: IValidationWrapperProps) {
        super(props);

        this.children = {};
    }

    render() {
        const { children } = this.props;

        this.children = {};

        return <ValidationContext.Provider value={this.children}>{children}</ValidationContext.Provider>;
    }
}

export const withChildren = (Component: TrackItFC<any>) => (props: IAny) =>
    (
        <ValidationWrapper>
            <Component {...props} />
        </ValidationWrapper>
    );

export const useChildren = () => useContext(ValidationContext);
