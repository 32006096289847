import Grid from '@material-ui/core/Grid';
import React from 'react';

export const InputContext = React.createContext({});

const InputProvider = (props) => {
    const { children, grid = false, ...rest } = props;

    const context = {
        grid,
        ...rest,
    };

    const childrenRender = grid ? (
        <Grid container spacing={1}>
            {children}
        </Grid>
    ) : (
        children
    );

    return <InputContext.Provider value={context}>{childrenRender}</InputContext.Provider>;
};

export default InputProvider;
