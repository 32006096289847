import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { CloseButton } from 'components/generic/dialog';
import { useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TTextCloseDialog } from './dialogStates';

const TextCloseDialog = () => {
    const [
        {
            state: { text, title, buttonLabel },
        },
    ] = useDialogState<TTextCloseDialog>();

    return (
        <>
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <CloseButton label={buttonLabel} />
            </DialogActions>
        </>
    );
};

export default TextCloseDialog;
