import { Button, Typography } from '@material-ui/core';
import taskApi from 'api/task';
import CustomEAMFilter from 'components/grids/CustomEAMFilter';
import EntityStatus from 'components/renderers/EntityStatus';
import EAMGrid from 'eam-components/dist/ui/components/grids/eam/EAMGrid';
import { EAMGridContext, EAMGridContextProvider } from 'eam-components/dist/ui/components/grids/eam/EAMGridContext';
import { EAMCellField } from 'eam-components/dist/ui/components/grids/eam/utils';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import useGridSyncedParams from 'hooks/useGridSyncedParams';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';
import { useUserData } from 'store/contexts/UserDataContext';
import { toast } from 'react-toastify';

interface ITasksPageProps {}
interface IExcelExportButtonProps {}

export const COLUMNS = {
    TASK_CODE: 'csm_code',
    STATUS: 'csm_udfchar04',
    ASSIGNEE: 'csm_responsible_name',
    PHASE: 'csm_udfchar03',
    DESCRIPTION: 'csm_desc',
    PARENT_TRACK: 'csm_parent_track',
    PARENT_IS: 'csm_parent_is',
    SCOPE: 'csm_udfchar12',
    PERIOD: 'csm_udfchar06',
    FACILITY: 'csm_udfchar01',
    CREATED_BY: 'csm_createdby_name',
    UPDATED_BY: 'csm_updatedby_name',
    LINKS: 'csm_links',
    COMMENTS: 'csm_comments',
};

const columnsOrder = [
    COLUMNS.TASK_CODE,
    COLUMNS.STATUS,
    COLUMNS.ASSIGNEE,
    COLUMNS.PHASE,
    COLUMNS.DESCRIPTION,
    COLUMNS.PARENT_TRACK,
    COLUMNS.PARENT_IS,
    COLUMNS.SCOPE,
    COLUMNS.PERIOD,
    COLUMNS.FACILITY,
    COLUMNS.CREATED_BY,
    COLUMNS.UPDATED_BY,
    COLUMNS.LINKS,
    COLUMNS.COMMENTS,
];

const TasksPage: TrackItFC<ITasksPageProps> = () => {
    const { applicationData } = useApplicationData();
    const { userData } = useUserData();
    const gridName = applicationData?.gridTaskListGridName;
    const { dropdownValues } = useDropdownValues();
    const location = useLocation();
    const myTasks = location.pathname === ROUTES.mytasks.search;
    // <Row_ID, New_Status> mapping (only containing changed rows)
    const [newStatusMap, setNewStatusMap] = useState();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        document.title = myTasks ? 'My Tasks' : 'Task List';
    }, [location.pathname]);

    let customInitialFilters: IAny[] = [];
    if (myTasks) {
        customInitialFilters = [
            {
                fieldName: 'csm_responsible_name',
                fieldValue: userData?.eamAccount?.employeeDesc,
                operator: 'CONTAINS',
            },
        ];
    }

    const taskStatusChangeHandler = async (option: any, row: any) => {
        setLoading(true);

        const rowId = row.id;
        const taskCode = row.original.csm_code;
        const newStatus = option.status;

        try {
            const task = await taskApi.getTask({ code: taskCode });
            const taskData = task.body.data;

            const updatedTaskData = { ...taskData, status: newStatus };
            await taskApi.saveTask(updatedTaskData);

            setNewStatusMap((prevState: any) => ({
                ...prevState,
                [rowId]: newStatus,
            }));
        } catch (error) {
            toast.error('Error updating task status', {
                position: toast.POSITION.BOTTOM_RIGHT,
            });

            const originalStatus = row.original.csm_udfchar04;
            const previousStatus = newStatusMap?.[rowId] || originalStatus;

            setNewStatusMap((prevState: any) => ({
                ...prevState,
                [rowId]: previousStatus,
            }));
        } finally {
            setLoading(false);
        }
    };

    const columnOverrides = {
        [COLUMNS.TASK_CODE]: {
            Header: 'Task ID',
            Cell: ({ row, value }: { row: any; value: string }) => {
                const rowData = row.original;
                return (
                    <>
                        <Typography style={{ fontWeight: 'bold' }} component="p">
                            {value}
                        </Typography>
                        <Typography>{rowData.csm_desc}</Typography>
                    </>
                );
            },
        },
        [COLUMNS.STATUS]: {
            Header: 'Status',
            Cell: ({ row, value }: { row: any; value: string }) => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    <EntityStatus
                        entity="task"
                        status={newStatusMap?.[row.id] ?? value}
                        onChange={(option) => taskStatusChangeHandler(option, row)}
                        onClose={(e: any) => {
                            // Prevents click event from being passed to row
                            // (which would open the track details page)
                            e.stopPropagation();
                        }}
                    />
                </div>
            ),
            dataType: '__SELECT',
            selectOptions: dropdownValues?.taskStatuses,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.ASSIGNEE]: {
            Header: 'Assignee',
        },
        [COLUMNS.PHASE]: {
            Header: 'Phase',
            Cell: ({ value }: { value: string }) =>
                dropdownValues?.taskPhases.find((e) => e.code === value)?.desc || '',
            dataType: '__SELECT',
            selectOptions: dropdownValues?.taskPhases,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.DESCRIPTION]: {
            Header: 'Description',
        },
        [COLUMNS.PARENT_TRACK]: {
            Header: 'In Track',
            Cell: ({ row, value }: { row: any; value: string }) => {
                const rowData = row.original;
                return (
                    <>
                        <Typography style={{ fontWeight: 'bold' }} component="p">
                            {value}
                        </Typography>
                        <Typography>{rowData.csm_parent_track_desc}</Typography>
                    </>
                );
            },
        },
        [COLUMNS.PARENT_IS]: {
            Header: 'In IS',
            Cell: ({ row, value }: { row: any; value: string }) => {
                const rowData = row.original;
                return (
                    <>
                        <Typography style={{ fontWeight: 'bold' }} component="p">
                            {value}
                        </Typography>
                        <Typography>{rowData.csm_parent_is_desc}</Typography>
                    </>
                );
            },
        },
        [COLUMNS.SCOPE]: {
            Header: 'Scope',
            Cell: ({ value }: { value: string }) => dropdownValues?.scopes.find((e) => e.code === value)?.desc || '',
            dataType: '__SELECT',
            selectOptions: dropdownValues?.scopes,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.PERIOD]: {
            Header: 'Period',
            Cell: ({ value }: { value: string }) => dropdownValues?.periods.find((e) => e.code === value)?.desc || '',
            dataType: '__SELECT',
            selectOptions: dropdownValues?.periods,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.FACILITY]: {
            Header: 'Facility',
            Cell: ({ value }: { value: string }) =>
                dropdownValues?.facilities.find((e) => e.code === value)?.desc || '',
            dataType: '__SELECT',
            selectOptions: dropdownValues?.facilities,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.CREATED_BY]: {
            Header: 'Created By',
        },
        [COLUMNS.UPDATED_BY]: {
            Header: 'Updated By',
        },
        [COLUMNS.LINKS]: {
            Header: 'Links',
        },
        [COLUMNS.COMMENTS]: {
            Header: 'Comments',
        },
    };

    const ExcelExportButton: TrackItFC<IExcelExportButtonProps> = () => {
        const { data } = useContext<any>(EAMGridContext);

        const handleExport: React.MouseEventHandler = () => {
            const filteredData = data.map((d: { [key: string]: string }) =>
                Object.fromEntries(
                    Object.entries(d)
                        .filter(([key]) => Object.keys(columnOverrides).includes(key))
                        .concat([
                            ['Track Description', d.csm_parent_track_desc],
                            ['IS Description', d.csm_parent_is_desc],
                        ])
                        .map(([key, val]) => {
                            switch (key) {
                                case 'csm_udfchar04':
                                    return [key, dropdownValues?.taskStatuses.find((e) => e.code === val)?.desc || val];
                                case 'csm_udfchar12':
                                    return [key, dropdownValues?.scopes.find((e) => e.code === val)?.desc || val];
                                case 'csm_udfchar01':
                                    return [key, dropdownValues?.facilities.find((e) => e.code === val)?.desc || val];
                                case 'csm_udfchar06':
                                    return [key, dropdownValues?.periods.find((e) => e.code === val)?.desc || val];
                                case 'csm_udfchar03':
                                    return [key, dropdownValues?.taskPhases.find((e) => e.code === val)?.desc || val];
                                default:
                                    return [key, val];
                            }
                        })
                        .map(([key, val]) => (val?.includes(',') ? [key, `"${val}"`] : [key, val]))
                )
            );

            const aoa = [
                Object.keys(filteredData[0]).map(
                    (k) => columnOverrides[k as keyof typeof columnOverrides]?.Header ?? k
                ),
                ...filteredData.map(Object.values),
            ];

            const csvContent = `data:text/csv;charset=utf-8,${aoa.map((e) => e.join(',')).join('\n')}`;
            const downloadLink = document.createElement('a');
            downloadLink.href = encodeURI(csvContent).replaceAll('#', '%23');
            downloadLink.download = 'tasks.csv';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        return (
            <Button onClick={handleExport} variant="outlined" size="small">
                Export to Excel
            </Button>
        );
    };

    const defaultCreateColumns = ({ gridField, cellRenderer }: { gridField: []; cellRenderer: () => JSX.Element }) =>
        columnsOrder.reduce<any[]>((acc, columnID) => {
            if (!gridField) return acc;
            const field: any = gridField?.find((e: { name: string }) => e.name === columnID);
            if (!field && !columnID.startsWith('__')) return acc;
            return [
                ...acc,
                {
                    id: columnID,
                    accessor: columnID,
                    width: Number(field?.width || 0),
                    minWidth: 0,
                    maxWidth: 99999,
                    dataType: field?.dataType,
                    Filter: CustomEAMFilter,
                    Cell: cellRenderer || EAMCellField,
                    ...columnOverrides[columnID as Partial<keyof typeof columnOverrides>],
                },
            ];
        }, []);

    const gridSyncedParamsProps = useGridSyncedParams();
    const history = useHistory();

    return (
        <>
            <EAMGridContextProvider
                gridName={gridName}
                {...gridSyncedParamsProps}
                initialFilters={[...gridSyncedParamsProps.initialFilters, ...customInitialFilters]}
                handleError={() => null} // TODO
                searchOnMount
                createColumns={defaultCreateColumns}
                filterProcessor={(e: IAny) => ({
                    ...e,
                    operator: columnOverrides[e.fieldName]?.dataType === '__SELECT' ? '=' : e.operator,
                })}
                blockGrid={loading}
            >
                <EAMGrid
                    customFooterOptions={() => <ExcelExportButton />}
                    getCellProps={() => ({
                        style: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        },
                    })}
                    getRowProps={(row: IAny) => ({
                        hover: true,
                        style: {
                            width: 'unset',
                            textDecoration: 'none',
                            cursor: 'pointer',
                        },
                        onClick: (e: IAny) => {
                            const path = getRoute({
                                path: ROUTES.tasks.view,
                                params: { code: row.values.csm_code },
                            });
                            if (e.ctrlKey) {
                                window.open(
                                    path.startsWith('/') ? `${process.env.PUBLIC_URL}${path} ` : path,
                                    '_blank'
                                );
                            } else {
                                history.push(path);
                            }
                        },
                    })}
                />
            </EAMGridContextProvider>
        </>
    );
};

export default TasksPage;
