import { ButtonBase, SvgIconProps, Typography } from '@material-ui/core';
import { Save } from '@material-ui/icons';
import DialogTypes from 'components/dialogs/types';
import { getKeysOf, IAny, TrackItFC } from 'helpers/helperTypes';
import { useEntity, useMetadata } from 'middleware/data';
import { useDialogDispatch } from 'middleware/dialogContext';
import { useChildren } from 'middleware/validation';
import React, { useContext, useEffect, useState } from 'react';

interface ISaveContext {
    title: string;
    saver: ({ data }: { data: any }) => Promise<void>;
    afterSave: (data: IAny) => void;
    error: (e?: any) => void;
    dirty: string[];
    addDirty: (key: string) => void;
    clean: () => void;
}

export const SaveContext = React.createContext<ISaveContext>({
    title: '',
    saver: async () => {},
    afterSave: () => {},
    error: () => {},
    dirty: [],
    addDirty: () => {},
    clean: () => {},
});

const useSave = () => {
    const children = useChildren();
    const { clean, saver, afterSave, error } = useContext(SaveContext);
    const [entity, updateEntity] = useEntity();
    const dispatchDialog = useDialogDispatch();

    // When you have an input in focus, and hit save,
    //  both handlers are called immediately, and setState is asynchronous.
    // To ensure the state has been updated, we use this counter
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        if (counter === 0) {
            return;
        }

        let validationPassed = true;

        getKeysOf(children)?.forEach((key) => {
            if (children[key] && children[key].validate && !children[key].validate()) {
                validationPassed = false;
            }
        });

        if (validationPassed) {
            dispatchDialog({
                type: DialogTypes.LOADING,
                state: {
                    promise: () => saver({ data: entity.data }),
                    callback: (response: any) => {
                        dispatchDialog(null);
                        afterSave && afterSave({ entity, updateEntity, response });
                        clean();
                    },
                    error,
                },
            });
        }
    }, [counter]);

    return async () => {
        setCounter((oldCounter: number) => oldCounter + 1);
    };
};

interface ISaveButtonProps {
    IconComponent?: typeof Save;
    label?: string;
    iconOnly?: boolean;
    color?: SvgIconProps['color'];
    preSave?: () => void;
    postSave?: () => void;
}

export const SaveButton: TrackItFC<ISaveButtonProps> = ({
    IconComponent = Save,
    label = 'SAVE',
    iconOnly,
    color,
    preSave,
    postSave,
}) => {
    const save = useSave();

    const handleClick = () => {
        window.onbeforeunload = null;
        preSave?.();
        save();
        postSave?.();
    };

    return iconOnly ? (
        <ButtonBase onClick={handleClick}>
            <IconComponent {...{ color }} />
        </ButtonBase>
    ) : (
        <ButtonBase
            style={{
                backgroundColor: '#eceff1',
                borderRadius: 2,
                padding: '10px 10px 10px 5px',
                justifyContent: 'space-between',
            }}
            onMouseUp={handleClick}
        >
            <IconComponent {...{ color }} />
            <Typography style={{ marginLeft: 5 }}>{label}</Typography>
        </ButtonBase>
    );
};

export const SaveProvider = (props: any) => {
    const { title, saver, afterSave, error, children } = props;

    const [dirtyState, setDirty] = useState<string[]>([]);
    const [{ dirty: dirtyMetadata = [] } = {}, updateMetadata] = useMetadata();

    const addDirty = (key: string) => {
        const scopedKey = `${title}$$$${key}`;

        if (dirtyState.indexOf(scopedKey) === -1) {
            setDirty([...dirtyState, scopedKey]);
        }

        if (dirtyMetadata.indexOf(scopedKey) === -1) {
            updateMetadata({ dirty: [...dirtyMetadata, scopedKey] });
        }
    };

    const clean = () => {
        const remaining = dirtyMetadata.filter((key: string) => !dirtyState.includes(key));
        setDirty([]);
        updateMetadata({ dirty: remaining });
    };

    return (
        <SaveContext.Provider value={{ title, saver, afterSave, error, dirty: dirtyState, addDirty, clean }}>
            {children}
        </SaveContext.Provider>
    );
};
