import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import taskApi from 'api/task';
import { CloseButton, ValidateButton } from 'components/generic/dialog';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React, { useEffect, useState } from 'react';
import { TAddTaskDialog } from '../dialogStates';
import DialogTypes from '../types';
import TaskDialog from './TaskDialog';

const AddTaskDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TAddTaskDialog>();
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        if (!loaded) {
            taskApi.getTaskDefault().then((response) => {
                updateState(response.body.data);
                setLoaded(true);
            });
        }
    }, []);

    if (!loaded) {
        return <div />;
    }
    const { parent, ...apiData } = state;

    return (
        <>
            <DialogTitle id="alert-dialog-title">Add Task</DialogTitle>
            <TaskDialog state={state} updateState={updateState} />
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => taskApi.createTask(apiData, parent),
                                callback: () => {
                                    state?.callback && state.callback();
                                    dispatchDialog(null);
                                },
                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: { title: 'Add Task', text: 'There was an error adding the task.' },
                                    }),
                            },
                        })
                    }
                    label="Create"
                />
            </DialogActions>
        </>
    );
};

export default AddTaskDialog;
