import client from 'api/client';

const RESOURCE_PATH = `${process.env.REACT_APP_BACKEND}/misc`;

export const getDocumentData = async (id, version) =>
    await client.get(
        `${RESOURCE_PATH}/documentdata?id=${id?.trim().toUpperCase()}&version=${version?.trim().toUpperCase()}`
    );

const all = {
    getDocumentData,
};

export default all;
