import LogGrid from 'components/entities/LogGrid';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import { useLocation } from 'react-router-dom';

interface IViewLogPageProps {}

const ViewLogPage: TrackItFC<IViewLogPageProps> = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    return <LogGrid code={code} />;
};

export default ViewLogPage;
