const colors = {
    green: '#00cc00',
    cyan: '#00aac8',
    orange: '#ff9900',
    red: '#ff0000',
    grey: '#808080',
    offwhite: '#c0c0c0',
    purple: '#992ec8',
};

export default colors;
