import { Box, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import * as needAPI from 'api/need';
import DialogTypes from 'components/dialogs/types';
import ActionDots from 'components/generic/ActionDots';
import NeedsCheckbox from 'components/renderers/NeedsCheckbox';
import { getKeysOf, IAny, TrackItFC } from 'helpers/helperTypes';
import { useData, useMetadata, useReload } from 'middleware/data';
import { useDialogDispatch } from 'middleware/dialogContext';
import withRegion from 'middleware/withRegion';
import React, { useEffect } from 'react';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';
import { useUserData } from 'store/contexts/UserDataContext';
import { RegionComponent } from '../types';

interface ITaskItemProps {
    need: IAny;
    readonly?: boolean;
    onUpdateNeed: (args: any) => void;
}

const NeedItem: TrackItFC<ITaskItemProps> = ({ need, onUpdateNeed, readonly }) => {
    const dispatchDialog = useDialogDispatch();
    const reloadNeeds = useReload('needs');
    const [{ entity }] = useMetadata();
    const { dropdownValues } = useDropdownValues();

    const typeTitleValues =
        entity === 'track' ? dropdownValues?.needTypesTrack : dropdownValues?.needTypesIntegrationStudy;

    const typeTitle = typeTitleValues?.find((el) => el.code === need.type)?.desc;

    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                position: 'relative',
                padding: '3px 0',
            }}
        >
            <NeedsCheckbox
                title={need.title ?? typeTitle}
                text={need.description}
                initialChecked={need.isFulfilled === '+'}
                required={need.isRequired === '+'}
                onChange={() => {
                    const updatedNeed = { ...need, isFulfilled: need.isFulfilled === '+' ? '-' : '+' };
                    needAPI.updateNeed(updatedNeed);
                    onUpdateNeed(updatedNeed);
                }} // TODO, improve this, if the request takes too long or fails
                readonly={readonly}
            />
            {!readonly && (
                <ActionDots
                    isStatic
                    actions={[
                        {
                            name: 'Edit',
                            onClick: () =>
                                dispatchDialog({
                                    type: DialogTypes.EDIT_NEED,
                                    state: {
                                        entity,
                                        ...need,
                                    },
                                }),
                        },
                        {
                            name: 'Delete',
                            onClick: () =>
                                dispatchDialog({
                                    type: DialogTypes.TEXT_YES_NO,
                                    state: {
                                        title: 'Confirm deletion',
                                        text: `Are you sure you want to delete Need "${need.title}"?`,
                                        callback: async () => {
                                            dispatchDialog({
                                                type: DialogTypes.LOADING,
                                                state: {
                                                    promise: async () => await needAPI.deleteNeed(need.trackNeedPK),
                                                    callback: () => {
                                                        reloadNeeds();
                                                        dispatchDialog(null);
                                                    },
                                                    error: () =>
                                                        dispatchDialog({
                                                            type: DialogTypes.TEXT_CLOSE,
                                                            state: {
                                                                title: 'Error',
                                                                text: 'The need could not be deleted.',
                                                            },
                                                        }),
                                                },
                                            });
                                        },
                                    },
                                }),
                        },
                    ]}
                />
            )}
        </Box>
    );
};

const NeedsRegion: RegionComponent = ({ setHeaderTitle, setSubtitle, setButtonRight }) => {
    const [needs, updateNeeds] = useData('needs');
    const [{ entity }] = useMetadata();
    const [{ code }] = useData('general');
    const reloadNeeds = useReload('needs');
    const { readonly } = useUserData();

    const dispatchDialog = useDialogDispatch();

    useEffect(() => {
        if (!readonly) {
            setButtonRight({
                icon: ({ size, color }) => <Add style={{ fontSize: size, color }} />,
                callback: () =>
                    dispatchDialog({
                        type: DialogTypes.ADD_NEED,
                        state: {
                            entity,
                            code,
                            callback: () => {
                                reloadNeeds();
                                dispatchDialog(null);
                            },
                        },
                    }),
            });
        }
        setHeaderTitle('Needs');
    }, [entity, code, reloadNeeds]);

    useEffect(() => {
        needs && setSubtitle(`(${needs.filter((n: any) => n.isFulfilled === '+').length}/${needs.length} Fulfilled)`);
    }, [needs]);

    return (
        <Box>
            {getKeysOf(needs).length > 0 ? (
                Object.values(needs).map((need: any) => (
                    <NeedItem
                        need={need}
                        key={`${need.trackNeedPK}${need.dateCreated}`}
                        onUpdateNeed={(n: any) =>
                            updateNeeds(needs.map((e: any) => (e.trackNeedPK === n.trackNeedPK ? n : e)))
                        }
                        readonly={readonly}
                    />
                ))
            ) : (
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Typography color="textSecondary">No needs.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default withRegion(NeedsRegion, ['needs']);
