import {
    Button,
    ClickAwayListener,
    Grow,
    LinearProgress,
    MenuItem,
    MenuList,
    Paper,
    Popper,
    Typography,
} from '@material-ui/core';
import { AccountTree, CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import DialogTypes from 'components/dialogs/types';
import statusMapping from 'components/generic/status';
import CustomEAMFilter from 'components/grids/CustomEAMFilter';
import EntityStatus from 'components/renderers/EntityStatus';
import ISLinkStatus from 'components/renderers/ISLinkStatus';
import StatusIcon from 'components/renderers/StatusIcon';
import TrackHierarchy from 'components/renderers/TrackHierarchy';
import EAMGrid from 'eam-components/dist/ui/components/grids/eam/EAMGrid';
import { EAMGridContext, EAMGridContextProvider } from 'eam-components/dist/ui/components/grids/eam/EAMGridContext';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import useDocumentsInfoForTracks from 'hooks/useDocumentsInfoForTracks';
import useGridSyncedParams from 'hooks/useGridSyncedParams';
import useHierarchyForTracks from 'hooks/useHierarchyForTracks';
import useStudiesForTracks from 'hooks/useStudiesForTracks';
import { useDialogDispatch } from 'middleware/dialogContext';
import withEntity from 'middleware/withEntity';
import React, { MouseEvent, useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';
import { useUserData } from 'store/contexts/UserDataContext';
import trackApi from 'api/track';
import { toast } from 'react-toastify';
import DiscreteSelect from 'components/generic/DiscreteSelect';
import Input from 'components/generic/Input';
import InputProvider from 'components/generic/InputProvider';
import autocompleteApi from 'api/autocomplete';

const TRACK_PROPERTIES = {
    STATUS: 'status',
    SCOPE: 'scope',
    FACILITY: 'facility',
    PERIOD: 'period',
    LOCATION: 'location',
    DESCRIPTION: 'description',
    GROUP: 'group',
    MATURITY: 'maturityLevel',
    RESPONSIBLE_CODE: 'responsibleCode',
    RESPONSIBLE_DESC: 'responsibleDesc',
    INSTALLATION: 'installation',
};

export const COLUMNS = {
    TRACK_CODE: 'csm_code',
    STATUS: 'csm_udfchar09',
    SCOPE: 'csm_udfchar12',
    FACILITY: 'csm_udfchar01',
    PERIOD: 'csm_udfchar06',
    LOCATION: 'csm_udfchar18',
    DESCRIPTION: 'csm_desc',
    TASKS: '__tasks',
    NEEDS: '__needs',
    LINKS: '__links',
    COMMENTS: '__comments',
    SRR: 'csm_docsrr_details',
    ECR: 'csm_docecr_details',
    INTEG_STUDIES: '__integrationstudies',
    GROUP: 'csm_udfchar13',
    CONTACT: 'csm_responsible_name',
    MATURITY: 'csm_udfchar16',
    CREATION_DATE: 'csm_created',
    INSTALLATION: 'csm_udfchar07',
};

const columnsOrder = [
    COLUMNS.TRACK_CODE,
    COLUMNS.STATUS,
    COLUMNS.SCOPE,
    COLUMNS.FACILITY,
    COLUMNS.PERIOD,
    COLUMNS.LOCATION,
    COLUMNS.DESCRIPTION,
    COLUMNS.INSTALLATION,
    COLUMNS.TASKS,
    COLUMNS.NEEDS,
    COLUMNS.LINKS,
    COLUMNS.COMMENTS,
    COLUMNS.SRR,
    COLUMNS.ECR,
    COLUMNS.INTEG_STUDIES,
    COLUMNS.GROUP,
    COLUMNS.CONTACT,
    COLUMNS.MATURITY,
];

const HEADERS = {
    TRACKS: 'Tracks',
    STATUS: 'Status',
    SCOPE: 'Scope',
    FACILITY: 'Facility',
    PERIOD: 'Period',
    LOCATION: 'Location',
    DESCRIPTION: 'Description',
    TASKS: 'Tasks',
    NEEDS: 'Needs',
    LINKS: 'Links',
    COMMENTS: 'Comments',
    SRR: 'SRR',
    ECR: 'ECR',
    IS: 'Integration Studies',
    GROUP: 'Group',
    CONTACT: 'Contact',
    MATURITY: 'Maturity',
    INSTALLATION: 'Installation',
};

// The grid handles an Enter key press wherever we are on the page, causing
// the grid to refresh. We want to avoid refreshing while editing a text
// field since the user expectation would be that an Enter would submit the
// value. Although the value is not submitted when pressing Enter, we should
// at the very least avoid the grid from refreshing for a decent UX.
const stopEnterKeyPropagation = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    if (event.key === 'Enter') {
        event.stopPropagation();
    }
};

// The grid component refreshes when the user presses the Enter key, so we take
// advantage of that behavior to be able to programmatically refresh the grid.
const triggerEnterKeyToRefreshGrid = () => {
    const enterKeyEvent = new KeyboardEvent('keydown', { key: 'Enter' });
    window.dispatchEvent(enterKeyEvent);
};

interface IExcelExportButtonProps {}

interface ITracksPageProps {}

const TracksPage: TrackItFC<ITracksPageProps> = () => {
    const { applicationData } = useApplicationData();
    const gridName = applicationData?.gridTrackListGridName;
    const { trackHierarchy, loadTrackHierarchy, isLoadingTrackHierarchy } = useHierarchyForTracks();
    const { studiesForTracks, loadStudiesForTracks, isLoadingStudiesForTracks } = useStudiesForTracks();
    const { documentsInfoForTracks, loadDocumentsInfoForTracks, isLoadingDocumentsInfoForTracks } =
        useDocumentsInfoForTracks();
    const { dropdownValues } = useDropdownValues();
    const { readonly } = useUserData();
    const [editedRows, setEditedRows] = useState<any[]>(); // A mapping of: <Row_ID, { trackProp1: val1, trackProp2: val2, .. }>
    const [editedTextCells, setEditedTextCells] = useState<any>({});
    const [editMode, setEditMode] = useState<boolean>(false);
    const [isSavingEdit, setIsSavingEdit] = useState<boolean>(false);
    const somethingEdited = useRef(false);
    const [disableScrollUp, setDisableScrollUp] = useState<boolean>(false);

    useEffect(() => {
        document.title = 'Track List';
    }, []);

    const trackMultiChangeHandler = async (
        row: any,
        newValuesObj: any,
        columnName: string,
        previousValuesObj?: any
    ) => {
        const rowId = row.id;
        const trackCode = row.original.csm_code;

        setIsSavingEdit(true);

        // For DiscreteSelect and EntityStatus components we update the state to show the picked value to the user even before saving starts
        // whereas for Input components their related state is already showing the most up to date value.
        if (previousValuesObj) {
            setEditedRows((prevState: any) => ({
                ...prevState,
                [rowId]: {
                    ...prevState?.[rowId],
                    ...newValuesObj,
                },
            }));
        }

        try {
            const track = await trackApi.getTrack({ code: trackCode });
            const trackData = track.body.data;

            const updatedTrackData = { ...trackData, ...newValuesObj };
            await trackApi.saveTrack(updatedTrackData);

            // Edited rows state is not updated if the API call fails since an error is caught
            if (!previousValuesObj) {
                setEditedRows((prevState: any) => ({
                    ...prevState,
                    [rowId]: {
                        ...prevState?.[rowId],
                        ...newValuesObj,
                    },
                }));
            }
            somethingEdited.current = true;

            toast.success(`Updated ${columnName} of track ${trackCode}`, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });
        } catch (error: any) {
            // Revert DiscreteSelect and EntityStatus components to their previous value
            if (previousValuesObj) {
                setEditedRows((prevState: any) => ({
                    ...prevState,
                    [rowId]: {
                        ...prevState?.[rowId],
                        ...previousValuesObj,
                    },
                }));
            }

            const errorDetail = error?.response?.body?.errors?.[0]?.detail;
            const baseErrorMessage = `Error updating ${columnName} of track ${trackCode} `;
            const errorMessage = errorDetail ? `${baseErrorMessage}: ${errorDetail}` : baseErrorMessage;

            toast.error(errorMessage, {
                position: toast.POSITION.BOTTOM_RIGHT,
            });

            throw new Error(errorMessage); // propagate error to callers
        } finally {
            setIsSavingEdit(false);
        }
    };

    // Ideally we would simply re-use the 'trackMultiChangeHandler' function, but we cannot
    // because the InputProvider's API relies on each child Input setting a 'valueKey'
    // that corresponds to the propriety of the object defined in the 'value' prop.
    const editTextCellHandler = async (
        option: any,
        valueKey: any,
        row: any,
        trackProperty: any,
        columnKey: any,
        columnName: string
    ) => {
        const originalValue = row.original[columnKey];
        const previousValue = editedTextCells[valueKey] || originalValue;
        const newValue = option[valueKey];

        // Since the onChange gets called on clicking out of the field, we need
        // to check if the value has actually changed to avoid unnecessary API calls
        if (newValue === previousValue) return;

        try {
            // Update the state before making the API call to show the updated value to the user
            setEditedTextCells((prevState: any) => ({
                ...prevState,
                ...option,
            }));

            await trackMultiChangeHandler(row, { [trackProperty]: newValue }, columnName);
        } catch (error) {
            // Revert the state to the previous value if the API call fails
            setEditedTextCells((prevState: any) => ({
                ...prevState,
                [valueKey]: previousValue,
            }));
        }
    };

    const editAutocompleteAdapter = async (
        option: any,
        codeValueKey: any,
        descValueKey: any,
        trackCodeProperty: any,
        trackDescProperty: any,
        row: any,
        columnName: string,
        autocompleteKey: any,
        originalCode: any,
        originalDesc: any
    ) => {
        const newCode = option[codeValueKey];
        const previouslyEditedCode = editedTextCells[codeValueKey];
        const previouslyEditedDesc = editedTextCells[descValueKey];
        const previousCode = previouslyEditedCode || originalCode;
        const previousDesc = previouslyEditedDesc || originalDesc;

        // NOTE: With the current field behavior, when there is a blur, the onChange
        // gets called with an empty string if there was no edit. As such, here we
        // completely disallow clearing the field using the grid to avoid submitting
        // an empty string update when in reality there was no edit intended. Additionally,
        // since the field component allows for un-synced values between the state
        // and the shown input value, we set the state again to what it was to
        // force a re-render, thus avoiding showing an empty field when there is
        // in fact a value to be shown.
        if (newCode === '') {
            setEditedTextCells((prevState: any) => ({
                ...prevState,
                [codeValueKey]: previousCode,
                [descValueKey]: previousDesc,
            }));
            return;
        }

        try {
            const response = await autocompleteApi.getSelect(autocompleteKey)(newCode);
            const newDesc = response?.body?.data?.desc;

            if (newCode === previousCode && newDesc === previousDesc) {
                return;
            }

            // Update the state before making the API call to show the updated value to the user
            setEditedTextCells((prevState: any) => ({
                ...prevState,
                [codeValueKey]: newCode,
                [descValueKey]: newDesc,
            }));

            await trackMultiChangeHandler(
                row,
                {
                    [trackCodeProperty]: newCode,
                    [trackDescProperty]: newDesc,
                },
                columnName
            );
        } catch (error) {
            // Revert the state to the previous value if the API call fails
            setEditedTextCells((prevState: any) => ({
                ...prevState,
                [codeValueKey]: previousCode,
                [descValueKey]: previousDesc,
            }));
        }
    };

    const selectFieldOrValue = (trackProperty: any, editDropdownValues: any, row: any, value: any, headerTitle: any) =>
        editMode ? (
            <DiscreteSelect
                valueKey={trackProperty}
                options={editDropdownValues || []}
                value={editDropdownValues?.find(
                    (e: any) => e.code === (editedRows?.[row.id]?.[trackProperty] ?? value)
                )}
                onChange={(newVal: any) =>
                    trackMultiChangeHandler(row, { [trackProperty]: newVal }, headerTitle, {
                        [trackProperty]: value,
                    })
                }
                onClose={(e: any) => {
                    e.stopPropagation(); // Prevents click event from opening the row's track
                }}
                textAlign="left"
            />
        ) : (
            editDropdownValues.find((e: any) => e.code === value)?.desc || ''
        );

    const inputFieldOrValue = (
        valueKey: any,
        row: any,
        trackProperty: any,
        columnKey: any,
        headerTitle: any,
        value: any,
        inforCode: string
    ) =>
        editMode ? (
            // Prevents click event from opening the row's track
            // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
            <div onClick={(e) => e.stopPropagation()} onKeyDown={stopEnterKeyPropagation}>
                <InputProvider
                    value={editedTextCells}
                    entity="track"
                    onChange={(val: any) =>
                        editTextCellHandler(val, valueKey, row, trackProperty, columnKey, headerTitle)
                    }
                    readonly={readonly}
                    label=""
                >
                    <Input valueKey={valueKey} infor={inforCode} initialValue={value} />
                </InputProvider>
            </div>
        ) : (
            value
        );

    const columnOverrides = {
        [COLUMNS.TRACK_CODE]: {
            Header: HEADERS.TRACKS,
            width: 130,
            Cell: ({ value }: { value: string }) => (
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}>
                        <Typography variant="subtitle2">{value}</Typography>
                        <a
                            href={`${applicationData?.trackItVisualToolUrl}${applicationData?.trackItVisualToolUrlTracks}${value}`}
                            target="_blank"
                            onClick={(e) => e.stopPropagation()}
                            rel="noreferrer"
                        >
                            <AccountTree color="disabled" fontSize="small" />
                        </a>
                    </div>
                    {isLoadingTrackHierarchy ? (
                        <LinearProgress />
                    ) : (
                        <TrackHierarchy hierarchy={trackHierarchy[value] || {}} hideDescription hidePeriod />
                    )}
                </div>
            ),
        },
        [COLUMNS.STATUS]: {
            Header: HEADERS.STATUS,
            width: 100,
            Cell: ({ row, value }: { row: any; value: string }) => (
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                    {editMode ? (
                        <EntityStatus
                            entity="track"
                            status={editedRows?.[row.id]?.[TRACK_PROPERTIES.STATUS] ?? value}
                            onChange={(option) =>
                                trackMultiChangeHandler(
                                    row,
                                    { [TRACK_PROPERTIES.STATUS]: option.status },
                                    HEADERS.STATUS,
                                    { [TRACK_PROPERTIES.STATUS]: value }
                                )
                            }
                            onClose={(e: any) => {
                                e.stopPropagation(); // Prevents click event from opening the row's track
                            }}
                        />
                    ) : (
                        <>
                            <StatusIcon size={24} entity="track" status={value} />
                            <Typography variant="caption">
                                {dropdownValues?.trackStatuses.find((e) => e.code === value)?.desc}
                            </Typography>
                        </>
                    )}
                </div>
            ),
            dataType: '__SELECT',
            selectOptions: dropdownValues?.trackStatuses,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.SCOPE]: {
            Header: HEADERS.SCOPE,
            width: 130,
            Cell: ({ row, value }: { row: any; value: string }) =>
                selectFieldOrValue(TRACK_PROPERTIES.SCOPE, dropdownValues?.scopes, row, value, HEADERS.SCOPE),
            dataType: '__SELECT',
            selectOptions: dropdownValues?.scopes,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.FACILITY]: {
            Header: HEADERS.FACILITY,
            width: 130,
            Cell: ({ row, value }: { row: any; value: string }) =>
                selectFieldOrValue(TRACK_PROPERTIES.FACILITY, dropdownValues?.facilities, row, value, HEADERS.FACILITY),
            dataType: '__SELECT',
            selectOptions: dropdownValues?.facilities,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.PERIOD]: {
            Header: HEADERS.PERIOD,
            width: 100,
            Cell: ({ row, value }: { row: any; value: string }) =>
                selectFieldOrValue(TRACK_PROPERTIES.PERIOD, dropdownValues?.periods, row, value, HEADERS.PERIOD),
            dataType: '__SELECT',
            selectOptions: dropdownValues?.periods,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.LOCATION]: {
            Header: HEADERS.LOCATION,
            Cell: ({ row, value }: { row: any; value: string }) =>
                inputFieldOrValue(
                    `row${row.id}Location`,
                    row,
                    TRACK_PROPERTIES.LOCATION,
                    COLUMNS.LOCATION,
                    HEADERS.LOCATION,
                    value,
                    'udfchar18'
                ),
        },
        [COLUMNS.DESCRIPTION]: {
            Header: HEADERS.DESCRIPTION,
            width: 250,
            Cell: ({ row, value }: { row: any; value: string }) =>
                inputFieldOrValue(
                    `row${row.id}Description`,
                    row,
                    TRACK_PROPERTIES.DESCRIPTION,
                    COLUMNS.DESCRIPTION,
                    HEADERS.DESCRIPTION,
                    value,
                    'casedescription'
                ),
        },
        [COLUMNS.TASKS]: {
            Header: HEADERS.TASKS,
            width: Number(70),
            Cell: ({ row }: any) => {
                const rowData = row.original;
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {rowData.csm_tasks_notcompleted !== '0' && (
                            <div
                                style={{ display: 'flex' }}
                                title={`${rowData.csm_tasks_notcompleted} incomplete tasks(s)`}
                            >
                                <CheckBoxOutlineBlank fontSize="small" htmlColor="red" />
                                <Typography>{rowData.csm_tasks_notcompleted}</Typography>
                            </div>
                        )}
                        {rowData.csm_tasks_completed !== '0' && (
                            <div style={{ display: 'flex' }} title={`${rowData.csm_tasks_completed} complete tasks(s)`}>
                                <CheckBox fontSize="small" htmlColor="green" />
                                <Typography>{rowData.csm_tasks_completed}</Typography>
                            </div>
                        )}
                    </div>
                );
            },
        },
        [COLUMNS.NEEDS]: {
            Header: HEADERS.NEEDS,
            width: Number(70),
            Cell: ({ row }: any) => {
                const rowData = row.original;
                return (
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {rowData.csm_needs_notdone !== '0' && (
                            <div style={{ display: 'flex' }} title={`${rowData.csm_needs_notdone} unfulfilled need(s)`}>
                                <CheckBoxOutlineBlank fontSize="small" htmlColor="red" />
                                <Typography>{rowData.csm_needs_notdone}</Typography>
                            </div>
                        )}
                        {rowData.csm_needs_done !== '0' && (
                            <div style={{ display: 'flex' }} title={`${rowData.csm_needs_done} fulfilled need(s)`}>
                                <CheckBox fontSize="small" htmlColor="green" />
                                <Typography>{rowData.csm_needs_done}</Typography>
                            </div>
                        )}
                    </div>
                );
            },
        },
        [COLUMNS.LINKS]: {
            Header: HEADERS.LINKS,
            width: Number(70),
            Cell: ({ row }: any) => {
                const rowData = row.original;
                return `${rowData.csm_links}`;
            },
        },
        [COLUMNS.COMMENTS]: {
            Header: HEADERS.COMMENTS,
            width: Number(90),
            Cell: ({ row }: any) => {
                const rowData = row.original;
                return `${rowData.csm_comments}`;
            },
        },
        [COLUMNS.SRR]: {
            Header: HEADERS.SRR,
            width: Number(200),
            Filter: () => null,
            Cell: ({ row }: any) => {
                const rowData = row.original;

                if (isLoadingDocumentsInfoForTracks) {
                    return <LinearProgress />;
                }
                const hasDoc = documentsInfoForTracks[rowData.csm_code]?.EDMSSRR?.length;
                if (!hasDoc) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{rowData.csm_docsrr_details}</div>
                            <div>
                                {rowData.csm_docsrr_req_flag === '+'
                                    ? 'Needed'
                                    : rowData.csm_docsrr_req_flag === '-'
                                    ? 'Optional'
                                    : ''}
                            </div>
                        </div>
                    );
                }
                return (
                    <>
                        {documentsInfoForTracks[rowData.csm_code].EDMSSRR.map(
                            (srr: {
                                displayedDocName: string;
                                documentName: string;
                                status: string;
                                documentCode: string;
                                version: string;
                            }) => (
                                <div
                                    key={srr.documentCode}
                                    id={srr.displayedDocName}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        paddingBottom: 4,
                                    }}
                                    title={`${srr.displayedDocName} v.${srr.version} - ${srr.documentName}`}
                                >
                                    <ISLinkStatus
                                        primaryText={`SRR ${srr.displayedDocName}`}
                                        color={statusMapping[srr.status]?.color || statusMapping.default.color}
                                        statusText={statusMapping[srr.status]?.text || srr.status}
                                    />
                                </div>
                            )
                        )}
                    </>
                );
            },
        },
        [COLUMNS.ECR]: {
            Header: HEADERS.ECR,
            width: Number(200),
            Filter: () => null,
            Cell: ({ row }: any) => {
                const rowData = row.original;

                if (isLoadingDocumentsInfoForTracks) {
                    return <LinearProgress />;
                }
                const hasDoc = documentsInfoForTracks[rowData.csm_code]?.EDMSECR?.length;
                if (!hasDoc) {
                    return (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div>{rowData.csm_docecr_details}</div>
                            <div>
                                {rowData.csm_docecr_req_flag === '+'
                                    ? 'Needed'
                                    : rowData.csm_docecr_req_flag === '-'
                                    ? 'Optional'
                                    : ''}
                            </div>
                        </div>
                    );
                }
                return (
                    <>
                        {documentsInfoForTracks[rowData.csm_code].EDMSECR.map(
                            (ecr: {
                                displayedDocName: string;
                                documentName: string;
                                status: string;
                                documentCode: string;
                                version: string;
                            }) => (
                                <div
                                    key={ecr.documentCode}
                                    id={ecr.displayedDocName}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'baseline',
                                        paddingBottom: 4,
                                    }}
                                    title={`${ecr.displayedDocName} v.${ecr.version} - ${ecr.documentName}`}
                                >
                                    <ISLinkStatus
                                        primaryText={`ECR ${ecr.displayedDocName}`}
                                        color={statusMapping[ecr.status]?.color || statusMapping.default.color}
                                        statusText={statusMapping[ecr.status]?.text || ecr.status}
                                    />
                                </div>
                            )
                        )}
                    </>
                );
            },
        },
        [COLUMNS.INTEG_STUDIES]: {
            Header: HEADERS.IS,
            width: Number(150),
            Cell: ({ row }: any) => {
                const rowData = row.original;
                return (
                    <>
                        {isLoadingStudiesForTracks ? (
                            <LinearProgress />
                        ) : !studiesForTracks[rowData.csm_code]?.length ? (
                            rowData.csm_instudy_req_flag === '+' ? (
                                <Typography>Needed</Typography>
                            ) : (
                                <Typography>{rowData.csm_intstudy_details}</Typography>
                            )
                        ) : (
                            <>
                                {studiesForTracks[rowData.csm_code]?.map(
                                    (study: { code: string; description: string; status: string }) => (
                                        <div
                                            key={study.code}
                                            style={{ display: 'flex', alignItems: 'baseline', paddingBottom: 4 }}
                                            title={study.description}
                                        >
                                            <ISLinkStatus
                                                primaryText="IS"
                                                color={
                                                    statusMapping[study.status]?.color || statusMapping.default.color
                                                }
                                                statusText={statusMapping[study.status]?.text || study.status}
                                            />
                                        </div>
                                    )
                                )}
                            </>
                        )}
                    </>
                );
            },
        },
        [COLUMNS.GROUP]: {
            Header: HEADERS.GROUP,
            Cell: ({ row, value }: { row: any; value: string }) =>
                selectFieldOrValue(TRACK_PROPERTIES.GROUP, dropdownValues?.group, row, value, HEADERS.GROUP),
        },
        [COLUMNS.CONTACT]: {
            Header: HEADERS.CONTACT,
            width: 200,
            Cell: ({ row, value }: { row: any; value: string }) => {
                const codeValueKey = `row${row.id}ContactCode`;
                const descValueKey = `row${row.id}ContactDesc`;
                const originalContactCode = row.original.csm_responsible;
                return editMode ? (
                    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
                    <div onClick={(e) => e.stopPropagation()} onKeyDown={stopEnterKeyPropagation}>
                        <InputProvider
                            value={editedTextCells}
                            entity="track"
                            onChange={(val: any) =>
                                editAutocompleteAdapter(
                                    val,
                                    codeValueKey,
                                    descValueKey,
                                    TRACK_PROPERTIES.RESPONSIBLE_CODE,
                                    TRACK_PROPERTIES.RESPONSIBLE_DESC,
                                    row,
                                    HEADERS.CONTACT,
                                    'responsible',
                                    originalContactCode,
                                    value
                                )
                            }
                            readonly={readonly}
                            label=""
                        >
                            {/* TODO: fix the dropdown suggestions being currently trimmed to the size of the cell */}
                            <Input
                                valueKey={codeValueKey}
                                infor="responsible"
                                autocomplete="responsible"
                                initialValue={{ code: originalContactCode, desc: value }}
                            />
                        </InputProvider>
                    </div>
                ) : (
                    value
                );
            },
        },
        [COLUMNS.MATURITY]: {
            Header: HEADERS.MATURITY,
            Cell: ({ row, value }: { row: any; value: string }) =>
                selectFieldOrValue(
                    TRACK_PROPERTIES.MATURITY,
                    dropdownValues?.trackMaturityLevel,
                    row,
                    value,
                    HEADERS.MATURITY
                ),
            dataType: '__SELECT',
            selectOptions: dropdownValues?.trackMaturityLevel,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
        [COLUMNS.INSTALLATION]: {
            Header: HEADERS.INSTALLATION,
            Cell: ({ row, value }: { row: any; value: string }) =>
                selectFieldOrValue(
                    TRACK_PROPERTIES.INSTALLATION,
                    dropdownValues?.installation,
                    row,
                    value,
                    HEADERS.INSTALLATION
                ),
            dataType: '__SELECT',
            selectOptions: dropdownValues?.installation,
            getOptionLabel: (option: any) => option.desc,
            getOptionValue: (option: any) => option.code,
        },
    };

    const ExcelExportButton: TrackItFC<IExcelExportButtonProps> = () => {
        const { data } = useContext<any>(EAMGridContext);

        const handleExport: React.MouseEventHandler = () => {
            const filteredData = data.map((d: { [key: string]: string }) => {
                const studiesParsed = studiesForTracks[d.csm_code]
                    ? `${studiesForTracks[d.csm_code]
                          .map((s) => `${s.code}: ${statusMapping[s.status]?.text}`)
                          .join(', ')}`
                    : '';

                const srrParsed = documentsInfoForTracks[d.csm_code]?.EDMSSRR
                    ? `${documentsInfoForTracks[d.csm_code].EDMSSRR.map(
                          (srr) => `${srr.displayedDocName}: ${statusMapping[srr.status]?.text || srr.status}`
                      ).join(', ')}`
                    : '';

                const ecrParsed = documentsInfoForTracks[d.csm_code]?.EDMSECR
                    ? `${documentsInfoForTracks[d.csm_code].EDMSECR.map(
                          (ecr) => `${ecr.displayedDocName}: ${statusMapping[ecr.status]?.text || ecr.status}`
                      ).join(', ')}`
                    : '';

                return Object.fromEntries(
                    Object.entries(d)
                        .filter(([key]) => Object.keys(columnOverrides).includes(key))
                        .concat([
                            ['Tasks Not Completed', d.csm_tasks_notcompleted],
                            ['Tasks Completed', d.csm_tasks_completed],
                            ['Needs Not Completed', d.csm_needs_notdone],
                            ['Needs Completed', d.csm_needs_done],
                            ['Links', d.csm_links],
                            ['Comments', d.csm_comments],
                            ['Studies', studiesParsed],
                        ])
                        .map(([key, val]) => {
                            switch (key) {
                                case 'csm_udfchar09':
                                    return [
                                        key,
                                        dropdownValues?.trackStatuses.find((e) => e.code === val)?.desc || val,
                                    ];
                                case 'csm_udfchar12':
                                    return [key, dropdownValues?.scopes.find((e) => e.code === val)?.desc || val];
                                case 'csm_udfchar01':
                                    return [key, dropdownValues?.facilities.find((e) => e.code === val)?.desc || val];
                                case 'csm_udfchar06':
                                    return [key, dropdownValues?.periods.find((e) => e.code === val)?.desc || val];
                                case 'csm_udfchar16':
                                    return [
                                        key,
                                        dropdownValues?.trackMaturityLevel.find((e) => e.code === val)?.desc || val,
                                    ];
                                case 'csm_docecr_details':
                                    return [key, ecrParsed];
                                case 'csm_docsrr_details':
                                    return [key, srrParsed];
                                default:
                                    return [key, val];
                            }
                        })
                        .map(([key, val]) => (val.includes(',') ? [key, `"${val}"`] : [key, val]))
                );
            });

            const aoa = [
                Object.keys(filteredData[0]).map(
                    (k) => columnOverrides[k as keyof typeof columnOverrides]?.Header ?? k
                ),
                ...filteredData.map(Object.values),
            ];
            const utf8BOM = '\uFEFF';
            const downloadLink = document.createElement('a');
            downloadLink.href = `data:text/csv;charset=utf-8,${encodeURI(
                `${utf8BOM + aoa.map((e) => e.join(',')).join('\n')}`
            )}`;
            downloadLink.target = '_blank';
            downloadLink.download = 'tracks.csv';

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        };

        return (
            <Button
                disabled={isLoadingStudiesForTracks || isLoadingDocumentsInfoForTracks}
                onClick={handleExport}
                variant="outlined"
                size="small"
            >
                Export to Excel
            </Button>
        );
    };

    const defaultCreateColumns = ({ gridField }: { gridField: [] }) =>
        columnsOrder.reduce<any[]>((acc, columnID) => {
            if (!gridField) return acc;
            const field: any = gridField?.find((e: { name: string }) => e.name === columnID);
            if (!field && !columnID.startsWith('__')) return acc;
            return [
                ...acc,
                {
                    id: columnID,
                    accessor: columnID,
                    width: Number(field?.width || 0),
                    minWidth: 0,
                    maxWidth: 99999,
                    dataType: field?.dataType,
                    Filter: CustomEAMFilter,
                    ...columnOverrides[columnID as keyof typeof columnOverrides],
                },
            ];
        }, []);

    const handleDataChange = async ({ data }: any) => {
        const trackCodes = data.map((element: any) => element.csm_code);

        loadTrackHierarchy({ trackCodes });
        loadStudiesForTracks({ trackCodes });
        loadDocumentsInfoForTracks({ trackCodes });
    };

    const history = useHistory();

    const gridSyncedParamsProps = useGridSyncedParams();

    const [contextMenuPosition, setContextMenuPosition] = useState<{ x: number; y: number } | null>(null);
    const handleClose = () => setContextMenuPosition(null);

    const [targetRow, setTargetRow] = useState<IAny | null>(null);

    const dialogDispatch = useDialogDispatch();

    return (
        <>
            <EAMGridContextProvider
                gridName={gridName}
                {...gridSyncedParamsProps}
                initialSortBy={
                    gridSyncedParamsProps?.initialSortBy?.length
                        ? gridSyncedParamsProps.initialSortBy
                        : [{ id: 'csm_code', desc: false }]
                }
                // eslint-disable-next-line no-console
                handleError={(e: Error) => console.log(e)} // TODO
                searchOnMount
                createColumns={defaultCreateColumns}
                processData={({ data }: any) =>
                    // remove duplicate track rows (when multi parent)
                    data.filter((e: any, i: number) => data.findIndex((a: any) => a.csm_code === e.csm_code) === i)
                }
                sortByProcessor={(e: IAny) =>
                    // Trick: Sort by creation date to simulate numeric sortin on TRACK_CODE
                    e.sortBy === COLUMNS.TRACK_CODE ? { ...e, sortBy: COLUMNS.CREATION_DATE } : e
                }
                filterProcessor={(e: IAny) => ({
                    ...e,
                    operator: columnOverrides[e.fieldName]?.dataType === '__SELECT' ? '=' : e.operator,
                })}
                dataCallback={handleDataChange}
                blockGrid={isSavingEdit}
            >
                <EAMGrid
                    disableScrollUp={disableScrollUp}
                    customFooterOptions={() => (
                        <>
                            {!editMode && <ExcelExportButton />}
                            <Button
                                disabled={isLoadingStudiesForTracks || isLoadingDocumentsInfoForTracks || isSavingEdit}
                                onClick={() => {
                                    setEditMode((isEditMode) => {
                                        // Disabling edit mode refreshes the grid if there was an edit
                                        if (isEditMode && somethingEdited.current) {
                                            triggerEnterKeyToRefreshGrid();
                                            somethingEdited.current = false;
                                        }

                                        // Disable grid scroll up when enabling edit mode
                                        setDisableScrollUp(!isEditMode);

                                        return !isEditMode;
                                    });
                                }}
                                variant="outlined"
                                size="small"
                                style={{
                                    color: editMode && !isSavingEdit ? '#ff0000' : undefined,
                                    marginLeft: '3px',
                                }}
                            >
                                {editMode ? 'Exit Edit Mode' : 'Edit Mode'}
                            </Button>
                        </>
                    )}
                    getCellProps={() => ({
                        style: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        },
                    })}
                    getRowProps={(row: IAny) => ({
                        hover: true,
                        style: {
                            width: 'unset',
                            textDecoration: 'none',
                            cursor: 'pointer',
                        },
                        onClick: (e: IAny) => {
                            const path = getRoute({ path: ROUTES.tracks.view, params: { code: row.values.csm_code } });
                            if (e.ctrlKey) {
                                window.open(
                                    path.startsWith('/') ? `${process.env.PUBLIC_URL}${path} ` : path,
                                    '_blank'
                                );
                            } else {
                                history.push(path);
                            }
                        },
                        onContextMenu: (e: MouseEvent) => {
                            if (!readonly) {
                                e.preventDefault();
                                setContextMenuPosition({ x: e.clientX, y: e.clientY });
                                setTargetRow(row.original);
                            }
                        },
                    })}
                />
                <Popper
                    anchorEl={{
                        clientHeight: window.outerHeight,
                        clientWidth: window.outerWidth,
                        getBoundingClientRect: () => ({
                            width: 0,
                            height: 0,
                            x: contextMenuPosition?.x || 0,
                            y: contextMenuPosition?.y || 0,
                            top: contextMenuPosition?.y || 0,
                            bottom: contextMenuPosition?.y || 0,
                            left: contextMenuPosition?.x || 0,
                            right: contextMenuPosition?.x || 0,
                            toJSON: () => null,
                        }),
                    }}
                    style={{ zIndex: 999 }}
                    open={Boolean(contextMenuPosition)}
                    transition
                >
                    {({ TransitionProps }) => (
                        <Grow timeout={500} {...TransitionProps}>
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList autoFocusItem={Boolean(contextMenuPosition)}>
                                        <MenuItem
                                            onClick={() => {
                                                dialogDispatch({
                                                    type: DialogTypes.ADD_LINK,
                                                    state: {
                                                        entity: 'track',
                                                        code: targetRow?.csm_code,
                                                    },
                                                });
                                                handleClose();
                                            }}
                                        >
                                            Add Link
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                dialogDispatch({
                                                    type: DialogTypes.ADD_NEED,
                                                    state: {
                                                        code: targetRow?.csm_code,
                                                        entity: 'track',
                                                    },
                                                });
                                                handleClose();
                                            }}
                                        >
                                            Add Need
                                        </MenuItem>
                                        <MenuItem
                                            onClick={() => {
                                                dialogDispatch({
                                                    type: DialogTypes.ADD_TASK,
                                                    state: {
                                                        parent: targetRow?.csm_code,
                                                    },
                                                });
                                                handleClose();
                                            }}
                                        >
                                            Add Task
                                        </MenuItem>
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </EAMGridContextProvider>
        </>
    );
};

export default withEntity(TracksPage);
