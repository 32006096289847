import { ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight } from '@material-ui/icons';
import { getDropdownDescription } from 'helpers/helperFunctions';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';
import HorizontalEntityStatus from './HorizontalEntityStatus';

interface ITrackHierarchyProps {
    hierarchy: {
        parents?: IAny[] | null;
        children?: IAny[] | null;
        links?: IAny[] | null;
    };
    hideDescription?: boolean;
    hidePeriod?: boolean;
}

const renderHierarchyItem = ({
    item,
    hideDescription,
    hidePeriod,
}: {
    item: IAny;
    hideDescription?: boolean;
    hidePeriod?: boolean;
}) => {
    const { dropdownValues } = useDropdownValues();
    const { scopes, periods, facilities, trackStatuses, isStatuses } = dropdownValues || {};

    const isTrack = [item.entityType, item.typeCode].includes('TRACK');
    const extraData = [
        {
            title: 'Scope',
            value: getDropdownDescription({
                options: scopes,
                code: item.scope,
            }),
        },
        {
            title: 'Period',
            value: getDropdownDescription({
                options: periods,
                code: item.period,
            }),
        },
        {
            title: 'Facility',
            value: getDropdownDescription({
                options: facilities,
                code: item.facility,
            }),
        },
        {
            title: 'Status',
            value: getDropdownDescription({
                options: isTrack ? trackStatuses : isStatuses,
                code: item.status,
            }),
        },
        {
            title: 'Location',
            value: item.location,
        },
    ];

    return (
        <Link
            key={item.code}
            style={{ textDecoration: 'none' }}
            onClick={(e) => e.stopPropagation()}
            to={getRoute({
                path: isTrack ? ROUTES.tracks.view : ROUTES.integrationStudies.view,
                params: { code: item.code },
            })}
            title={`${item.code} - ${item.description}\n${extraData
                .filter((e) => e.value)
                .map((e) => `${e.title}: ${e.value}`)
                .join('\n')}`}
        >
            <HorizontalEntityStatus
                code={item.code}
                status={item.status}
                description={item.description}
                period={getDropdownDescription({
                    options: periods,
                    code: item.period,
                })}
                hideDescription={hideDescription}
                hidePeriod={hidePeriod}
            />
        </Link>
    );
};

const TrackHierarchy: TrackItFC<ITrackHierarchyProps> = ({ hierarchy, hideDescription, hidePeriod }) => (
    <div>
        {hierarchy.parents && Object.keys(hierarchy.parents).length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ArrowDropUp color="action" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {hierarchy.parents.map((item) => renderHierarchyItem({ item, hideDescription, hidePeriod }))}
                </div>
            </div>
        )}
        {hierarchy.children && Object.keys(hierarchy.children).length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ArrowDropDown color="action" />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {hierarchy.children.map((item) => renderHierarchyItem({ item, hideDescription, hidePeriod }))}
                </div>
            </div>
        )}
        {hierarchy.links && Object.keys(hierarchy.links).length > 0 && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
                <ArrowLeft color="action" style={{ margin: '0 -9px 0 -3px' }} />
                <ArrowRight color="action" style={{ margin: '0 -3px 0 -9px' }} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {hierarchy.links.map((item) => renderHierarchyItem({ item, hideDescription, hidePeriod }))}
                </div>
            </div>
        )}
    </div>
);

export default TrackHierarchy;
