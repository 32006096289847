import GridTools from 'eam-components/dist/ui/components/grids/GridTools';
import { TrackItFC } from 'helpers/helperTypes';
import useQueryParams from 'middleware/useQueryParams';
import React from 'react';
import { Redirect, useParams } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { COLUMNS as DOC_COLUMNS } from './DocumentsPage';
import { COLUMNS as TASK_COLUMNS } from './TasksPage';
import { COLUMNS as TRACKS_COLUMNS } from './TracksPage';

/*
    /SSO/trackit/integrationstudy.xhtml?code=97596 => /SSO/trackit/integrationstudies/97596
    /SSO/trackit/case.xhtml?code=10042 => /SSO/trackit/tracks/10042
    /SSO/trackit/case.xhtml?error=XXX => ?
    /SSO/trackit/case.xhtml => /SSO/trackit/create
    /SSO/trackit/case.xhtml?parentTrackCode=10042 => /SSO/trackit/create?parent=10042
    /SSO/trackit/task.xhtml?code=81281 => /SSO/trackit/tasks/81281

    /SSO/trackit/search.xhtml?
            searchKeyword=removal => ?
            sortColumn=facility => ?
            sortOrder=desc => ?
            scope=ADCONS => ?
            facility=FAC_SPS => ?
            period=PER_15_LS1 => ?
            status=TRK_STAT_20 => ?
            entity=TRACK => ?
            inentity=TRACK => ?

    /SSO/trackit/trackview.xhtml?
            code=76097 => ?
            sortColumn=csm_udfchar12 => ?
            sortOrder=asc => ?
            scope=ADCONS => ?
            facility=FAC_AD => ?
            period=PER_18_YETS => ?
            status=TRK_STAT_10 => ?
            location=AD+Hall => ?
            description=Extension+of => ?
            group=BE-EA => ?
            responsible=BUTIN => ?
            maturity=???

    /SSO/trackit/docs.xhtml?
            docname=1368378 => ?
            type=EC => ?
            sortOrder=asc => ?
            sortColumn=ted_track => ?
            status=Under+Impleme => ?
            title=Removal+of+a+BCT => ?
            version=1 => ?
            authors=Lars+Soby => ?
            scope=ADCONS => ?
            period=PER_15_LS1 => ?
            facility=FAC_SPS => ?
            track=89300 Removal of a BCT in TT10 during LS1 => ?

    /SSO/trackit/tasksview.xhtml?
            sortColumn=csm_udfchar04 => ?
            sortOrder=asc => ?
            taskCode=85779 => ?
            mytasks=true | undefined => /mytasks/search | /tasks/search
            status=TSK_STAT_10 => ?
            responsibleDesc=LACROIX => ?
            phase=PREPARATION => ?
            description=modele+Integration => ?
            code=76945 => ?
            parentIS=77795 => ?
            scope=ADCONS => ?
            period=PER_18_YETS => ?
            facility=FAC_PSCOMPLEX => ?
            createdByName=CORSO => ?
            updatedByName=CORSO => ?
*/

const convertQueryParams = ({
    queryParams,
    mapping,
}: {
    queryParams: { [key: string]: string };
    mapping: { [key: string]: string };
}) => ({
    gridFilters: GridTools.stringifyGridFilters(
        Object.entries(queryParams)
            .filter(([key]) => mapping[key])
            .filter(([, value]) => value)
            .map(([key, value]) => ({
                fieldName: mapping[key],
                fieldValue: value,
                operator: 'BEGINS',
            }))
    ),
    sortColumn: mapping[queryParams.sortColumn],
    sortOrder: queryParams.sortOrder,
});

const pageHandlers: { [index: string]: (queryParams: { [index: string]: string }) => string | undefined } = {
    'case.xhtml': ({ code, parentTrackCode }) => {
        if (code && parentTrackCode) {
            return undefined;
        }

        if (code) {
            return getRoute({ path: ROUTES.tracks.view, params: { code } });
        }

        if (parentTrackCode) {
            return getRoute({ path: ROUTES.tracks.create, queryParams: { parent: parentTrackCode } });
        }

        return getRoute({ path: ROUTES.tracks.create });
    },
    'integrationstudy.xhtml': ({ code }) => {
        if (code) {
            return getRoute({ path: ROUTES.integrationStudies.view, params: { code } });
        }

        return undefined;
    },
    'task.xhtml': ({ code }) => {
        if (code) {
            return getRoute({ path: ROUTES.tasks.view, params: { code } });
        }

        return undefined;
    },
    'search.xhtml': (queryParams) =>
        getRoute({
            path: ROUTES.globalSearch,
            queryParams: {
                query: queryParams.searchKeyword,
                entity: queryParams.entity,
                facility: queryParams.facility,
                period: queryParams.period,
                scope: queryParams.scope,
                status: queryParams.status,
                usedinEntity: queryParams.inentity,
            },
        }),
    'trackview.xhtml': (queryParams) => {
        const mapping: { [key: string]: string } = {
            code: TRACKS_COLUMNS.TRACK_CODE,
            status: TRACKS_COLUMNS.STATUS,
            scope: TRACKS_COLUMNS.SCOPE,
            facility: TRACKS_COLUMNS.FACILITY,
            period: TRACKS_COLUMNS.PERIOD,
            location: TRACKS_COLUMNS.LOCATION,
            description: TRACKS_COLUMNS.DESCRIPTION,
            group: TRACKS_COLUMNS.GROUP,
            responsible: TRACKS_COLUMNS.CONTACT,
            maturity: TRACKS_COLUMNS.MATURITY,
        };

        return getRoute({
            path: ROUTES.tracks.search,
            queryParams: convertQueryParams({ queryParams, mapping }),
        });
    },
    'docs.xhtml': (queryParams) => {
        const mapping: { [key: string]: string } = {
            docname: DOC_COLUMNS.NAME,
            type: DOC_COLUMNS.TYPE,
            status: DOC_COLUMNS.STATUS,
            title: DOC_COLUMNS.TITLE,
            version: DOC_COLUMNS.VERSION,
            authors: DOC_COLUMNS.AUTHORS,
            track: DOC_COLUMNS.TRACK,
            scope: DOC_COLUMNS.SCOPE,
            period: DOC_COLUMNS.PERIOD,
            facility: DOC_COLUMNS.FACILITY,
        };

        return getRoute({ path: ROUTES.documents.search, queryParams: convertQueryParams({ queryParams, mapping }) });
    },
    'tasksview.xhtml': (queryParams) => {
        const { mytasks } = queryParams;
        const mapping: { [key: string]: string } = {
            taskCode: TASK_COLUMNS.TASK_CODE,
            status: TASK_COLUMNS.STATUS,
            responsibleDesc: TASK_COLUMNS.ASSIGNEE,
            phase: TASK_COLUMNS.PHASE,
            description: TASK_COLUMNS.DESCRIPTION,
            code: TASK_COLUMNS.PARENT_TRACK,
            parentIS: TASK_COLUMNS.PARENT_IS,
            scope: TASK_COLUMNS.SCOPE,
            period: TASK_COLUMNS.PERIOD,
            facility: TASK_COLUMNS.FACILITY,
            createdByName: TASK_COLUMNS.CREATED_BY,
            updatedByName: TASK_COLUMNS.UPDATED_BY,
        };

        return getRoute({
            path: mytasks ? ROUTES.mytasks.search : ROUTES.tasks.search,
            queryParams: convertQueryParams({ queryParams, mapping }),
        });
    },
};

interface IRedirectPageProps {}

const RedirectPage: TrackItFC<IRedirectPageProps> = () => {
    const { page } = useParams<{ page: string }>();

    const queryParams = useQueryParams();

    const redirectURL = pageHandlers[page]?.(queryParams) ?? getRoute({ path: ROUTES.homepage });

    return <Redirect to={redirectURL} />;
};

export default RedirectPage;
