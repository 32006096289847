import { Box, BoxProps, Typography } from '@material-ui/core';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent, { DialogContentProps } from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import trackApi from 'api/track';
import { Checkbox, CloseButton, ValidateButton } from 'components/generic/dialog';
import { IAny } from 'helpers/helperTypes';
import { useData, useReload } from 'middleware/data';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TDetachIntegrationStudyDialog } from './dialogStates';
import DialogTypes from './types';

const DetachIntegrationStudyDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TDetachIntegrationStudyDialog>();
    const [studies] = useData('studies');
    const reloadStudies = useReload('studies');
    const [{ code }] = useData('general');

    const updateStudy = (study: IAny) => updateState({ studies: { ...state.studies, ...study } });

    const checkboxState = {
        ...Object.fromEntries(Object.values(studies).map((study: any) => [study.code, false] as const)),
        ...state.studies,
    };

    const checkboxes = Object.values(studies).map((study: any) => (
        <Checkbox
            label={`${study.code} - ${study.description}`}
            state={checkboxState}
            valueKey={study.code}
            update={updateStudy}
            key={study.code}
            disabled={study.numberOfParentLinks === 1}
            title={
                study.numberOfParentLinks === 1 &&
                'This study only has this track as a parent. If you want to remove it from this track as well, delete the study entirely.'
            }
            optional
        />
    ));

    const apiData = {
        parentTrackCode: code,
        studiesToDetach: Object.entries(checkboxState)
            .filter(([, value]) => value)
            .map(([key]) => key),
    };

    // the 2 styles below compensate for the fact that the Checkbox has the style "width: 50%"
    const contentStyle: DialogContentProps['style'] = { overflowX: 'hidden' };
    const contentDivStyle: BoxProps['style'] = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        width: '200%',
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title">Detach IS</DialogTitle>
            {checkboxes.length > 0 ? (
                <Box>
                    <DialogContent style={contentStyle}>
                        <div style={contentDivStyle}>{checkboxes}</div>
                    </DialogContent>
                    <DialogActions>
                        <CloseButton label="Cancel" />
                        <ValidateButton
                            callback={() =>
                                dispatchDialog({
                                    type: DialogTypes.LOADING,
                                    state: {
                                        promise: () => trackApi.detachIntegrationStudy(apiData),
                                        callback: () => {
                                            reloadStudies();
                                            dispatchDialog(null);
                                        },
                                        error: () =>
                                            dispatchDialog({
                                                type: DialogTypes.TEXT_CLOSE,
                                                state: {
                                                    title: 'Detach IS',
                                                    text: 'There was an error detaching the Integration Study.',
                                                },
                                            }),
                                    },
                                })
                            }
                            label="Confirm"
                        />
                    </DialogActions>
                </Box>
            ) : (
                <Box>
                    <DialogContent style={contentStyle}>
                        <div style={contentDivStyle}>
                            <Typography>There are no studies to detach.</Typography>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <CloseButton label="Close" />
                    </DialogActions>
                </Box>
            )}
        </>
    );
};

export default DetachIntegrationStudyDialog;
