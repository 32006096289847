import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { sliceReducersObject } from './reducers';

// const store = createStore(reducers, {}, composeWithDevTools(applyMiddleware(thunk)));

const store = configureStore({
    reducer: sliceReducersObject,
});

export type TRootState = ReturnType<typeof store.getState>;
export type TAppDispatch = typeof store.dispatch;

export const useTypedDispatch = () => useDispatch<TAppDispatch>();
export const useTypedSelector: TypedUseSelectorHook<TRootState> = useSelector;

export default store;
