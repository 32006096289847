import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import dialog from 'components/dialogs';
import DialogTypes from 'components/dialogs/types';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import { withChildren } from 'middleware/validation';
import React from 'react';

const overflowStyle: any = {
    overflowY: 'visible',
    minWidth: '400px',
};

const useStyles = makeStyles({
    paper: overflowStyle,
});

const DialogLayout = () => {
    const [dialogData] = useDialogState();
    const setDialogData = useDialogDispatch();
    const { type } = dialogData ?? {};
    const OpenDialog = dialog[type];
    const classes = useStyles();

    return (
        <Dialog
            open={dialogData !== null}
            onClose={() => setDialogData(null)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            disableBackdropClick={![DialogTypes.TEXT_OK, DialogTypes.TEXT_CLOSE].includes(type)}
            classes={{
                paper: classes.paper,
            }}
        >
            {OpenDialog && <OpenDialog />}
        </Dialog>
    );
};

export default withChildren(DialogLayout);
