import client from 'api/client';

const RESOURCE_PATH = `${process.env.REACT_APP_BACKEND}/integrationstudies`;

export const getIntegrationStudy = async ({ code }) => await client.get(`${RESOURCE_PATH}?code=${code}`);
export const getIntegrationStudyDefault = async ({ parent }) =>
    await client.get(`${RESOURCE_PATH}/default?parent=${parent}`);
export const saveIntegrationStudy = async (data) => await client.put(`${RESOURCE_PATH}`, data);
export const deleteIntegrationStudy = async ({ code }) => await client.delete(`${RESOURCE_PATH}?code=${code}`);
export const createIntegrationStudy = async (data) => await client.post(`${RESOURCE_PATH}`, data);
export const getHierarchy = async ({ code }) => await client.get(`${RESOURCE_PATH}/hierarchy/${code}`);
export const detachParent = async (data) => await client.post(`${RESOURCE_PATH}/detachparent`, data);
export const createNeed = async (data) => await client.post(`${RESOURCE_PATH}/need`, data);
export const saveNeed = async (data) => await client.put(`${RESOURCE_PATH}/need`, data);

const all = {
    getIntegrationStudy,
    getIntegrationStudyDefault,
    saveIntegrationStudy,
    createIntegrationStudy,
    deleteIntegrationStudy,
    getHierarchy,
    detachParent,
    createNeed,
    saveNeed,
};

export default all;
