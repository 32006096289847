export const getColumnFromMatrix = (mat: unknown[][], index: number) => mat.map((x) => x[index]);

export const transposeMatrix = (mat: unknown[][]) => mat[0].map((_, i) => mat.map((r) => r[i]));

export const arrange2DArrayByColumns = (mat: string[][], order: string[]) =>
    transposeMatrix([
        ...order.map((header) =>
            getColumnFromMatrix(
                mat,
                mat[0].findIndex((h) => h === header)
            )
        ),
    ]);

export const getDropdownDescription = ({ options = [], code }: { options: any[] | undefined; code: string }) =>
    options.find((e) => e.code === code)?.desc;
