import { Box, Typography } from '@material-ui/core';
import DiscreteSelect from 'components/generic/DiscreteSelect';
import statusMapping from 'components/generic/status';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import { Link } from 'react-router-dom';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';
import StatusIcon, { TEntityType } from './StatusIcon';

interface IEntityStatusProps {
    entity: TEntityType;
    status: keyof typeof statusMapping;
    code?: string;
    link?: string;
    onChange?: (value: any) => void;
    onClose?: (value: any) => void;
}

const InnerContent: TrackItFC<Omit<IEntityStatusProps, 'link'>> = ({ entity, status, code, onChange }) => (
    <>
        <StatusIcon {...{ status, entity }} />
        {code && <Typography variant="button">{code}</Typography>}
        {!onChange && <Typography style={{ marginTop: -3 }}>{statusMapping[status]?.text}</Typography>}
    </>
);

const EntityStatus: TrackItFC<IEntityStatusProps> = ({ link, onChange, onClose, entity, status, code }) => {
    const { dropdownValues } = useDropdownValues();
    const statusMap = {
        track: dropdownValues?.trackStatuses,
        integrationStudy: dropdownValues?.isStatuses,
        task: dropdownValues?.taskStatuses,
    };

    const innerContentProps = { entity, status, code, onChange };
    const options = (statusMap as any)?.[entity];
    return (
        <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center' }}>
            {link ? (
                <Link style={{ textDecoration: 'none', color: 'initial' }} to={link}>
                    <InnerContent {...innerContentProps} />
                </Link>
            ) : (
                <InnerContent {...innerContentProps} />
            )}
            {onChange && (
                <DiscreteSelect
                    valueKey="status"
                    options={options}
                    onChange={(value) => onChange({ status: value })}
                    onClose={onClose}
                    value={options.find((val: any) => val.code === status)}
                    width="80px"
                />
            )}
        </Box>
    );
};
export default EntityStatus;
