import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const createPartialDynamicSlice = <TState extends { location: string[] }>(name: string) => {
    type TNestedState = { [key: string]: TNestedState } | null;
    const initialState = { value: null as TNestedState };

    const slice = createSlice({
        name,
        initialState,
        reducers: {
            update: (state, action: PayloadAction<TState>) => {
                if (state === null) {
                    return state;
                }

                const location = [...action.payload.location];

                if (location.length === 0) {
                    return {
                        ...state,
                        ...action.payload,
                    };
                }

                const newObject = { ...state };
                const currState = newObject;

                for (;;) {
                    if (currState.value === null) {
                        return state;
                    }
                    const key = location[0] as keyof typeof currState.value;
                    location.shift();

                    if (location.length === 0) {
                        currState.value[key] = {
                            ...currState.value[key],
                            ...action.payload,
                        };
                        return newObject;
                    }

                    currState.value[key] = { ...currState.value[key] };
                    currState.value = currState.value[key];
                }
            },
        },
    });

    return [slice.reducer, slice.actions] as const;
};

export default createPartialDynamicSlice;
