import Box from '@material-ui/core/Box';
import Input from 'components/generic/Input';
import InputProvider from 'components/generic/InputProvider';
import ISLinkSynopsis from 'components/renderers/ISLinkSynopsis';
import { IAny } from 'helpers/helperTypes';
import { useData, useMetadata } from 'middleware/data';
import withRegion from 'middleware/withRegion';
import React, { useMemo } from 'react';
import { useUserData } from 'store/contexts/UserDataContext';
import { RegionComponent } from '../types';

const GeneralInformationRegion: RegionComponent = ({ setHeaderTitle }) => {
    const [general, updateGeneral] = useData('general');
    const [links] = useData('links');
    const [{ entity }] = useMetadata();
    const { readonly } = useUserData();
    const { code } = general;
    const newEntity = !code;

    setHeaderTitle('General Information');

    const sortedLinks = useMemo(
        () =>
            (Array.isArray(links) ? links : []).reduce((acc: IAny, cur: IAny) => {
                const group = acc[cur.system] || [];
                group.push(cur);
                acc[cur.system] = group;
                return acc;
            }, {}),
        [links]
    );

    return (
        <InputProvider value={general} entity={entity} onChange={updateGeneral} readonly={readonly} grid>
            {entity === 'track' && <Input valueKey="location" infor="udfchar18" />}
            {entity === 'track' && <Input valueKey="group" infor="udfchar13" select="group" />}
            <Input valueKey="responsibleCode" infor="responsible" autocomplete="responsible" label="Assignee" />
            {entity === 'track' && <Input valueKey="budgetCode" infor="udfchar08" />}
            {entity === 'task' && (
                <Input
                    valueKey="phase"
                    infor="udfchar03"
                    select="taskPhases"
                    valuesProcessing={(values) => [...values].reverse()}
                    renderSuggestion={(value) => value.desc}
                    renderValue={(value) => value.desc}
                />
            )}
            {entity === 'integrationStudy' && !newEntity && (
                <Box m={1}>
                    <ISLinkSynopsis links={sortedLinks} />
                </Box>
            )}
        </InputProvider>
    );
};

export default withRegion(GeneralInformationRegion, ['general']);
