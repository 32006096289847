import { SvgIcon } from '@material-ui/core';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import { ISquareIconProps } from './interfaces';

const TaskIcon: TrackItFC<ISquareIconProps> = ({ size, color }) => (
    <SvgIcon style={{ width: size, height: size }}>
        <g transform="matrix(.94371 0 0 .94371 -329.57 -158.39)" fill={color}>
            <g transform="matrix(2.0473 0 0 2.0772 312.4 -61.374)" strokeWidth=".48492">
                <path d="m24.1 115.64c-0.88611 0.79133-3.2784 3.3172-3.2784 3.3172l0.91777 0.90537s2.4677-2.4273 3.3171-3.2546c-0.43095-0.25146-0.73307-0.50118-0.9565-0.96802zm-2.2459 3.0227c0.1162-9e-5 0.21041 0.0941 0.21032 0.21033-1.96e-4 0.11599-0.09433 0.20989-0.21032 0.2098-0.11579-1.9e-4 -0.20961-0.094-0.20981-0.2098-8.9e-5 -0.116 0.09381-0.21013 0.20981-0.21033z" />
                <path
                    d="m27.511 115.31c-0.2536 0.94642-1.2264 1.5081-2.1728 1.2545-0.94643-0.25359-1.5081-1.2264-1.2545-2.1728 0.25359-0.94644 1.2264-1.5081 2.1728-1.2545 0.24188 0.0576 0.44181 0.18754 0.63096 0.31337l-1.602 1.049 0.09003 0.6701 0.47843 0.33933 1.7011-0.90212c0.01589 0.11414 0.02072 0.23076 0.01366 0.34843-0.0071 0.11767-0.02603 0.23639-0.05773 0.3547z"
                    style={{
                        paintOrder: 'stroke markers fill',
                    }}
                />
            </g>
            <path
                d="m355.58 169.34-6.3555 11.211 6.3555 11.211h12.721l0.10742-0.19141 6.248-11.02-6.3555-11.211zm0.43555 0.75h11.848l5.9316 10.461-5.9316 10.461h-11.848l-5.9297-10.461z"
                color="#000000"
                colorRendering="auto"
                dominantBaseline="auto"
                imageRendering="auto"
                shapeRendering="auto"
                style={{
                    fontFeatureSettings: 'normal',
                    fontVariantAlternates: 'normal',
                    fontVariantCaps: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantNumeric: 'normal',
                    fontVariantPosition: 'normal',
                    isolation: 'auto',
                    mixBlendMode: 'normal',
                    paintOrder: 'stroke markers fill',
                    textDecorationColor: '#000000',
                    textDecorationLine: 'none',
                    textDecorationStyle: 'solid',
                    textIndent: '0',
                    textOrientation: 'mixed',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                }}
            />
        </g>
    </SvgIcon>
);

export default TaskIcon;
