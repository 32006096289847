import DialogContent from '@material-ui/core/DialogContent';
import Input from 'components/generic/Input';
import InputProvider from 'components/generic/InputProvider';
import React from 'react';

const overflowStyle: any = {
    overflowY: 'visible',
};

const IntegrationStudyDialog = (props: any) => {
    const { state, updateState } = props;
    return (
        <>
            <DialogContent style={overflowStyle}>
                <InputProvider value={state} entity="task" onChange={updateState}>
                    <Input valueKey="description" infor="casedescription" />
                    <Input valueKey="responsibleCode" infor="responsible" autocomplete="responsible" />
                    <Input
                        valueKey="status"
                        infor="udfchar11"
                        select="isStatuses"
                        renderSuggestion={(value) => value.desc}
                        renderValue={(value) => value.desc}
                    />
                </InputProvider>
            </DialogContent>
        </>
    );
};

export default IntegrationStudyDialog;
