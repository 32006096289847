import { IAny, TrackItFC } from 'helpers/helperTypes';
import React, { Reducer, useContext, useReducer } from 'react';

const initialState: any = {};

const EntityContext = React.createContext(initialState);

const { Provider } = EntityContext;

enum EntityActions {
    UPDATE,
}

interface IEntityContextProviderProps {
    children: React.ReactNode;
}

// replace redux for entity management with react context, so that changes are local to the component
export const EntityContextProvider: TrackItFC<IEntityContextProviderProps> = ({ children }) => {
    const [entity, dispatch] = useReducer<
        Reducer<IAny, { type: EntityActions; location: (string | number)[]; data: IAny }>
    >((state, action) => {
        switch (action.type) {
            case EntityActions.UPDATE: {
                const location = [...action.location];

                if (location.length === 0) {
                    return {
                        ...state,
                        ...action.data,
                    };
                }

                const newObject = { ...state };
                let currState = newObject;

                for (;;) {
                    const key = location[0];
                    location.shift();

                    if (location.length === 0) {
                        const type = typeof action.data;
                        if (['boolean', 'number', 'string', 'function'].includes(type) || Array.isArray(action.data)) {
                            currState[key] = action.data;
                        } else {
                            currState[key] = {
                                ...currState[key],
                                ...action.data,
                            };
                        }

                        return newObject;
                    }

                    currState[key] = { ...currState[key] };
                    currState = currState[key];
                }
            }
            default:
                throw new Error(`Invalid entity action ${action.type}`);
        }
    }, initialState);

    return <Provider value={[entity, dispatch] as const}>{children}</Provider>;
};

export const useEntityBasic = <TData, TEntity = any>() => {
    const [state, dispatch] = useContext<
        [
            TEntity,
            React.Dispatch<{
                type: EntityActions;
                location: (string | number)[];
                data: TData;
            }>
        ]
    >(EntityContext);
    const updateEntity = (location: (string | number)[], data: TData) =>
        dispatch({ type: EntityActions.UPDATE, data, location });
    return [state, updateEntity] as const;
};
