import colors from 'components/color';
import { IAny } from 'helpers/helperTypes';

const statusMapping: IAny = {
    TSK_STAT_10: { text: 'Not Started', color: colors.red },
    TSK_STAT_20: { text: 'In Progress', color: colors.orange },
    TSK_STAT_40: { text: 'Completed', color: colors.green },
    TSK_STAT_50: { text: 'Cancelled', color: colors.grey },
    TSK_STAT_60: { text: 'For Deletion', color: colors.grey },
    TSK_STAT_70: { text: 'Postponed', color: colors.purple },

    TRK_STAT_10: { text: 'New', color: colors.red },
    TRK_STAT_20: { text: 'In Progress', color: colors.orange },
    TRK_STAT_40: { text: 'Completed', color: colors.green },
    TRK_STAT_50: { text: 'Canceled', color: colors.grey },
    TRK_STAT_60: { text: 'To be deleted', color: colors.grey },
    TRK_STAT_70: { text: 'Postponed', color: colors.purple },

    '10_Stat_IntStudy': { text: 'Not Started', color: colors.red },
    '20_Stat_IntStudy': { text: 'In Progress', color: colors.orange },
    '30_Stat_IntStudy': { text: 'Completed', color: colors.cyan },
    '40_Stat_IntStudy': { text: 'Validated', color: colors.green },
    '50_Stat_IntStudy': { text: 'Rejected', color: colors.red },
    '60_Stat_IntStudy': { text: 'Cancelled', color: colors.grey },

    'In Work': { text: 'In Work', color: colors.red },
    'Cancelled, with new version': { text: 'Cancelled, with new version', color: colors.grey },
    'Engineering Check': { text: 'Engineering Check', color: colors.orange },
    'For Approval': { text: 'For Approval', color: colors.orange },
    'Approval of action plan closed': { text: 'Approval of action plan closed', color: colors.orange },
    'Under Approval': { text: 'Under Approval', color: colors.orange },
    'Approval Closed': { text: 'Approval Closed', color: colors.orange },
    Approved: { text: 'Approved', color: colors.green },
    Released: { text: 'Released', color: colors.green },
    'Released-Approved': { text: 'Released-Approved', color: colors.green },
    Accepted: { text: 'Accepted', color: colors.green },
    'Approved and Performed': { text: 'Approved and Performed', color: colors.cyan },
    'Under Implementation': { text: 'Under Implementation', color: colors.cyan },
    Implemented: { text: 'Implemented', color: colors.cyan },
    'Approval rejected': { text: 'Approval rejected', color: colors.grey },
    Obsolete: { text: 'Obsolete', color: colors.grey },
    'Approved by not performed': { text: 'Approved by not performed', color: colors.grey },
    'Approval Accepted': { text: 'Approval Accepted', color: colors.grey },
    FINISHED: { text: 'Finished', color: colors.green },

    default: {
        text: 'Unknown',
        color: colors.grey,
    },
};

export default statusMapping;
