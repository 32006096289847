import { Description } from '@material-ui/icons';
import miscApi from 'api/misc';
import DialogTypes from 'components/dialogs/types';
import Input from 'components/generic/Input';
import InputProvider from 'components/generic/InputProvider';
import { SaveButton, SaveProvider } from 'components/generic/save';
import { IAny } from 'helpers/helperTypes';
import { useData } from 'middleware/data';
import { useDialogDispatch } from 'middleware/dialogContext';
import { withChildren } from 'middleware/validation';
import withRegion from 'middleware/withRegion';
import React, { useState } from 'react';
import { RegionComponent } from '../types';

const CreateFromDocumentRegion: RegionComponent = ({ setHeaderTitle }) => {
    const [state, setState] = useState<any>({
        id: '',
        version: '',
    });
    const setPartialState = (changes: any) =>
        setState({
            id: changes?.id?.trim() || state.id,
            version: changes?.version?.trim() || state.version,
        });

    const [general, updateGeneral] = useData('general');
    const dispatchDialog = useDialogDispatch();

    const { scope } = general;
    const { id, version } = state;

    const scopeToFacility: any = {
        LHCBASELINE: 'FAC_LHC',
        SPSBASELINE: 'FAC_SPS',
        PSBASELINE: 'FAC_PSCOMPLEX',
    };

    setHeaderTitle('Create from Document');

    return (
        <SaveProvider
            title="createfromdoc"
            saver={() => miscApi.getDocumentData(id, version)}
            afterSave={({ response }: any) => {
                updateGeneral({ description: response.body.data, fromDocumentID: id, fromDocumentVersion: version });
                if (Object.keys(scopeToFacility).includes(scope)) {
                    updateGeneral({ facility: scopeToFacility[scope], group: 'EN-ACE', period: 'PER_NA' });
                }
            }}
            error={(error: IAny) =>
                dispatchDialog({
                    type: DialogTypes.TEXT_CLOSE,
                    state: {
                        title: 'Failure',
                        text: error?.response?.body?.errors?.map((e: any) => <p>{e.detail}</p>),
                    },
                })
            }
        >
            <InputProvider type="text" value={state} onChange={setPartialState} grid>
                <Input required valueKey="id" label="Document Id" />
                <Input valueKey="version" label="Document Version" />
            </InputProvider>
            <SaveButton IconComponent={Description} label="Get document data" />
        </SaveProvider>
    );
};

export default withChildren(withRegion(CreateFromDocumentRegion, ['general']));
