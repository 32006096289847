import { TrackItFC } from 'helpers/helperTypes';
import React, { useContext, useReducer } from 'react';
import TDialogUnion from '../components/dialogs/dialogStates';

const initialState: any = null;

const DialogContext = React.createContext(initialState);

const { Provider } = DialogContext;

enum DialogActions {
    UPDATE,
}

interface IDialogContextProviderProps {
    children: React.ReactNode;
}

// replace redux for entity management with react context, so that changes are local to the component
export const DialogContextProvider: TrackItFC<IDialogContextProviderProps> = ({ children }) => {
    const [entity, dispatch] = useReducer((_: any, action: { type: DialogActions; data: TDialogUnion | null }) => {
        switch (action.type) {
            case DialogActions.UPDATE: {
                return action.data;
            }
            default:
                throw new Error(`Invalid entity action ${action.type}`);
        }
    }, initialState);

    return <Provider value={[entity, dispatch] as const}>{children}</Provider>;
};

const useDialogBasic = <TDialogState extends TDialogUnion = TDialogUnion>() => {
    const [state, dispatch] = useContext<
        [
            TDialogState | null,
            React.Dispatch<{
                type: DialogActions;
                data: TDialogState | null;
            }>
        ]
    >(DialogContext);
    const updateDialog = (data: TDialogState | null) => {
        if (!data || data?.state) dispatch({ type: DialogActions.UPDATE, data });
        else if (data?.type)
            dispatch({ type: DialogActions.UPDATE, data: { type: data.type, state: {} } as TDialogState });
    };
    return [state, updateDialog] as const;
};

export const useDialogState = <TDialogState extends TDialogUnion = TDialogUnion>() => {
    const [dialogInfo, dispatchDialog] = useContext<
        [
            TDialogState,
            React.Dispatch<{
                type: DialogActions;
                data: TDialogState | null;
            }>
        ]
    >(DialogContext);

    const updateDialog = (changes: Partial<TDialogState['state']>) => {
        const data = {
            type: dialogInfo.type,
            state: { ...dialogInfo.state, ...changes },
        } as TDialogState;

        dispatchDialog({ type: DialogActions.UPDATE, data });
    };

    return [dialogInfo as Required<TDialogState>, updateDialog] as const;
};

export const useDialogDispatch = () => useDialogBasic()[1];
