import { Box, BoxProps, Typography } from '@material-ui/core';
import { Add } from '@material-ui/icons';
import integrationStudyApi from 'api/integrationStudy';
import DialogTypes from 'components/dialogs/types';
import ActionDots from 'components/generic/ActionDots';
import Input, { IInputProps } from 'components/generic/Input';
import { SaveButton, SaveProvider } from 'components/generic/save';
import EntityStatus from 'components/renderers/EntityStatus';
import ISLinkSynopsis from 'components/renderers/ISLinkSynopsis';
import { getKeysOf, IAny, TrackItFC } from 'helpers/helperTypes';
import { useData, useMetadata, useReload } from 'middleware/data';
import { useDialogDispatch } from 'middleware/dialogContext';
import { withChildren } from 'middleware/validation';
import withRegion from 'middleware/withRegion';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useUserData } from 'store/contexts/UserDataContext';
import { RegionComponent } from '../types';

interface ITaskItemProps {
    onChange: (object: any) => void;
    entity: IInputProps['entity'];
    study: IAny;
    history: ReturnType<typeof useHistory>;
    readonly?: boolean;
    style?: Omit<NonNullable<BoxProps['style']>, 'display' | 'alignItems' | 'position'>;
}

const StudyItem: TrackItFC<ITaskItemProps> = ({ entity, onChange, study, style, history, readonly }) => {
    const dispatchDialog = useDialogDispatch();
    const [studies, updateStudies] = useData('studies');
    const reloadStudies = useReload('studies');

    return (
        <SaveProvider
            title={`IS$$${study.code}`}
            saver={() => integrationStudyApi.saveIntegrationStudy(study)}
            afterSave={() => {
                const newTask = { ...study };
                newTask.updateCount += 1;
                updateStudies(newTask, [Object.keys(studies).find((el) => studies[el] === study) as string]);
            }}
            error={() =>
                dispatchDialog({
                    type: DialogTypes.TEXT_CLOSE,
                    state: { title: 'Failure', text: 'Failed to save tasks' },
                })
            }
        >
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    position: 'relative',
                    padding: '10px 0',
                    justifyContent: 'space-between',
                    ...style,
                }}
            >
                <Box>
                    <EntityStatus
                        code={study.code}
                        status={study.status}
                        entity="integrationStudy"
                        link={getRoute({ path: ROUTES.integrationStudies.view, params: { code: study.code } })}
                        onChange={!readonly ? onChange : undefined}
                    />
                </Box>
                <Box
                    style={{
                        flex: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        justifyContent: 'space-between',
                        padding: '0 1rem',
                    }}
                >
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Box style={{ width: '100%', padding: '0.5rem' }}>
                            <Input
                                readonly={readonly}
                                value={study}
                                valueKey="description"
                                entity={entity}
                                infor="casedescription"
                                onChange={onChange}
                            />
                        </Box>
                        <Box style={{ width: '100%', padding: '0.5rem' }}>
                            <Input
                                value={study}
                                valueKey="responsibleCode"
                                entity={entity}
                                infor="responsible"
                                onChange={onChange}
                                autocomplete="responsible"
                                readonly={readonly}
                                label="Assignee"
                            />
                        </Box>
                    </Box>
                    <Box style={{ padding: '0.5rem' }}>
                        {study?.linkObjects ? <ISLinkSynopsis links={study.linkObjects} /> : null}
                    </Box>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'flex-end',
                            padding: '0.5rem',
                        }}
                    >
                        <Box style={{ display: 'flex' }}>
                            <Typography variant="subtitle2" color="textSecondary">
                                Tasks:
                            </Typography>
                            <Typography style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }} color="textSecondary">
                                {study.tasks}
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                            <Typography variant="subtitle2" color="textSecondary">
                                Needs:
                            </Typography>
                            <Typography style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }} color="textSecondary">
                                {study.needs}
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                            <Typography variant="subtitle2" color="textSecondary">
                                Links:
                            </Typography>
                            <Typography style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }} color="textSecondary">
                                {study.links}
                            </Typography>
                        </Box>
                        <Box style={{ display: 'flex' }}>
                            <Typography variant="subtitle2" color="textSecondary">
                                Comments:
                            </Typography>
                            <Typography style={{ paddingLeft: '0.5rem', paddingRight: '1rem' }} color="textSecondary">
                                {study.comments}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                {!readonly && (
                    <Box
                        style={{
                            alignSelf: 'stretch',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            alignContent: 'center',
                            padding: 5,
                        }}
                    >
                        <Box style={{ flexBasis: '25%', display: 'flex', justifyContent: 'end' }}>
                            <Box>
                                <SaveButton color="action" iconOnly />
                            </Box>
                            <ActionDots
                                isStatic
                                actions={[
                                    {
                                        name: 'Edit',
                                        onClick: () =>
                                            history.push(
                                                getRoute({
                                                    path: ROUTES.integrationStudies.view,
                                                    params: { code: study.code },
                                                })
                                            ),
                                    },
                                    {
                                        name: 'Delete',
                                        onClick: () =>
                                            dispatchDialog({
                                                type: DialogTypes.TEXT_YES_NO,
                                                state: {
                                                    title: 'Confirm deletion',
                                                    text: `Are you sure you want to delete Integration Study ${study.code}? This will completely delete all the study, not just unlink it from the current track!`,
                                                    callback: async () => {
                                                        dispatchDialog({
                                                            type: DialogTypes.LOADING,
                                                            state: {
                                                                promise: async () =>
                                                                    await integrationStudyApi.deleteIntegrationStudy({
                                                                        code: study.code,
                                                                    }),
                                                                callback: () => {
                                                                    reloadStudies();
                                                                    dispatchDialog(null);
                                                                },
                                                                error: () =>
                                                                    dispatchDialog({
                                                                        type: DialogTypes.TEXT_CLOSE,
                                                                        state: {
                                                                            title: 'Error',
                                                                            text: 'The study could not be deleted.',
                                                                        },
                                                                    }),
                                                            },
                                                        });
                                                    },
                                                },
                                            }),
                                    },
                                ]}
                            />
                        </Box>
                    </Box>
                )}
            </Box>
        </SaveProvider>
    );
};

const IntegrationStudyRegion: RegionComponent = (props) => {
    const { setHeaderTitle, setButtonRight } = props;
    const [general] = useData('general');
    const [studies, updateStudies] = useData('studies');
    const [{ entity }] = useMetadata();
    const reloadStudies = useReload('studies');
    const dispatchDialog = useDialogDispatch();
    const { readonly } = useUserData();

    const history = useHistory();

    useEffect(() => {
        if (!readonly)
            setButtonRight({
                icon: ({ size, color }) => <Add style={{ fontSize: size, color }} />,
                callback: () =>
                    dispatchDialog({
                        type: DialogTypes.ADD_INTEGRATION_STUDY,
                        state: {
                            parent: general.code,
                            callback: () => {
                                reloadStudies();
                                dispatchDialog(null);
                            },
                        },
                    }),
            });
        setHeaderTitle('Integration Studies');
    }, [general.code, reloadStudies]);

    return (
        <Box>
            {getKeysOf(studies).length > 0 ? (
                Object.values(studies).map((fs: any, i) => (
                    <StudyItem
                        onChange={(object) => updateStudies(object, [i])}
                        entity={entity}
                        study={fs}
                        history={history}
                        key={fs.code}
                        readonly={readonly}
                        style={{
                            borderBottom: i !== getKeysOf(studies).length - 1 ? '1px solid lightgrey' : undefined,
                        }}
                    />
                ))
            ) : (
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Typography color="textSecondary">No integration studies.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default withChildren(withRegion(IntegrationStudyRegion, ['studies']));
