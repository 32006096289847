import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import ApplicationDataProvider from './ApplicationDataContext';
import DropdownDataProvider from './DropdownDataContext';
import SettingsDataProvider from './SettingsDataContext';
import UserDataProvider from './UserDataContext';

interface IDataProviderProps {
    children: React.ReactNode;
}

const AggregateDataProvider: TrackItFC<IDataProviderProps> = ({ children }) => (
    <UserDataProvider>
        <ApplicationDataProvider>
            <SettingsDataProvider>
                <DropdownDataProvider>{children}</DropdownDataProvider>
            </SettingsDataProvider>
        </ApplicationDataProvider>
    </UserDataProvider>
);

export default AggregateDataProvider;
