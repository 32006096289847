import { Box, Grid, Typography } from '@material-ui/core';
import Input from 'components/generic/Input';
import InputProvider from 'components/generic/InputProvider';
import statusMapping from 'components/generic/status';
import ISLinkStatus from 'components/renderers/ISLinkStatus';
import { TrackItFC } from 'helpers/helperTypes';
import { useData, useMetadata } from 'middleware/data';
import withRegion from 'middleware/withRegion';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useUserData } from 'store/contexts/UserDataContext';
import { RegionComponent } from '../types';

interface IStatusItemProps {
    type: string;
    color: string;
    statusText: string;
    tooltip: string;
    to: string | undefined;
}

const StatusItem: TrackItFC<IStatusItemProps> = ({ type, color, statusText, tooltip, to }) => (
    <Link
        to={{
            pathname: to,
        }}
        style={{
            display: 'flex',
            flexBasis: '50%',
            cursor: 'pointer',
            alignItems: 'baseline',
            textDecoration: 'none',
            color: 'initial',
        }}
        title={tooltip}
        target="_blank"
        rel="noopener noreferrer"
    >
        <Typography style={{ minWidth: 40, fontWeight: 450, marginRight: 4 }}>{type}</Typography>
        <ISLinkStatus color={color} statusText={statusText} />
    </Link>
);

const StatusOverviewRegion: RegionComponent = ({ setHeaderTitle }) => {
    useEffect(() => {
        setHeaderTitle('Status Overview');
    }, []);

    const [general, updateGeneral] = useData('general');
    const [links] = useData('links');
    const [studies] = useData('studies');
    const [{ entity }] = useMetadata();
    const { readonly } = useUserData();

    const statuses: {
        type: string;
        status: keyof typeof statusMapping;
        pk: string;
        tooltip: string;
        to: string | undefined;
    }[] = [
        ...(links
            ? Object.values(links)
                  .filter((link: any) => !!link.statusDisplayValue && link.system !== 'TRACK')
                  .map((link: any) => ({
                      type: link.linkType ?? link.system,
                      status: link.statusDisplayValue,
                      pk: link.trackLinkPK,
                      tooltip: link.tooltip,
                      to: link.url,
                  }))
            : []),
        ...(studies
            ? Object.values(studies)?.map((study: any) => ({
                  type: 'IS',
                  status: study.status,
                  pk: study.code,
                  tooltip: `[${study.code}] ${study.description}\nStatus: ${
                      statusMapping[study.status as keyof typeof statusMapping].text
                  }`,
                  to: getRoute({ path: ROUTES.integrationStudies.view, params: { code: study.code } }),
              }))
            : []),
    ].sort((a, b) => {
        if (a.type > b.type) return 1;
        if (b.type > a.type) return -1;
        return 0;
    });

    return (
        <Box>
            {statuses.length > 0 ? (
                <Box style={{ display: 'flex', flexWrap: 'wrap' }}>
                    {statuses?.map((link) => (
                        <StatusItem
                            key={link.pk}
                            type={link.type}
                            color={statusMapping[link.status]?.color || statusMapping.default.color}
                            statusText={statusMapping[link.status]?.text || link.status}
                            tooltip={link.tooltip}
                            to={link.to}
                        />
                    ))}
                </Box>
            ) : (
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Typography color="textSecondary">No statuses.</Typography>
                </Box>
            )}

            {entity === 'track' && (
                <InputProvider grid value={general} entity={entity} onChange={updateGeneral}>
                    <Grid item sm={12} md={6}>
                        <Input
                            readonly={readonly}
                            select="installation"
                            valueKey="installation"
                            infor="udfchar07"
                            fullWidth
                            renderSuggestion={(value) => value.desc}
                            renderValue={(value) => value.desc}
                        />
                    </Grid>
                    <Grid item sm={12} md={6}>
                        <Input
                            readonly={readonly}
                            select="trackMaturityLevel"
                            valueKey="maturityLevel"
                            infor="udfchar16"
                            fullWidth
                            renderSuggestion={(value) => value.desc}
                            renderValue={(value) => value.desc}
                        />
                    </Grid>
                </InputProvider>
            )}
        </Box>
    );
};

export default withRegion(StatusOverviewRegion, ['links', 'studies']);
