import { BoxProps, Link as ExternalLink } from '@material-ui/core';
import { Theme, useTheme } from '@material-ui/core/styles';
import { TrackItFC } from 'helpers/helperTypes';
import { useApplicationSetting } from 'middleware/data';
import React from 'react';
import { Link } from 'react-router-dom';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import './ApplicationLayout.css';
import CompactButton from './CompactButton';
import HeaderDetails from './HeaderDetails';
import NavigationPane from './NavigationPane';

const navigationWidth = 240;

const screenDivStyle: BoxProps['style'] = {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    height: '100%',
};

const headerStyle: (theme: Theme) => BoxProps['style'] = (theme) => ({
    height: '48px',
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    justifyContent: 'flex-start',
});

const titleStyle: BoxProps['style'] = {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    boxSizing: 'border-box',
    paddingLeft: '15px',
    minWidth: `${navigationWidth}px`,
    fontFamily: 'Roboto,sans-serif',
    fontSize: '20px',
    lineHeight: '1.35em',
    color: '#fff',
    fontWeight: 900,
    textDecoration: 'none',
};

const detailsStyle: BoxProps['style'] = {
    backgroundColor: 'rgba(40, 40, 40, 0.06)',
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    color: 'rgba(255, 255, 255, 0.8)',
};

const pageStyle: BoxProps['style'] = {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    height: '100%',
};

const navigationStyle: (open: boolean) => BoxProps['style'] = (open) => ({
    position: 'fixed',
    width: `${navigationWidth}px`,
    marginLeft: open ? '0px' : `${20 - navigationWidth}px`,
    transition: 'margin-left 0.25s ease-in-out',
    backgroundColor: '#282828',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '10px',
    alignItems: 'stretch',
    height: '100%',
    overflow: 'auto',
    zIndex: 100,
});

const contentStyle: (open: boolean) => BoxProps['style'] = (open) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    flexGrow: 1,
    minWidth: '0',
    marginLeft: open ? `${navigationWidth}px` : '20px',
    transition: 'margin-left 0.25s ease-in-out',
    boxSizing: 'border-box',
});

const sectionStyle: BoxProps['style'] = {
    width: '100%',
    height: '100%',
    backgroundColor: '#f2f2f2',
    display: 'flex',
};

const footerStyle: (open: boolean) => BoxProps['style'] = (open) => ({
    borderTop: '1px solid #d1d1d1',
    textAlign: 'right',
    padding: '5px',
    marginLeft: open ? `${navigationWidth}px` : '20px',
    transition: 'margin-left 0.25s ease-in-out',
});

interface IApplicationLayout {
    children: React.ReactNode;
}

const ApplicationLayout: TrackItFC<IApplicationLayout> = ({ children }) => {
    const theme = useTheme();
    const [open, setOpen] = useApplicationSetting('open', true);
    const { applicationData } = useApplicationData();

    const version = process.env.REACT_APP_VERSION;

    return (
        <>
            <div style={screenDivStyle}>
                <header style={headerStyle(theme)}>
                    <Link to="/" style={titleStyle}>
                        Track It
                    </Link>
                    <CompactButton open={open} setOpen={setOpen} />
                    <div style={detailsStyle}>
                        <HeaderDetails />
                    </div>
                </header>
                <div style={pageStyle} className="page">
                    <nav style={navigationStyle(open)} className="navigation">
                        <NavigationPane />
                    </nav>
                    <div style={contentStyle(open)}>
                        <section style={sectionStyle}>{children}</section>
                    </div>
                </div>
                <footer style={footerStyle(open)}>
                    Track It v{version}
                    {'  '}•{'  '}
                    <ExternalLink
                        target="_blank"
                        href={applicationData?.helpURL || 'https://track-it-documentation.web.cern.ch/'}
                    >
                        Help
                    </ExternalLink>
                </footer>
            </div>
        </>
    );
};

export default ApplicationLayout;
