import { Input } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import autocompleteApi from 'api/autocomplete';
import { InputContext } from 'components/generic/InputProvider';
import { SaveContext } from 'components/generic/save';
import EAMAutocomplete from 'eam-components/dist/ui/components/muiinputs/EAMAutocomplete';
import EAMInput from 'eam-components/dist/ui/components/muiinputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/muiinputs/EAMSelect';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import { useChildren } from 'middleware/validation';
import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';
import { useUserData } from 'store/contexts/UserDataContext';

export interface IInputProps {
    value?: IAny;
    initialValue?: any;
    valueKey: string;
    onChange?: (object: any) => void;
    infor?: string;
    label?: string;
    entity?: 'track' | 'task' | 'integrationStudy';
    autocomplete?: string;
    select?: string;
    valuesProcessing?: (values: [IAny]) => IAny[];
    grid?: boolean;
    fullWidth?: boolean;
    type?: 'text';
    required?: boolean;
    fixed?: boolean;
    readonly?: boolean;
    renderSuggestion?: (value: any) => string | TrackItFC;
    renderValue?: (value: any) => string | TrackItFC;
}

export const withComponentWillReceivePropsDisabled = (Component: any) =>
    class extends Component {
        constructor(props: any) {
            super(props);

            const previousInit = this.init;
            let hasInit = false;
            this.init = (initProps: any) => {
                if (!hasInit) previousInit(initProps);
                hasInit = true;
            };
        }
    };

const EAMInputFixed: typeof EAMInput = withComponentWillReceivePropsDisabled(EAMInput);

const TrackItInput: TrackItFC<IInputProps> = (props) => {
    const inputContext = useContext(InputContext);
    const { dropdownValues } = useDropdownValues();
    const isFirstRun = useRef(true);

    const { addDirty } = useContext(SaveContext);

    const {
        value,
        initialValue,
        onChange: onChangeFunction,
        infor,
        entity,
        valueKey,
        autocomplete,
        select,
        valuesProcessing,
        grid,
        fullWidth,
        label,
        type,
        required,
        fixed,
        renderSuggestion,
        renderValue,
        readonly,
    } = {
        ...inputContext,
        ...props,
    };

    const onChange = (object: any) => {
        if (object[valueKey] !== undefined && value !== undefined && value[valueKey] !== object[valueKey]) {
            addDirty(valueKey);
        }
        onChangeFunction?.(object);
    };

    const descKey = valueKey.replace('Code', 'Desc');
    const code = value && value[valueKey];

    useEffect(() => {
        if (isFirstRun.current) {
            isFirstRun.current = false;
            return;
        }
        autocomplete &&
            autocompleteApi
                .getSelect(autocomplete)(code)
                .then(
                    (response: any) =>
                        response &&
                        response.body &&
                        response.body.data &&
                        onChange &&
                        onChange({ [descKey]: response.body.data.desc })
                );
    }, [code, isFirstRun]);

    if (value === undefined || onChange === undefined) {
        throw new Error('A mandatory prop is undefined');
    }

    const children = useChildren();

    const entityToScreen = {
        track: 'CSTRK1',
        task: 'CSTRK2',
        integrationStudy: 'CSINT1',
    };

    const { userData } = useUserData();

    const screenLayout = entity !== undefined && userData?.screenLayouts[entityToScreen[entity]]?.fields;
    const elementInfo = infor === undefined ? { fieldType: type } : { ...screenLayout[infor] };

    const values = useMemo(() => (select ? (dropdownValues as any)[select] : []), [select]);
    const valuesProcessed = useMemo(() => (valuesProcessing ? valuesProcessing(values) : values), [values]);

    if (required) {
        elementInfo.attribute = 'R';
    }

    if (readonly) {
        elementInfo.readonly = true;
    }

    if (label !== undefined) {
        elementInfo.text = label;
    }

    let element;

    if (!elementInfo || elementInfo.fieldType !== 'text') {
        element = <Input disabled={readonly} style={{ width: '100%' }} />;
    } else if (elementInfo.attribute === 'H') {
        return <></>;
    } else if (autocomplete) {
        element = (
            <EAMAutocomplete
                value={value[valueKey] || initialValue?.code}
                updateProperty={(newValue: any) => {
                    onChange({ [valueKey]: newValue });
                }}
                elementInfo={elementInfo}
                valueDesc={value[descKey] || initialValue?.desc}
                autocompleteHandler={autocompleteApi.getComplete(autocomplete)}
                // eslint-disable-next-line react/no-children-prop
                children={children}
            />
        );
    } else if (select) {
        element = (
            <EAMSelect
                elementInfo={elementInfo}
                value={value[valueKey]}
                values={valuesProcessed}
                updateProperty={(_: any, newValue: any) => onChange({ [valueKey]: newValue })}
                // eslint-disable-next-line react/no-children-prop
                children={children}
                renderSuggestion={renderSuggestion}
                renderValue={renderValue}
            />
        );
    } else {
        const InputComponent = fixed ? EAMInputFixed : EAMInput;
        element = (
            <InputComponent
                value={value[valueKey] || initialValue}
                updateProperty={(newValue: any) => onChange({ [valueKey]: newValue })}
                elementInfo={elementInfo}
                // eslint-disable-next-line react/no-children-prop
                children={children}
                inputProps={{ maxLength: elementInfo.maxLength }}
            />
        );
    }

    if (grid) {
        return (
            <Grid item xs={12} lg={fullWidth ? 12 : 6}>
                {element}
            </Grid>
        );
    }

    return element;
};

export default TrackItInput;
