import { SvgIcon } from '@material-ui/core';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import { ISquareIconProps } from './interfaces';

const MyTasksIcon: TrackItFC<ISquareIconProps> = ({ size, color }) => (
    <SvgIcon style={{ width: size, height: size }}>
        <g transform="matrix(.94371 0 0 .94371 -82.287 -50.476)" fill={color}>
            <g transform="matrix(1.8564 0 0 1.8564 -145.48 -156.52)">
                <g transform="matrix(1.104 0 0 1.104 105.47 -8.6205)" strokeWidth=".90578">
                    <path d="m24.1 115.64c-0.88611 0.79133-3.2784 3.3172-3.2784 3.3172l0.91777 0.90537s2.4677-2.4273 3.3171-3.2546c-0.43095-0.25146-0.73307-0.50118-0.9565-0.96802zm-2.2459 3.0227c0.1162-9e-5 0.21041 0.0941 0.21032 0.21033-1.96e-4 0.11599-0.09433 0.20989-0.21032 0.2098-0.11579-1.9e-4 -0.20961-0.094-0.20981-0.2098-8.9e-5 -0.116 0.09381-0.21013 0.20981-0.21033z" />
                    <path
                        d="m27.511 115.31c-0.2536 0.94642-1.2264 1.5081-2.1728 1.2545-0.94643-0.25359-1.5081-1.2264-1.2545-2.1728 0.25359-0.94644 1.2264-1.5081 2.1728-1.2545 0.24188 0.0576 0.44181 0.18754 0.63096 0.31337l-1.602 1.049 0.09003 0.6701 0.47843 0.33933 1.7011-0.90212c0.01589 0.11414 0.02072 0.23076 0.01366 0.34843-0.0071 0.11767-0.02603 0.23639-0.05773 0.3547z"
                        style={{
                            paintOrder: 'stroke markers fill',
                        }}
                    />
                </g>
                <g transform="matrix(.8923 0 0 .88214 105.11 15.094)" stroke="#fff" strokeWidth="1.1271">
                    <path
                        d="m27.871 119.34c-0.29951-0.12286-0.37772-0.22627-0.71052 0.66209-0.33279 0.88836 0.30277 3.0676 0.75848 3.1569h1.6539c0.4773 0.0698 1.1791-2.2457 0.80931-3.1398-0.36982-0.89407-0.46468-0.80881-0.78572-0.67923-0.59658 0.24079-1.2435 0.19767-1.7254 0zm0-0.15939c-0.23061-0.14418-0.35734-0.38481-0.35734-0.73351 0-0.6974 0.54624-1.2628 1.2201-1.2628 0.67381 0 1.22 0.56535 1.22 1.2628 0 0.3487-0.12673 0.58933-0.35734 0.73351-0.48768 0.30488-1.2377 0.30488-1.7254 0z"
                        fill={color}
                        stroke="#fff"
                        strokeWidth=".15905"
                    />
                </g>
            </g>
            <path
                d="m93.551 54.992-6.3555 11.211 6.3555 11.211h12.721l0.10742-0.19141 6.248-11.02-6.3555-11.211zm0.43555 0.75h11.848l5.9316 10.461-5.9316 10.461h-11.848l-5.9297-10.461z"
                color="#000000"
                colorRendering="auto"
                dominantBaseline="auto"
                imageRendering="auto"
                shapeRendering="auto"
                style={{
                    fontFeatureSettings: 'normal',
                    fontVariantAlternates: 'normal',
                    fontVariantCaps: 'normal',
                    fontVariantLigatures: 'normal',
                    fontVariantNumeric: 'normal',
                    fontVariantPosition: 'normal',
                    isolation: 'auto',
                    mixBlendMode: 'normal',
                    paintOrder: 'stroke markers fill',
                    textDecorationColor: '#000000',
                    textDecorationLine: 'none',
                    textDecorationStyle: 'solid',
                    textIndent: '0',
                    textOrientation: 'mixed',
                    textTransform: 'none',
                    whiteSpace: 'normal',
                }}
            />
        </g>
    </SvgIcon>
);

export default MyTasksIcon;
