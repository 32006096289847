import AttachIntegrationStudyDialog from './AttachIntegrationStudyDialog';
import AttachSubTrackDialog from './AttachSubTrackDialog';
import AttachTrackDialog from './AttachTrackDialog';
import CloneTrackDialog from './CloneTrackDialog';
import DetachIntegrationStudyDialog from './DetachIntegrationStudyDialog';
import DetachParentIntegrationStudyDialog from './DetachParentIntegrationStudyDialog';
import DetachParentTrackDialog from './DetachParentTrackDialog';
import DetachSubTrackDialog from './DetachSubTrackDialog';
import AddIntegrationStudyDialog from './IntegrationStudyDialog/AddIntegrationStudyDialog';
import AddLinkDialog from './LinkDialog/AddLinkDialog';
import EditLinkDialog from './LinkDialog/EditLinkDialog';
import LoadingDialog from './LoadingDialog';
import AddNeedDialog from './NeedDialog/AddNeedDialog';
import EditNeedDialog from './NeedDialog/EditNeedDialog';
import AddTaskDialog from './TaskDialog/AddTaskDialog';
import EditTaskDialog from './TaskDialog/EditTaskDialog';
import TextCloseDialog from './TextCloseDialog';
import TextOkDialog from './TextOkDialog';
import TextYesNoDialog from './TextYesNoDialog';
import DialogTypes from './types';

const all: { [K in DialogTypes]: () => JSX.Element } = {
    [DialogTypes.TEXT_CLOSE]: TextCloseDialog,
    [DialogTypes.TEXT_OK]: TextOkDialog,
    [DialogTypes.TEXT_YES_NO]: TextYesNoDialog,
    [DialogTypes.LOADING]: LoadingDialog,
    [DialogTypes.CLONE_TRACK]: CloneTrackDialog,
    [DialogTypes.ATTACH_SUBTRACK]: AttachSubTrackDialog,
    [DialogTypes.ATTACH_IS]: AttachIntegrationStudyDialog,
    [DialogTypes.ATTACH_TRACK]: AttachTrackDialog,
    [DialogTypes.DETACH_IS]: DetachIntegrationStudyDialog,
    [DialogTypes.DETACH_SUBTRACK]: DetachSubTrackDialog,
    [DialogTypes.DETACH_PARENTTRACK]: DetachParentTrackDialog,
    [DialogTypes.DETACH_PARENTIS]: DetachParentIntegrationStudyDialog,
    [DialogTypes.ADD_LINK]: AddLinkDialog,
    [DialogTypes.EDIT_LINK]: EditLinkDialog,
    [DialogTypes.ADD_NEED]: AddNeedDialog,
    [DialogTypes.EDIT_NEED]: EditNeedDialog,
    [DialogTypes.ADD_TASK]: AddTaskDialog,
    [DialogTypes.EDIT_TASK]: EditTaskDialog,
    [DialogTypes.ADD_INTEGRATION_STUDY]: AddIntegrationStudyDialog,
};

export default all;
