import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import * as needApi from 'api/need';
import { CloseButton, ValidateButton } from 'components/generic/dialog';
import { useReload } from 'middleware/data';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TEditNeedDialog } from '../dialogStates';
import DialogTypes from '../types';
import NeedDialog from './NeedDialog';

const EditNeedDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TEditNeedDialog>();
    const reloadNeeds = useReload('needs');

    if (state.isRequiredBool === null) {
        updateState({ isRequiredBool: false });
    }

    const { entity, ...apiData } = state;

    return (
        <>
            <DialogTitle id="alert-dialog-title">Edit Need</DialogTitle>
            <NeedDialog state={state} updateState={updateState} />
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => needApi.updateNeed(apiData),
                                callback: () => {
                                    reloadNeeds();
                                    dispatchDialog(null);
                                },
                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: { title: 'Edit Need', text: 'There was an error updating the need.' },
                                    }),
                            },
                        })
                    }
                    label="Modify"
                />
            </DialogActions>
        </>
    );
};

export default EditNeedDialog;
