import { Button, createStyles, Theme, Typography, useTheme, WithStyles } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import DocumentsIcon from 'components/icons/DocumentsIcon';
import MyTasksIcon from 'components/icons/MyTasksIcon';
import TaskIcon from 'components/icons/TaskIcon';
import TracksIcon from 'components/icons/TracksIcon';
import GridTools from 'eam-components/dist/ui/components/grids/GridTools';
import { IAny } from 'helpers/helperTypes';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import ROUTES, { getRoute } from 'routes/Routes';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';

const styles = ({ palette }: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
            backgroundColor: palette.background.default,
            color: palette.primary.main,
            overflow: 'auto',
        },
        headerSection: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '1rem',
        },
        dataSection: {
            display: 'flex',
            flexDirection: 'column',
            paddingBottom: '1rem',
        },
        reportsSection: {
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
        },
        reportsIFrame: {
            border: 'none',
            width: '100%',
            height: '100%',
        },
        sectionTitle: {
            paddingLeft: '1rem',
            backgroundColor: palette.grey[200],
            color: palette.primary.dark,
        },
        dataFiltersSection: {
            display: 'flex',
            flexDirection: 'row',
            width: '100%',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        menuNavigationSection: {
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
        },
        filterElement: {
            padding: '1rem',
            flex: 1,
        },
        menuNavigationElement: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '1rem',
        },
        menuNavigationButton: {
            marginTop: '0.5rem',
        },
    });

interface HomePageProps extends WithStyles<typeof styles> {}

interface IHomePageSelectFilterProps {
    options: { code: string; desc: string }[];
    text: string;
    value: { code: string; desc: string } | null;
    onChange: (
        newValue: {
            code: string;
            desc: string;
        } | null
    ) => void;
}

const HomePageSelectFilter = ({ options, text, value, onChange }: IHomePageSelectFilterProps) => (
    <>
        <Typography style={{ display: 'flex', justifyContent: 'center', paddingBottom: '0.5rem' }}>{text}</Typography>
        <Select
            isClearable
            defaultValue={value}
            options={options}
            onChange={onChange}
            placeholder="Select one..."
            getOptionValue={(option: { code: string; desc: string }) => option.code}
            getOptionLabel={(option: { code: string; desc: string }) => option.desc}
        />
    </>
);

const HomePage = withStyles(styles)(({ classes }: HomePageProps) => {
    const { applicationData } = useApplicationData();
    const { dropdownValues } = useDropdownValues();
    const history = useHistory();
    const theme = useTheme();
    const [scope, setScope] = useState<{ code: string; desc: string } | null>(null);
    const [period, setPeriod] = useState<{ code: string; desc: string } | null>(null);
    const [facility, setFacility] = useState<{ code: string; desc: string } | null>(null);

    useEffect(() => {
        document.title = 'Track It';
    }, []);

    const handleGridNavigation = ({ route }: IAny) => {
        const gridFilters: IAny[] = [
            {
                fieldName: 'csm_udfchar12',
                fieldValue: scope?.code,
                operator: '=',
            },
            {
                fieldName: 'csm_udfchar06',
                fieldValue: period?.code,
                operator: '=',
            },
            {
                fieldName: 'csm_udfchar01',
                fieldValue: facility?.code,
                operator: '=',
            },
        ];

        history.push(
            getRoute({
                path: route,
                queryParams: { gridFilters: GridTools.stringifyGridFilters(gridFilters.filter((e) => e.fieldValue)) },
            })
        );
    };

    return (
        <div className={classes.root}>
            <section className={classes.headerSection}>
                <Typography variant="h5" gutterBottom color="secondary">
                    Welcome to Track It
                </Typography>
            </section>
            <section className={classes.dataSection}>
                <Typography className={classes.sectionTitle} variant="subtitle1">
                    Data
                </Typography>

                <section className={classes.dataFiltersSection}>
                    <div className={classes.filterElement}>
                        <HomePageSelectFilter
                            value={scope}
                            onChange={(newValue) => setScope(newValue)}
                            options={dropdownValues?.scopes || []}
                            text="Scope"
                        />
                    </div>

                    <div className={classes.filterElement}>
                        <HomePageSelectFilter
                            value={period}
                            onChange={(newValue) => setPeriod(newValue)}
                            options={dropdownValues?.periods || []}
                            text="Period"
                        />
                    </div>

                    <div className={classes.filterElement}>
                        <HomePageSelectFilter
                            value={facility}
                            onChange={(newValue) => setFacility(newValue)}
                            options={dropdownValues?.facilities || []}
                            text="Facility"
                        />
                    </div>
                </section>

                <section className={classes.menuNavigationSection}>
                    <div className={classes.menuNavigationElement}>
                        <TracksIcon size={57} color={theme.palette.primary.main} />

                        <Button
                            className={classes.menuNavigationButton}
                            disableElevation
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => handleGridNavigation({ route: ROUTES.tracks.search })}
                        >
                            Tracks
                        </Button>
                    </div>
                    <div className={classes.menuNavigationElement}>
                        <DocumentsIcon size={57} color={theme.palette.primary.main} />

                        <Button
                            className={classes.menuNavigationButton}
                            disableElevation
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => handleGridNavigation({ route: ROUTES.documents.search })}
                        >
                            Documents
                        </Button>
                    </div>
                    <div className={classes.menuNavigationElement}>
                        <TaskIcon size={57} color={theme.palette.primary.main} />

                        <Button
                            className={classes.menuNavigationButton}
                            disableElevation
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => handleGridNavigation({ route: ROUTES.tasks.search })}
                        >
                            Tasks
                        </Button>
                    </div>
                    <div className={classes.menuNavigationElement}>
                        <MyTasksIcon size={57} color={theme.palette.primary.main} />

                        <Button
                            className={classes.menuNavigationButton}
                            disableElevation
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={() => handleGridNavigation({ route: ROUTES.mytasks.search })}
                        >
                            My Tasks
                        </Button>
                    </div>
                    {/* <div className={classes.menuNavigationElement}>
                        <WhatsNewIcon size={57} color={theme.palette.primary.main} />

                        <Button
                            className={classes.menuNavigationButton}
                            disableElevation
                            size="small"
                            color="primary"
                            variant="contained"
                        >
                            What&apos;s new?
                        </Button>
                    </div> */}
                </section>
            </section>

            <section className={classes.reportsSection}>
                <Typography className={classes.sectionTitle} variant="subtitle1">
                    Reports
                </Typography>

                <iframe
                    style={{ zIndex: 0 }}
                    className={classes.reportsIFrame}
                    title="Track It Reports"
                    src={applicationData?.trackItReportsURL}
                    allowFullScreen
                />
            </section>
        </div>
    );
});

export default HomePage;
