import { keycloak, tokens } from 'AuthWrapper';
import { AxiosInstance } from 'axios';
import ajax from 'eam-components/dist/tools/ajax';

const DEFAULT_TIMEOUT = 60000;

const configDefaults = {
    timeout: DEFAULT_TIMEOUT,
};

if (process.env.REACT_APP_LOGIN_METHOD === 'OPENID') {
    (ajax.getAxiosInstance() as AxiosInstance).interceptors.request.use(
        (oldConfig) =>
            // updateToken if it will last less than 5 minutes
            keycloak.updateToken(300).then(() => {
                const newConfig = oldConfig;
                if (tokens?.token && newConfig && newConfig.headers) {
                    newConfig.headers.Authorization = `Bearer ${tokens.token}`;
                }
                return newConfig;
            }),
        (error) => Promise.reject(error)
    );
}

const client = {
    ...ajax,
    getAxiosInstance: ajax.getAxiosInstance,
    get: (url: string, config?: object) => ajax.get(url, { ...configDefaults, ...config }),
    post: (url: string, body: object, config?: object) => ajax.post(url, body, { ...configDefaults, ...config }),
    put: (url: string, body: object, config?: object) => ajax.put(url, body, { ...configDefaults, ...config }),
    delete: (url: string, config?: object) => ajax.delete(url, { ...configDefaults, ...config }),
};

export default client;
