import { TrackItFC } from 'helpers/helperTypes';
import LoadingPane from 'layout/LoadingPane';
import { useEntityBasic } from 'middleware/entityContext';
import React from 'react';

const withRegion =
    <T extends object>(Component: TrackItFC<T>, loaderDependencies: string[]) =>
    (props: T) => {
        const [entity] = useEntityBasic();

        if (entity.loaders === undefined || loaderDependencies.some((dependency) => !entity.loaders[dependency])) {
            return <LoadingPane />;
        }

        return <Component {...props} />;
    };

export default withRegion;
