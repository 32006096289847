import taskApi from 'api/task';
import trackApi from 'api/track';
import DialogTypes from 'components/dialogs/types';
import RenderEntity from 'components/generic/RenderEntity';
import { SaveProvider } from 'components/generic/save';
import EntityDetailsRegion from 'components/regions/header/EntityDetailsRegion';
import NewEntityParentRegion from 'components/regions/header/NewEntityParentRegion';
import CommentsRegion from 'components/regions/side/CommentsRegion';
import EDMSDoclightRegion from 'components/regions/side/EDMSDoclightRegion';
import GeneralInformationRegion from 'components/regions/side/GeneralInformationRegion';
import LinksRegion from 'components/regions/side/LinksRegion';
import { useMetadata } from 'middleware/data';
import { useDialogDispatch } from 'middleware/dialogContext';
import useQueryParams from 'middleware/useQueryParams';
import withEntity from 'middleware/withEntity';
import React, { useEffect, useMemo } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';

const Task = (props: any) => {
    const { code, history } = props;
    const { parent } = useQueryParams();

    const apiArgs = useMemo(
        () => ({
            code,
        }),
        [code]
    );

    useEffect(() => {
        const prefix = code === undefined ? 'New ' : '';
        const suffix = code === undefined ? '' : ` ${code}`;
        document.title = `${prefix}Task${suffix}`;
    }, []);

    const newEntity = code === undefined;

    const emptyRequest = async () => ({ response: { data: {} } });

    const mandatoryLoaders = useMemo(
        () => ({
            general: newEntity ? taskApi.getTaskDefault : taskApi.getTask,
            hierarchy: newEntity
                ? emptyRequest
                : async (args: any) => {
                      try {
                          const response = await taskApi.getParent(args);
                          response.body.data = {
                              [args.code]: {
                                  parents: [response.body.data],
                              },
                          };
                          return response;
                      } catch (e) {
                          return await emptyRequest;
                      }
                  },
        }),
        [newEntity]
    );

    const loaders = useMemo(
        () => ({
            trackLinks: newEntity ? emptyRequest : async () => trackApi.getTrackLinksForCases({ cases: [code] }),
            links: newEntity ? emptyRequest : trackApi.getLinksFromTrack,
            comments: newEntity ? emptyRequest : trackApi.getCommentsFromTrack,
        }),
        [newEntity, code]
    );

    const [, updateMetadata] = useMetadata();

    const dispatchDialog = useDialogDispatch();

    const saver = newEntity
        ? async ({ data }: any) => await taskApi.createTask(data.general, parent)
        : async ({ data }: any) => await taskApi.saveTask(data.general);

    const afterSave = newEntity
        ? ({ response }: any) =>
              history.push({
                  pathname: getRoute({ path: ROUTES.tasks.view, params: { code: response.body.data } }),
                  state: { disablePrompt: true },
              })
        : ({ entity, updateEntity }: any) =>
              updateEntity(['data', 'general'], { updateCount: entity.data.general.updateCount + 1 });

    const entity = 'task';
    useEffect(() => {
        updateMetadata({
            entity,
            newEntity,
            dirty: [],
        });
    }, []);

    const headerRegions = useMemo(
        () => (newEntity ? [EntityDetailsRegion, ...(parent ? [NewEntityParentRegion] : [])] : [EntityDetailsRegion]),
        [newEntity, parent]
    );

    const leftRegions = useMemo(() => [GeneralInformationRegion, EDMSDoclightRegion], []);
    const rightRegions = useMemo(() => (newEntity ? [] : [CommentsRegion, LinksRegion]), [newEntity]);
    return (
        <SaveProvider
            title={entity}
            saver={saver}
            afterSave={afterSave}
            error={(err: any) =>
                dispatchDialog({
                    type: DialogTypes.TEXT_CLOSE,
                    state: { title: 'Task operation failed', text: err?.response?.body?.errors?.[0]?.detail },
                })
            }
        >
            <RenderEntity
                mandatoryLoaders={mandatoryLoaders}
                loaders={loaders}
                apiArgs={apiArgs}
                header={headerRegions}
                left={leftRegions}
                right={rightRegions}
                blockOnMetadata
            />
        </SaveProvider>
    );
};

const KeyedTask = (props: any) => {
    const { code } = useParams<{ code: string; parent: string }>();

    const Component = withEntity(withRouter(Task));
    return <Component key={code} code={code} {...props} />;
};

export default KeyedTask;
