import { Box, Checkbox, Typography } from '@material-ui/core';
import { CheckBoxOutlined, PriorityHigh } from '@material-ui/icons';
import { TrackItFC } from 'helpers/helperTypes';
import React, { useState } from 'react';

interface INeedsCheckboxProps {
    initialChecked: boolean;
    title: string;
    text?: string;
    required?: boolean;
    onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
    readonly?: boolean;
}

const NeedsCheckbox: TrackItFC<INeedsCheckboxProps> = ({
    title,
    text,
    initialChecked,
    onChange,
    required,
    readonly,
}) => {
    const [isChecked, setIsChecked] = useState(initialChecked);

    return (
        <Box
            style={{
                display: 'flex',
                alignItems: 'center',
                flex: 1,
                overflowWrap: 'break-word',
                overflow: 'hidden',
            }}
        >
            <Box style={{ display: 'flex', alignItems: 'baseline', minWidth: '60px', justifyContent: 'space-between' }}>
                <Box>{required && <PriorityHigh color={isChecked ? 'disabled' : 'error'} />}</Box>
                <Checkbox
                    checked={isChecked}
                    onChange={(ev) => {
                        setIsChecked(ev.target.checked);
                        onChange?.(ev);
                    }}
                    style={{ marginLeft: -15 }}
                    checkedIcon={<CheckBoxOutlined color="action" />}
                    disabled={readonly}
                />
            </Box>
            <Box style={{ minWidth: 0 }}>
                <Typography style={{ fontWeight: 500 }}>{title}</Typography>
                <Typography color="textSecondary" style={{ fontSize: 12 }}>
                    {text}
                </Typography>
            </Box>
        </Box>
    );
};
export default NeedsCheckbox;
