/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import statusMapping from 'components/generic/status';
import IntegrationStudyIcon from 'components/icons/IntegrationStudyIcon';
import { ISquareIconProps } from 'components/icons/interfaces';
import TaskIcon from 'components/icons/TaskIcon';
import TracksIcon from 'components/icons/TracksIcon';
import { TrackItFC } from 'helpers/helperTypes';
import React, { useMemo } from 'react';
import ColoredIcon from '../ColoredIcon';
import Stat_IntStudy_10 from './10_Stat_IntStudy.png';
import Stat_IntStudy_20 from './20_Stat_IntStudy.png';
import Stat_IntStudy_30 from './30_Stat_IntStudy.png';
import Stat_IntStudy_40 from './40_Stat_IntStudy.png';
import Stat_IntStudy_50 from './50_Stat_IntStudy.png';
import Stat_IntStudy_60 from './60_Stat_IntStudy.png';

export type TEntityType = 'track' | 'task' | 'integrationStudy';

type TIconRegistry = {
    [key in TEntityType]: {
        icon?: TrackItFC<ISquareIconProps>;
        values: { [key: string]: string };
        default?: string;
    };
};

const icons: TIconRegistry = {
    track: {
        icon: ({ size, color }) => <TracksIcon size={size} color={color} />,
        values: {
            TRK_STAT_10: 'red',
            TRK_STAT_20: 'orange',
            TRK_STAT_30: 'purple',
            TRK_STAT_40: 'green',
            TRK_STAT_50: 'gray',
            TRK_STAT_60: 'gray',
            TRK_STAT_70: 'gray',
        },
        default: 'gray',
    },
    task: {
        icon: ({ size, color }) => <TaskIcon size={size} color={color} />,
        values: {
            TSK_STAT_10: 'red',
            TSK_STAT_20: '#ffa500',
            TSK_STAT_40: 'green',
            TSK_STAT_50: 'grey',
            TSK_STAT_60: 'black',
            TSK_STAT_70: 'purple',
        },
        default: 'gray',
    },
    integrationStudy: {
        icon: ({ size, color }) => <IntegrationStudyIcon size={size} color={color} />,
        values: {
            '10_Stat_IntStudy': Stat_IntStudy_10,
            '20_Stat_IntStudy': Stat_IntStudy_20,
            '30_Stat_IntStudy': Stat_IntStudy_30,
            '40_Stat_IntStudy': Stat_IntStudy_40,
            '50_Stat_IntStudy': Stat_IntStudy_50,
            '60_Stat_IntStudy': Stat_IntStudy_60,
        },
    },
};

export interface IStatusIconProps {
    entity: keyof TIconRegistry;
    status: keyof TIconRegistry[TEntityType]['values'];
    size?: number;
}

const StatusIcon: TrackItFC<IStatusIconProps> = ({ entity, status, size }) => {
    const iconString = icons[entity].values[status];

    const IconAsset = icons[entity].icon;

    const iconRender = useMemo(
        () =>
            IconAsset ? (
                <ColoredIcon
                    size={size}
                    icon={({ size: sizeArg, color }) => <IconAsset size={sizeArg} color={color} />}
                    color={statusMapping?.[status]?.color || statusMapping.default}
                />
            ) : (
                <img src={iconString} alt="" style={size ? { width: `${size}px`, height: `${size}px` } : {}} />
            ),
        [size, status, IconAsset]
    );

    return <div style={{ display: 'flex ', justifyContent: 'center' }}>{iconRender}</div>;
};

export default StatusIcon;
