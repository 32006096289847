import integrationStudyApi from 'api/integrationStudy';
import trackApi from 'api/track';
import DialogTypes from 'components/dialogs/types';
import RenderEntity from 'components/generic/RenderEntity';
import { SaveProvider } from 'components/generic/save';
import EntityDetailsRegion from 'components/regions/header/EntityDetailsRegion';
import NewEntityParentRegion from 'components/regions/header/NewEntityParentRegion';
import CommentsRegion from 'components/regions/side/CommentsRegion';
import GeneralInformationRegion from 'components/regions/side/GeneralInformationRegion';
import LinksRegion from 'components/regions/side/LinksRegion';
import NeedsRegion from 'components/regions/side/NeedsRegion';
import StatusOverviewRegion from 'components/regions/side/StatusOverviewRegion';
import TasksRegion from 'components/regions/side/TasksRegion';
import { useMetadata, useUnsafeData } from 'middleware/data';
import { useDialogDispatch } from 'middleware/dialogContext';
import useQueryParams from 'middleware/useQueryParams';
import withEntity from 'middleware/withEntity';
import React, { useEffect } from 'react';
import { useParams, withRouter } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import EDMSDoclightRegion from 'components/regions/side/EDMSDoclightRegion';
import { TEntityAction } from './entityActions';

const IntegrationStudy = (props: any) => {
    const { code, history } = props;

    const { parent } = useQueryParams();

    const apiArgs = {
        code,
        parent,
    };

    useEffect(() => {
        const prefix = code === undefined ? 'New ' : '';
        const suffix = code === undefined ? '' : ` ${code}`;
        document.title = `${prefix}Integration Study${suffix}`;
    }, []);

    const newEntity = code === undefined;

    const emptyRequest = async () => ({ response: { data: {} } });

    const mandatoryLoaders = {
        general: newEntity ? integrationStudyApi.getIntegrationStudyDefault : integrationStudyApi.getIntegrationStudy,
        hierarchy: newEntity ? emptyRequest : integrationStudyApi.getHierarchy,
    };

    const loaders = {
        tasks: newEntity ? emptyRequest : trackApi.getTasksFromTrack,
        studies: newEntity ? emptyRequest : trackApi.getStudiesFromTrack,
        needs: newEntity ? emptyRequest : trackApi.getNeedsFromTrack,
        links: newEntity ? emptyRequest : trackApi.getLinksFromTrack,
        comments: newEntity ? emptyRequest : trackApi.getCommentsFromTrack,
        trackLinks: newEntity ? emptyRequest : async () => trackApi.getTrackLinksForCases({ cases: [code] }),
    };

    const [, updateMetadata] = useMetadata();
    const dispatchDialog = useDialogDispatch();

    const saver = newEntity
        ? async ({ data }: any) => await integrationStudyApi.createIntegrationStudy(data.general)
        : async ({ data }: any) => await integrationStudyApi.saveIntegrationStudy(data.general);

    const afterSave = newEntity
        ? ({ response }: any) =>
              history.push({
                  pathname: getRoute({ path: ROUTES.integrationStudies.view, params: { code: response.body.data } }),
                  state: { disablePrompt: true },
              })
        : ({ entity, updateEntity }: any) => {
              updateEntity(['data', 'general'], { updateCount: entity.data.general.updateCount + 1 });
              dispatchDialog({
                  type: DialogTypes.TEXT_CLOSE,
                  state: { title: 'Study saved', text: 'The study has been saved successfully.' },
              });
          };

    const entity = 'integrationStudy';

    useEffect(() => {
        updateMetadata({
            entity,
            dirty: [],
        });
    }, []);

    const [hierarchy] = useUnsafeData('hierarchy');

    const actions: TEntityAction[] = newEntity
        ? []
        : [
              {
                  name: 'Attach to Track',
                  onClick: () => dispatchDialog({ type: DialogTypes.ATTACH_TRACK }),
              },
              {
                  name: 'Detach from Track',
                  onClick: () => dispatchDialog({ type: DialogTypes.DETACH_PARENTIS }),
                  disabled: !hierarchy || !hierarchy?.[code]?.parents || hierarchy[code].parents.length === 1,
              },
          ];

    const headerRegions = newEntity
        ? [EntityDetailsRegion, ...(parent ? [NewEntityParentRegion] : [])]
        : [EntityDetailsRegion];
    const leftRegions = newEntity
        ? [GeneralInformationRegion]
        : [GeneralInformationRegion, TasksRegion, EDMSDoclightRegion];
    const rightRegions = newEntity ? [] : [StatusOverviewRegion, CommentsRegion, NeedsRegion, LinksRegion];

    return (
        <SaveProvider
            title={entity}
            saver={saver}
            afterSave={afterSave}
            error={(err: any) =>
                dispatchDialog({
                    type: DialogTypes.TEXT_CLOSE,
                    state: {
                        title: 'Integration Study operation failed',
                        text: err?.response?.body?.errors?.[0]?.detail,
                    },
                })
            }
        >
            <RenderEntity
                mandatoryLoaders={mandatoryLoaders}
                loaders={loaders}
                apiArgs={apiArgs}
                header={headerRegions}
                left={leftRegions}
                right={rightRegions}
                blockOnMetadata
                actions={actions}
            />
        </SaveProvider>
    );
};

const KeyedIntegrationStudy = (props: any) => {
    const { code } = useParams<{ code: string; parent: string }>();

    const Component = withEntity(withRouter(IntegrationStudy));
    return <Component key={code} code={code} {...props} />;
};

export default KeyedIntegrationStudy;
