import trackApi from 'api/track';
import React, { useEffect, useState } from 'react';
import StatusIcon from './StatusIcon';

const StatusFetch = (props: any) => {
    const { caseCode, ...others } = props;

    const [state, setState] = useState(null);

    useEffect(() => {
        trackApi.getCaseSummary(caseCode).then((response) => setState(response?.body?.data));
    }, []);

    if (state === null) {
        return null;
    }

    return <StatusIcon entity={(state as any)?.entityType} status={(state as any)?.status} {...others} />;
};

export default StatusFetch;
