import { ReactKeycloakProvider } from '@react-keycloak/web';
import { TrackItFC } from 'helpers/helperTypes';
import Keycloak from 'keycloak-js';
import React from 'react';

// Default envvars used as realm and clientId are not optional
const keycloak = Keycloak({
    url: process.env.REACT_APP_KEYCLOAK_URL,
    realm: process.env.REACT_APP_KEYCLOAK_REALM || 'cern',
    clientId: process.env.REACT_APP_KEYCLOAK_CLIENTID || 'trackit',
});

// eslint-disable-next-line import/no-mutable-exports
let tokens: any;

const handleTokens = (freshTokens: any) => {
    tokens = freshTokens;
};

const AuthWrapper: TrackItFC<{ children: React.ReactNode }> = ({ children }) => {
    switch (process.env.REACT_APP_LOGIN_METHOD) {
        case 'OPENID':
            return (
                <ReactKeycloakProvider
                    authClient={keycloak}
                    onTokens={handleTokens}
                    LoadingComponent={<></>}
                    initOptions={{ onLoad: 'login-required' }}
                >
                    {children}
                </ReactKeycloakProvider>
            );
        case 'CERNSSO':
        case 'LOCAL':
            return <>{children}</>;
        default:
            return <div>No authentication flow declared.</div>;
    }
};

const logout = () => {
    switch (process.env.REACT_APP_LOGIN_METHOD) {
        case 'OPENID':
            keycloak.logout();
            break;
        case 'CERNSSO':
            window.location.href = 'https://espace.cern.ch/authentication/_layouts/15/SignOut.aspx';
            break;
        case 'LOCAL':
            // eslint-disable-next-line no-alert
            alert(`LOCAL development: logout just shows this alert.`);
            break;
        default:
            break;
    }
};

export default AuthWrapper;
export { keycloak, tokens, logout };
