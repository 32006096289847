import client from 'api/client';

const RESOURCE_PATH = `${process.env.REACT_APP_BACKEND}/autocomplete`;

const createAutocompleteApi = (field, select) => async (query) =>
    await client.get(`${RESOURCE_PATH}/${field}${select ? '/select' : ''}?query=${query}`);

export const getComplete = (field) => createAutocompleteApi(field, false);
export const getSelect = (field) => createAutocompleteApi(field, true);

const all = {
    getComplete,
    getSelect,
};

export default all;
