import { Box, CircularProgress } from '@material-ui/core';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';

const LoadingPane: TrackItFC = () => (
    <Box style={{ display: 'flex', height: '100%', justifyContent: 'space-around', alignItems: 'center' }}>
        <CircularProgress size={60} />
    </Box>
);

export default LoadingPane;
