import { Box, Typography } from '@material-ui/core';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import StatusIcon from './StatusIcon';

interface IHorizontalEntityStatusProps {
    code: string;
    description?: string;
    period?: string;
    status: string;
    // color: string;
    hideDescription?: boolean;
    hidePeriod?: boolean;
}

const HorizontalEntityStatus: TrackItFC<IHorizontalEntityStatusProps> = ({
    code,
    description,
    period,
    status,
    // color,
    hideDescription,
    hidePeriod,
}) => (
    <Box style={{ display: 'flex', alignItems: 'center', width: 'fit-content', padding: 2 }}>
        <div style={{ display: 'flex', flexDirection: 'row', alignContent: 'center' }}>
            <StatusIcon size={16} entity="track" status={status} />
            <div style={{ paddingLeft: '0.25rem' }}>
                <Typography style={{ marginRight: 10, fontSize: 13, fontWeight: 500 }} color="textSecondary">
                    {code}
                </Typography>
            </div>
        </div>
        {description && !hideDescription && (
            <Typography
                style={{
                    maxWidth: 200,
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    marginRight: 10,
                    fontSize: 13,
                    fontWeight: 500,
                }}
                color="textSecondary"
            >
                {description}
            </Typography>
        )}
        {period && !hidePeriod && (
            <Typography style={{ fontSize: 13, fontWeight: 500 }} color="textSecondary">
                {period}
            </Typography>
        )}
    </Box>
);
export default HorizontalEntityStatus;
