import { Box, Typography } from '@material-ui/core';
import StatusFetch from 'components/renderers/StatusFetch';
import useQueryParams from 'middleware/useQueryParams';
import withRegion from 'middleware/withRegion';
import React from 'react';
import { RegionComponent } from '../types';

const NewEntityParentRegion: RegionComponent = () => {
    const { parent } = useQueryParams();

    return (
        <Box
            style={{
                backgroundColor: '#eceff1',
                textAlign: 'center',
                padding: 2,
            }}
        >
            <Box
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography style={{ marginRight: '5px' }}>Creating new with parent:</Typography>
                <Box style={{ marginRight: '5px' }}>
                    <StatusFetch size={24} caseCode={parent} />
                </Box>
                <Typography>{parent}</Typography>
            </Box>
        </Box>
    );
};

export default withRegion(NewEntityParentRegion, ['general']);
