/**
 *  UserDataContext.tsx
 *  For storing (statically) the user's data.
 */

import { createContext, useContext } from 'react';

const createContextTuple = <T extends object | null>() => {
    const context = createContext<T | undefined>(undefined);

    const useStaticContext = () => {
        const fetchedContext = useContext(context);
        if (fetchedContext === undefined) {
            throw new Error('Static context referenced outside static context provider');
        }
        return fetchedContext;
    };

    return [useStaticContext, context.Provider] as const;
};

export default createContextTuple;
