import { IconButton } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
        display: 'none',
        [theme.breakpoints.up('sm')]: {
            display: 'block',
        },
    },
    search: {
        position: 'relative',
        borderRadius: theme.shape.borderRadius,
        backgroundColor: fade(theme.palette.common.white, 0.15),
        '&:hover': {
            backgroundColor: fade(theme.palette.common.white, 0.25),
        },
        marginLeft: 0,
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            marginLeft: theme.spacing(1),
            width: 'auto',
        },
    },
    searchIcon: {
        margin: theme.spacing(0, 0.5),
        color: fade(theme.palette.common.white, 0.55),
    },
    inputRoot: {
        color: 'inherit',
    },
    inputInput: {
        padding: theme.spacing(1, 1, 1, 1),
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('sm')]: {
            width: '12ch',
            '&:focus': {
                width: '20ch',
            },
        },
    },
}));

interface ISearchFieldProps {
    placeholder?: string;
    onChange: ({ newValue }: { newValue: string }) => void;
    onSearch: ({ value }: { value: string }) => void;
    value: string;
}

const SearchField: TrackItFC<ISearchFieldProps> = ({ placeholder = 'Search...', onChange, onSearch, value }) => {
    const classes = useStyles();
    return (
        <div className={classes.search}>
            <InputBase
                placeholder={placeholder}
                onChange={(event) => onChange({ newValue: event.target.value })}
                onKeyDown={(event) => {
                    if (event.key === 'Enter') {
                        onSearch({ value });
                    }
                }}
                value={value}
                classes={{
                    root: classes.inputRoot,
                    input: classes.inputInput,
                }}
                inputProps={{ 'aria-label': 'search' }}
            />
            <IconButton size="small" onClick={() => onSearch({ value })} className={classes.searchIcon}>
                <SearchIcon />
            </IconButton>
        </div>
    );
};

export default SearchField;
