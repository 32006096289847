import { DialogActions, DialogTitle } from '@material-ui/core';
import taskApi from 'api/task';
import { CloseButton, ValidateButton } from 'components/generic/dialog';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TEditTaskDialog } from '../dialogStates';
import DialogTypes from '../types';
import TaskDialog from './TaskDialog';

const EditTaskDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TEditTaskDialog>();
    const { callback, ...apiData } = state;
    return (
        <>
            <DialogTitle id="alert-dialog-title">Edit Task</DialogTitle>
            <TaskDialog state={state} updateState={updateState} />
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => taskApi.saveTask(apiData),
                                callback: () => {
                                    callback && callback();
                                    dispatchDialog(null);
                                },
                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: { title: 'Edit Task', text: 'There was an error updating the task.' },
                                    }),
                            },
                        })
                    }
                    label="Modify"
                />
            </DialogActions>
        </>
    );
};
export default EditTaskDialog;
