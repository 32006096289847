import trackAPI from 'api/track';
import { useState } from 'react';

const useHierarchyForTracks = () => {
    const [loading, setLoading] = useState(false);
    const [trackHierarchy, setTrackHierarchy] = useState<{ [key: string]: { parents: any[]; children: any[] } }>({});

    const loadTrackHierarchy = async ({ trackCodes }: { trackCodes: string[] }) => {
        if (!trackCodes.length) return;
        setLoading(true);
        setTrackHierarchy({});
        const result = await trackAPI.getHierarchyFromTracks({ trackCodes });
        const trackHierarchyData = result.body.data;
        setTrackHierarchy(trackHierarchyData);
        setLoading(false);
    };

    return {
        loadTrackHierarchy,
        isLoadingTrackHierarchy: loading,
        trackHierarchy,
    };
};

export default useHierarchyForTracks;
