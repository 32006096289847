import trackAPI from 'api/track';
import { useState } from 'react';

const useDocumentsInfoForTracks = () => {
    const [loading, setLoading] = useState(false);
    const [documentsInfoForTracks, setDocumentsInfoForTracks] = useState<{
        [key: string]: { [key: string]: any[] };
    }>({});

    const loadDocumentsInfoForTracks = async ({ trackCodes }: { trackCodes: string[] }) => {
        if (!trackCodes.length) return;
        setLoading(true);
        setDocumentsInfoForTracks({});
        const result = await trackAPI.getDocumentsInfoForTracks({ trackCodes });
        const documentsInfoForTracksData = result.body.data;
        setDocumentsInfoForTracks(documentsInfoForTracksData);
        setLoading(false);
    };

    return {
        loadDocumentsInfoForTracks,
        isLoadingDocumentsInfoForTracks: loading,
        documentsInfoForTracks,
    };
};

export default useDocumentsInfoForTracks;
