import { SvgIcon } from '@material-ui/core';
import ColoredIcon from 'components/renderers/ColoredIcon';
import { TrackItFC } from 'helpers/helperTypes';
import { FileDocument, Hexagon, Home, PlusThick, Wrench, WrenchOutline } from 'mdi-material-ui';
import React from 'react';
import { Link } from 'react-router-dom';
import ROUTES from 'routes/Routes';
import { useUserData } from 'store/contexts/UserDataContext';
import './NavigationPane.css';

const Title: React.FC = ({ children }) => (
    <p
        style={{
            padding: '10px',
            fontFamily: 'Roboto,sans-serif',
            fontSize: '14px',
            color: '#fff',
            textTransform: 'uppercase',
            textAlign: 'center',
        }}
    >
        {children}
    </p>
);

interface IIconLinkProps {
    icon: typeof SvgIcon;
    to: string;
    children: React.ReactNode;
}

const IconLink: TrackItFC<IIconLinkProps> = ({ icon: Icon, to, children }) => (
    <Link
        style={{
            padding: '10px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            color: 'rgba(255, 255, 255, 0.7)',
            textDecoration: 'none',
            paddingBottom: '1px',
        }}
        to={to}
        className="iconLink"
    >
        <ColoredIcon icon={({ size, color }) => <Icon style={{ fontSize: size, color }} />} />
        {children}
    </Link>
);

const NavigationPane = () => {
    const { readonly } = useUserData();

    return (
        <>
            <Title>Main Menu</Title>
            <IconLink icon={Home} to={ROUTES.homepage}>
                Home
            </IconLink>
            {!readonly && (
                <IconLink icon={PlusThick} to={ROUTES.tracks.create}>
                    Create New Track
                </IconLink>
            )}
            <IconLink icon={Hexagon} to={ROUTES.tracks.search}>
                Tracks
            </IconLink>
            <IconLink icon={FileDocument} to={ROUTES.documents.search}>
                Documents
            </IconLink>
            <IconLink icon={Wrench} to={ROUTES.tasks.search}>
                Tasks
            </IconLink>
            <IconLink icon={WrenchOutline} to={ROUTES.mytasks.search}>
                My Tasks
            </IconLink>
        </>
    );
};

export default NavigationPane;
