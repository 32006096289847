import React, { useEffect } from 'react';
import { useData } from 'middleware/data';
import { useUserData } from 'store/contexts/UserDataContext';
import { useApplicationData } from 'store/contexts/ApplicationDataContext';
import withRegion from 'middleware/withRegion';
import queryString from 'query-string';
import IframeResizer from 'iframe-resizer-react';
import { Typography } from '@material-ui/core';
import { RegionComponent } from '../types';

const EDMSDoclightRegion: RegionComponent = ({ setHeaderTitle }) => {
    const [general] = useData('general');
    const { readonly } = useUserData();
    const { applicationData } = useApplicationData();

    useEffect(() => {
        setHeaderTitle('Image Gallery');
    }, []);

    const docLightStyle = { width: '1px', minWidth: '100%', border: 'none' };

    const queryParams = queryString.stringify({
        collapsible: 'false',
        mode: readonly ? 'read' : 'write',
        objectID: general.code,
        objectType: 'CASE',
        profile: 'TRACKIT',
        title: '',
        view: 'gallery',
        header: 'false',
    });

    const src = `${applicationData?.edmsdoclightUrl}?${queryParams}`;

    if (process.env.NODE_ENV === 'development') {
        return (
            <Typography color="textSecondary">
                The Doclight iframe is not loaded in the development environment due to infinite network requests being
                done in some cases (for example, while using some chromium-based browsers).
            </Typography>
        );
    }

    return <IframeResizer style={docLightStyle} src={src} heightCalculationMethod="max" />;
};

export default withRegion(EDMSDoclightRegion, ['general']);
