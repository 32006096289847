import { TrackItFC } from 'helpers/helperTypes';
import React from 'react';
import { ISquareIconProps } from './interfaces';

const IntegrationStudyIcon: TrackItFC<ISquareIconProps> = ({ size, color = '#008fe2' }) => (
    <div style={{ width: size || '24px', height: size || '24px' }}>
        <object>
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
                display="inline-block"
                aria-hidden="true"
                role="presentation"
            >
                <g>
                    <rect
                        stroke="#ffffff"
                        transform="rotate(10.190423011779785 14.377006530761713,12.171277046203608) "
                        id="svg_7"
                        height="18.210583"
                        width="15.250243"
                        y="3.065986"
                        x="6.751885"
                        strokeWidth="0"
                        fill={color}
                    />
                    <path
                        stroke={color}
                        transform="rotate(90 9.182779312133789,12.03067970275879) "
                        id="svg_9"
                        d="m2.96404,17.47208l6.21874,-10.8828l6.21874,10.8828l-12.43748,0z"
                        strokeWidth="0"
                        fill="#ffffff"
                    />
                    <ellipse stroke="#000" id="svg_1" cy="0.590831" cx="1.40626" strokeWidth="1.5" fill="#ffffff" />
                    <path
                        stroke="#ffffff"
                        transform="rotate(90 6.104380130767821,12.03067970275879) "
                        id="svg_5"
                        d="m-0.114363,17.472082l6.218744,-10.882805l6.218744,10.882805l-12.437487,0z"
                        strokeWidth="0"
                        fill={color}
                    />
                </g>
            </svg>
        </object>
    </div>
);

export default IntegrationStudyIcon;
