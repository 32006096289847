import { Box, BoxProps, Typography } from '@material-ui/core';
import { Add, Description, Event, Folder, InsertLink, List, Storage } from '@material-ui/icons';
import linkApi from 'api/link';
import DialogTypes from 'components/dialogs/types';
import ActionDots from 'components/generic/ActionDots';
import statusMapping from 'components/generic/status';
import { ISquareIconProps } from 'components/icons/interfaces';
import TracksIcon from 'components/icons/TracksIcon';
import IntegrationStudyIcon from 'components/icons/IntegrationStudyIcon';
import { getDropdownDescription } from 'helpers/helperFunctions';
import { getKeysOf, IAny, TrackItFC } from 'helpers/helperTypes';
import { MathCompass } from 'mdi-material-ui';
import { useData, useMetadata, useReload } from 'middleware/data';
import { useDialogDispatch } from 'middleware/dialogContext';
import withRegion from 'middleware/withRegion';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ROUTES, { getRoute } from 'routes/Routes';
import { useDropdownValues } from 'store/contexts/DropdownDataContext';
import { useUserData } from 'store/contexts/UserDataContext';
import { RegionComponent } from '../types';

interface ILinkItemProps {
    entity: string;
    link: IAny;
    style?: Omit<NonNullable<BoxProps['style']>, 'display' | 'alignItems' | 'position'>;
    readonly?: boolean;
}

const LinkItem: TrackItFC<ILinkItemProps> = ({ entity, link, style, readonly }) => {
    let Icon: TrackItFC<ISquareIconProps>;
    const { dropdownValues } = useDropdownValues();
    const { scopes, periods } = dropdownValues || {};

    const dispatchDialog = useDialogDispatch();
    const reloadLinks = useReload('links');

    switch (link.system) {
        case 'EDMSSRR':
        case 'EDMSECR':
            Icon = ({ color, size }) => <Description style={{ fontSize: size, color }} />;
            break;
        case 'PLAN':
            Icon = ({ color, size }) => <Storage style={{ fontSize: size, color }} />;
            break;
        case 'INDICO':
            Icon = ({ color, size }) => <Event style={{ fontSize: size, color }} />;
            break;
        case 'TRACKIS':
            Icon = IntegrationStudyIcon;
            break;
        case 'TRACK':
            Icon = TracksIcon;
            break;
        case 'EDMS':
            switch (link.typeDisplayValue) {
                case 'Drawing':
                    Icon = ({ color, size }) => <MathCompass style={{ fontSize: size, color }} />;
                    break;
                case 'Drawing Folder':
                    Icon = ({ color, size }) => <Folder style={{ fontSize: size, color }} />;
                    break;
                case 'Specification':
                    Icon = ({ color, size }) => <List style={{ fontSize: size, color }} />;
                    break;
                default:
                    Icon = ({ color, size }) => <Description style={{ fontSize: size, color }} />;
            }
            break;
        default:
            Icon = ({ color, size }) => <InsertLink style={{ fontSize: size, color }} />;
    }

    const LinkComponent = (children: JSX.Element) =>
        ['TRACK', 'TRACKIS'].includes(link.system) ? (
            <Link
                to={{
                    pathname:
                        link.system === 'TRACK'
                            ? getRoute({ path: ROUTES.tracks.view, params: { code: link.sysRecordID } })
                            : link.system === 'TRACKIS'
                            ? getRoute({ path: ROUTES.integrationStudies.view, params: { code: link.sysRecordID } })
                            : link.url,
                }}
                style={{ textDecoration: 'none', color: 'initial', flexBasis: '90%' }}
                title={link.tooltip}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </Link>
        ) : (
            <a
                href={link.url.startsWith('http') ? link.url : `//${link.url}`}
                style={{ textDecoration: 'none', color: 'initial', flexBasis: '90%' }}
                title={link.tooltip}
                target="_blank"
                rel="noopener noreferrer"
            >
                {children}
            </a>
        );

    return (
        <Box style={{ display: 'flex', alignItems: 'center', position: 'relative', padding: '5px 0', ...style }}>
            {LinkComponent(
                <Box style={{ width: '100%' }}>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Icon
                            size={25}
                            color={statusMapping[link.statusDisplayValue]?.color || statusMapping.default.color}
                        />
                        <Typography color="textSecondary" style={{ marginLeft: 3 }}>
                            {link.trackSummary?.scope || link.trackSummary?.period
                                ? `${getDropdownDescription({
                                      options: scopes,
                                      code: link.trackSummary.scope,
                                  })} | ${getDropdownDescription({
                                      options: periods,
                                      code: link.trackSummary.period,
                                  })}`
                                : link.statusDisplayValue?.toUpperCase() ||
                                  (link.system === 'INDICO' ? 'EVENT' : undefined)}
                        </Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box style={{ padding: 3 }}>
                            <Typography style={{ fontWeight: 450 }}>
                                {link.typeDisplayValue && link.typeDisplayValue !== ''
                                    ? link.typeDisplayValue
                                    : link.linkType}
                            </Typography>
                        </Box>
                        <Box>
                            {link.sysRecordID && (
                                <Typography style={{ fontWeight: 500 }}>
                                    {`${link.sysRecordID} ${link.recVersion ? `v.${link.recVersion}` : ''}`}
                                </Typography>
                            )}
                        </Box>
                    </Box>
                    <Box style={{ padding: 3 }}>
                        <Typography
                            style={{
                                marginBottom: 5,
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',
                                hyphens: 'auto',
                            }}
                        >
                            {link.displayValue && link.displayValue !== '' ? link.displayValue : link.url}
                        </Typography>
                        <Typography
                            color="textSecondary"
                            style={{
                                marginBottom: 5,
                                wordWrap: 'break-word',
                                wordBreak: 'break-word',
                                hyphens: 'auto',
                            }}
                        >
                            {link.comment}
                        </Typography>
                    </Box>
                </Box>
            )}
            {!readonly && (
                <Box style={{ flexBasis: '10%', display: 'flex', justifyContent: 'space-around' }}>
                    <ActionDots
                        isStatic
                        actions={[
                            {
                                name: 'Edit',
                                onClick: () =>
                                    dispatchDialog({
                                        type: DialogTypes.EDIT_LINK,
                                        state: {
                                            entity,
                                            system: link.system,
                                            description: link.description,
                                            url: link.url,
                                            targetId: link.sysRecordID,
                                            version: link.recVersion,
                                            pres1: link.udf01,
                                            pres2: link.udf02,
                                            comment: link.comment,
                                            trackLinkPK: link.trackLinkPK,
                                        },
                                    }),
                            },
                            {
                                name: 'Delete',
                                onClick: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_YES_NO,
                                        state: {
                                            title: 'Link deletion',
                                            text: 'Are you sure you want to delete this link?',
                                            yesLabel: 'Confirm',
                                            noLabel: 'Cancel',
                                            callback: () =>
                                                dispatchDialog({
                                                    type: DialogTypes.LOADING,
                                                    state: {
                                                        promise: async () => await linkApi.deleteLink(link.trackLinkPK),
                                                        callback: () => {
                                                            reloadLinks();
                                                            dispatchDialog(null);
                                                        },
                                                        error: () =>
                                                            dispatchDialog({
                                                                type: DialogTypes.TEXT_CLOSE,
                                                                state: {
                                                                    title: 'Error',
                                                                    text: 'The need could not be deleted.',
                                                                },
                                                            }),
                                                    },
                                                }),
                                        },
                                    }),
                            },
                        ]}
                    />
                </Box>
            )}
        </Box>
    );
};

const LinksRegion: RegionComponent = ({ setHeaderTitle, setSubtitle, setButtonRight }) => {
    const [links] = useData('links');
    const [{ code }] = useData('general');
    const [{ entity }] = useMetadata();
    const reloadLinks = useReload('links');

    const { readonly } = useUserData();
    const dispatchDialog = useDialogDispatch();

    useEffect(() => {
        if (!readonly) {
            setButtonRight({
                icon: ({ size, color }) => <Add style={{ fontSize: size, color }} />,
                callback: () =>
                    dispatchDialog({
                        type: DialogTypes.ADD_LINK,
                        state: {
                            entity,
                            code,
                            callback: () => {
                                reloadLinks();
                                dispatchDialog(null);
                            },
                        },
                    }),
            });
        }
        setHeaderTitle('Links');
    }, [reloadLinks]);

    useEffect(() => {
        links && setSubtitle(`(${links.length})`);
    }, [links]);

    return (
        <Box>
            {getKeysOf(links).length > 0 ? (
                Object.values(links).map((fl: any) => (
                    <LinkItem
                        entity={entity}
                        link={fl}
                        key={`${fl.trackLinkPK}${fl.dateCreated}`}
                        readonly={readonly}
                        style={{ borderBottom: '1px solid #d1d1d1' }}
                    />
                ))
            ) : (
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Typography color="textSecondary">No links.</Typography>
                </Box>
            )}
        </Box>
    );
};

export default withRegion(LinksRegion, ['links']);
