import Button from '@material-ui/core/Button';
import { InputContext } from 'components/generic/InputProvider';
import EAMCheckbox from 'eam-components/dist/ui/components/muiinputs/EAMCheckbox';
import EAMInput from 'eam-components/dist/ui/components/muiinputs/EAMInput';
import EAMSelect from 'eam-components/dist/ui/components/muiinputs/EAMSelect';
import { getKeysOf } from 'helpers/helperTypes';
import { useDialogDispatch } from 'middleware/dialogContext';
import { useChildren } from 'middleware/validation';
import React, { useContext } from 'react';

export const CloseButton = (props) => {
    const { label = 'Close' } = props;

    const dispatchDialog = useDialogDispatch();

    return (
        <Button onClick={() => dispatchDialog(null)} color="primary">
            {label}
        </Button>
    );
};

export const OkButton = (props) => {
    const { label = 'Ok', callback } = props;

    return (
        <Button onClick={callback} color="primary">
            {label}
        </Button>
    );
};

export const ValidateButton = (props) => {
    const { label = 'Ok', callback } = props;
    const children = useChildren();

    const onClick = () => {
        let validationPassed = true;

        getKeysOf(children)?.forEach((key) => {
            if (children[key] && children[key].validate && !children[key].validate()) {
                validationPassed = false;
            }
        });

        if (validationPassed) {
            callback();
        }
    };

    return (
        <Button onClick={onClick} color="primary">
            {label}
        </Button>
    );
};

const withDialogInput = (InputComponent) => (props) => {
    const children = useChildren();
    const inputContext = useContext(InputContext);
    const {
        label,
        valueKey,
        state,
        update,
        optional,
        values,
        required,
        hidden,
        disabled,
        title,
        maxLength,
        labelKey,
        ...rest
    } = {
        ...inputContext,
        ...props,
    };

    const elementInfo = {
        attribute: hidden ? 'H' : required ? 'R' : optional ? 'O' : 'S',
        readonly: disabled,
        text: label,
        maxLength,
    };

    const value = InputComponent === EAMCheckbox ? (state[valueKey] ? 'true' : 'false') : state[valueKey] || '';

    if (InputComponent === EAMSelect) {
        const renderValue = (val) => [...new Set([val[labelKey || 'desc']])].filter(Boolean).join('');

        return (
            <EAMSelect
                elementInfo={elementInfo}
                // eslint-disable-next-line react/no-children-prop
                children={children}
                updateProperty={(_, newValue) => update({ [valueKey]: newValue })}
                value={value}
                values={values}
                suggestionsPixelHeight={200}
                renderValue={renderValue}
                renderSuggestion={renderValue}
                {...rest}
            />
        );
    }

    const updateProperty = (newValue) => {
        const transformedNewValue = InputComponent === EAMCheckbox ? newValue === 'true' : newValue;

        update({ [valueKey]: transformedNewValue });
    };
    const innerEl = (
        <InputComponent
            elementInfo={elementInfo}
            // eslint-disable-next-line react/no-children-prop
            children={children}
            updateProperty={updateProperty}
            value={value}
            inputProps={InputComponent === EAMInput ? { maxLength: elementInfo.maxLength } : undefined}
            {...rest}
        />
    );

    return title ? <div title={title}>{innerEl}</div> : innerEl;
};

export const Checkbox = withDialogInput(EAMCheckbox);
export const Input = withDialogInput(EAMInput);
export const Select = withDialogInput(EAMSelect);
