import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import trackApi from 'api/track';
import { CloseButton, Input, ValidateButton } from 'components/generic/dialog';
import { useData, useReload } from 'middleware/data';
import { useDialogDispatch, useDialogState } from 'middleware/dialogContext';
import React from 'react';
import { TAttachIntegrationStudyDialog } from './dialogStates';
import DialogTypes from './types';

const AttachIntegrationStudyDialog = () => {
    const dispatchDialog = useDialogDispatch();
    const [{ state }, updateState] = useDialogState<TAttachIntegrationStudyDialog>();
    const [{ code }] = useData('general');
    const reloadStudies = useReload('studies');

    const { childIntegrationStudyCode } = state;

    const apiData = {
        childIntegrationStudyCode,
        parentTrackCode: code,
    };

    return (
        <>
            <DialogTitle id="alert-dialog-title">Attach IS</DialogTitle>
            <DialogContent>
                <Input
                    label="Integration Study ID"
                    state={state}
                    valueKey="childIntegrationStudyCode"
                    update={updateState}
                />
            </DialogContent>
            <DialogActions>
                <CloseButton label="Cancel" />
                <ValidateButton
                    callback={() =>
                        dispatchDialog({
                            type: DialogTypes.LOADING,
                            state: {
                                promise: () => trackApi.attachIntegrationStudy(apiData),
                                callback: () => {
                                    dispatchDialog(null);
                                    reloadStudies();
                                },
                                error: () =>
                                    dispatchDialog({
                                        type: DialogTypes.TEXT_CLOSE,
                                        state: {
                                            title: 'Attach IS',
                                            text: 'There was an error attaching the IS.',
                                        },
                                    }),
                            },
                        })
                    }
                    label="Confirm"
                />
            </DialogActions>
        </>
    );
};

export default AttachIntegrationStudyDialog;
