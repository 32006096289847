import { EAMFilterField, getEAMDefaultFilterValue, OPERATORS } from 'eam-components/dist/ui/components/grids/eam/utils';
import { IAny, TrackItFC } from 'helpers/helperTypes';
import React from 'react';

interface ICustomEAMFilterProps {}

const CustomEAMFilter: TrackItFC<ICustomEAMFilterProps> = (props) => (
    <EAMFilterField
        {...props}
        getDefaultValue={(column: IAny) => {
            switch (column?.dataType) {
                case 'VARCHAR':
                case 'MIXVARCHAR':
                    return {
                        fieldName: column.id,
                        fieldValue: undefined,
                        joiner: 'AND',
                        operator: OPERATORS.CONTAINS,
                    };
                default:
                    return getEAMDefaultFilterValue(column);
            }
        }}
    />
);

export default CustomEAMFilter;
